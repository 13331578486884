<div id="container">
    <app-menu activeElement="help"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <iframe #frame class="help-iframe"
                (load)="onFrameLoaded()"></iframe>
            </div>
        </main>
    </div>
</div>
