<div id="container">

    <app-menu activeElement="dashboard"></app-menu>

    <!-- Dashboard Content -->
    <div id="inner">

        <!-- BANNER -->
        <app-banner #banner></app-banner>

        <!-- DASHBOARD MENU -->
        <header class="row-align-right">
            <div class="battery-status">
                <ng-container *ngIf="userService.isEDGUser()">
                    <div class="innogy-medium">{{ 'screens.dashboard.batteryStatus' | translate }}</div>
                    <app-battery-load [currentBatteryState]="currentORBatteryState"
                                      [smallBars]="true"
                                      [coloredWarning]="true"
                                      [boldText]="true"
                                      [isAvailable]="currentORBatteryState > -1">
                    </app-battery-load>
                </ng-container>
            </div>
            <button class="iona-icon-button sort-tiles" (click)="onSortButtonClick()"
                    angulartics2On="click" angularticsCategory="Dashboard" angularticsAction="edit_mode_start">
                {{ 'screens.dashboard.sort' | translate }}
            </button>
            <button class="iona-icon-button add-tile" (click)="onAddTileButtonClicked()">
                {{ 'screens.dashboard.addTile' | translate }}
            </button>
        </header>

        <!-- DASHBOARD -->
        <div class="tile-view">
            <ng-container *ngFor="let tile of dashboardTiles">
                <ng-container [ngSwitch]="tile.type">
                    <ng-container *ngSwitchCase="TILE_TYPE.LIVE">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-live-tile></app-live-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.TODAY">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-today-tile></app-today-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.APPLIANCES">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-appliances-tile *ngIf="tile.selected"></app-appliances-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.COMPARISON">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-comparison-tile *ngIf="tile.selected"></app-comparison-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.METER">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-meter-tile *ngIf="tile.selected"></app-meter-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.FINANCE">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-finance-tile *ngIf="tile.selected"></app-finance-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="userHasPlug">
                        <ng-container *ngSwitchCase="TILE_TYPE.POWER_CHECKER">
                            <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                                <app-powerchecker-tile *ngIf="tile.selected"></app-powerchecker-tile>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.CONSUMPTION_ALERT">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-consumption-alert-tile *ngIf="tile.selected"></app-consumption-alert-tile>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="mvpTileAvailable">
                        <ng-container *ngSwitchCase="TILE_TYPE.MVP">
                            <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                                <app-mvp-tile *ngIf="tile.selected" [config]="tile.mvpConfig"></app-mvp-tile>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="TILE_TYPE.HOUSEHOLD_COMPARISON">
                        <div class="tile-wrapper" appMatchHeight *ngIf="tile.selected">
                            <app-household-comparison-tile *ngIf="tile.selected"></app-household-comparison-tile>
                        </div>
                    </ng-container>

                </ng-container>
            </ng-container>

            <div class="tile-wrapper" appMatchHeight *ngIf="tileService.tilesAvailable()">
                <div class="tile-container">
                    <div class="tile tile-add" (click)="onAddTileButtonClicked()">
                        <div class="tile-inner center-content">
                            <div class="inflate center-contents">
                                <i class="icon-plus-l"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
