import {Injectable, OnDestroy} from '@angular/core';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {UserService} from './user.service';
import {MvpConfig} from './mvp.service';
import {Popover} from '../popovers/popover/popover.service';
import {map} from 'rxjs/operators';
import {PopoverConfigService} from '../popovers/static.popover.config';
import {LiveDetailsComponent} from '../tiles/live/live-details/live-details.component';
import {MeterDetailsComponent} from '../tiles/meter/meter-details/meter-details.component';
import {FinanceDetailsComponent} from '../tiles/finance/finance-details/finance-details.component';
import {MvpDetailsComponent} from '../tiles/mvp/mvp-details/mvp-details.component';
import {
    AppliancesDetailsComponent
} from '../tiles/appliances/appliances-detail/appliances-detail.component';
import {
    ConsumptionAlertDetailsComponent
} from '../tiles/consumption-alert/consumption-alert-details/consumption-alert-detail.component';
import {
    ComparisonDetailsComponent
} from '../tiles/compare/comparison-details/comparison-details.component';
import {
    PowerCheckerDetailsComponent
} from '../tiles/powerchecker/powerchecker-details/powerchecker-details.component';
import {TodayDetailsComponent} from '../tiles/today/today-details/today-details.component';
import {
    HouseholdComparisonDetailComponent
} from '../tiles/household-comparison/household-comparison-detail/household-comparison-detail.component';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class TileService implements OnDestroy {
    currentTiles: TileDef[] = [];

    selectionChanged = new Subject<TileDef[]>();
    private isVisionUser = false;

    private _powerCheckerTilePosition = 8;

    constructor(private userService: UserService,
                private popover: Popover,
                private popoverConfigService: PopoverConfigService,
                private translate: TranslateService) {
        this.init();
    }


    ngOnDestroy() {
        this.clearTiles();
    }


    /**
     * Updates the current tile list with the one passed.
     * Also emits the tile-selection-changed Subject with the new list and saves the current tile
     * selection ot the user settings.
     * @param newList
     */
    updateTileList(newList: TileDef[]) {
        this.currentTiles = newList;

        const tiles = this.getCurrentTiles(this.isVisionUser);

        this.userService.setActiveUserTiles(tiles);
        this.selectionChanged.next(tiles);
    }


    /**
     * Opens a detail view related to the selected tile type.
     * Takes data as additional input.
     *
     * @param type
     * @param data
     */
    openDetailView(type: TILE_TYPE, data = null): void {
        let component = null;
        let isNotDefaultTile = false;
        switch (type) {
            case TILE_TYPE.LIVE:
                component = LiveDetailsComponent;
                break;
            case TILE_TYPE.METER:
                component = MeterDetailsComponent;
                break;
            case TILE_TYPE.FINANCE:
                component = FinanceDetailsComponent;
                break;
            case TILE_TYPE.CONSUMPTION_ALERT:
                component = ConsumptionAlertDetailsComponent;
                break;
            case TILE_TYPE.APPLIANCES:
                component = AppliancesDetailsComponent;
                break;
            case TILE_TYPE.TODAY:
                component = TodayDetailsComponent;
                break;
            case TILE_TYPE.COMPARISON:
                component = ComparisonDetailsComponent;
                break;
            case TILE_TYPE.POWER_CHECKER:
                component = PowerCheckerDetailsComponent;
                isNotDefaultTile = true;
                break;
            case TILE_TYPE.MVP:
                component = MvpDetailsComponent;
                isNotDefaultTile = true;
                break;
            case TILE_TYPE.HOUSEHOLD_COMPARISON:
                component =  HouseholdComparisonDetailComponent;
                break;
        }

        if (!component) {
            console.log('No valid component type');
            return;
        }

        // create a new tile if its not a default tile
        let tileDef: TileDef = null;
        if (isNotDefaultTile) {
            if (type === TILE_TYPE.POWER_CHECKER) {
                tileDef = PowerCheckerTile;
            }
            if (type === TILE_TYPE.MVP) {
                tileDef = MVPTile;
            }
        } else {
            tileDef = defaultTilesTyped.find((e) => e.type === type);
        }

        // specify detail header background color
        const detailColor = tileDef.detailColor ? tileDef.detailColor : 'white';
        if (!data) {
            data = {color: detailColor};
        } else {
            data.color = detailColor;
        }

        // finish up generating the config
        const config = {
            content: component,
            data,
            hasBackdrop: true,
        };
        const s = this.popover.open(config).afterClosed$
            .subscribe(() => s.unsubscribe());
    }


    /**
     * Toggles a selected tile by its definition structure.
     * The tiles gets added to the end of the tile list.
     * @param tile - the tile to be toggled
     */
    toggleTile(tile: TileDef) {
        // console.log('tile', tile);
        // if (tile.selected) {
        //     console.log('here');
        //     this.currentTiles.forEach((it) => {
        //         if (it.type === tile.type) {
        //             it.selected = !it.selected;
        //         }
        //     });
        //     return;
        // }
        //

        // remove tile from its current position in the list
        // deepcopy the list
        const tileListCpy = JSON.parse(JSON.stringify(this.currentTiles));
        const currentListIdx = tileListCpy.findIndex((it) => it.type === tile.type);
        const splicedElement = tileListCpy.splice(currentListIdx, 1);

        tile.selected = true;
        tileListCpy.push(tile);
        this.updateTileList(tileListCpy);
    }


    /**
     * Toggles an MPV tile by its id.
     * @param id - MVP-tile id
     */
    toggleMVPTile(id: string): void {
        const idx = this.currentTiles.findIndex(it => it.id === id);
        if (idx === -1) {
            return;
        }
        this.currentTiles[idx].selected = !this.currentTiles[idx].selected;
        const tile = this.currentTiles[idx];
        this.currentTiles.splice(idx, 1);
        this.currentTiles.push(tile);
        this.updateTileList(this.currentTiles);
    }


    /**
     * Returns whether a tile with a certain type is available.
     * @param type - tile type
     */
    tileAvailable(type: TILE_TYPE) {
        const found = this.currentTiles.find((el) => el.type === type);
        return !!found;
    }


    /**
     * Returns whether there are unselected tiles available
     */
    tilesAvailable(): boolean {
        for (const tile of this.currentTiles) {
            if (tile.selected === false) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns whether a tile of certain type is selected
     * @param type - tile type
     */
    tileSelected(type: TILE_TYPE) {
        for (const it of this.currentTiles) {
            if (it.type === type) {
                return it.selected;
            }
        }
        return false;
    }


    /**
     * Changes the selected state of an existing tile
     * @param value - the selected value
     * @param tile - tile type
     * @param fromTile
     */
    setSelected(value: boolean, tile: TILE_TYPE, fromTile = false) {
        if (fromTile && !value) {
            const popoverConfig = this.popoverConfigService.getRemoveTilePopoverConfig();
            const s = this.popover.open(popoverConfig).afterClosed$.pipe(
                map(res => res.data)
            ).subscribe((res) => {
                if (res) {
                    this.setTileSelectedState(value, tile);
                }
                s.unsubscribe();
            });
            return;
        }
        this.setTileSelectedState(value, tile);
    }


    /**
     * Generally enables a tiletype.
     * Mainly to use for tiles that are not generally a part of the users dashboard.
     * Currelty used only for PowerChecker Tile.
     * MVP Tiles follow a different logic
     * @param tileType - tile type to be enabled
     */
    enableTileType(tileType: TILE_TYPE): void {
        switch (tileType) {
            case TILE_TYPE.POWER_CHECKER:
                this.currentTiles.splice(
                    this._powerCheckerTilePosition,
                    0,
                    PowerCheckerTile
                );
                break;
            default:
                console.log('No supported tile-type provided');

        }
        this.translateTiles();
    }


    /**
     * Disables a tile of specified type.
     * @param tileType - tile type to disable
     */
    disableTileType(tileType: TILE_TYPE): void {
        const idx = this.currentTiles.findIndex((it) => it.type === tileType);
        if (idx > -1) {
            this.currentTiles.splice(idx, 1);
            this.updateTileList(this.currentTiles);
            return;
        }
    }


    /**
     * Returns whether a MVP tile with a certain id is currently active == selected.
     * @param id - id of the MVP tile
     */
    mvpTileIsCurrentlyActive(id: string): boolean {
        try {
            return this.currentTiles.find(el => el.id === id).selected;
        } catch (error) {
            return false;
        }
    }


    /**
     * Adds an MVP tile with the passed configuration.
     * @param config - mvp tile configuration
     */
    addMVPTile(config: MvpConfig): void {
        const tile = this.createNewMVPTile(config);
        const currentTileIdx = this.currentTiles.findIndex(el => el.id === config.id);
        if (currentTileIdx >= 0) {
            this.currentTiles.splice(currentTileIdx, 1, tile);
        } else {
            this.currentTiles.splice(config.dashboardConfiguration.position, 0, tile);
        }
        this.updateTileList(this.currentTiles);
        this.translateTiles();
    }


    /**
     * Removes an MVP tile.
     * The tile gets selected by its config.
     * @param config - MVP tile config
     */
    removeMVPTile(config: MvpConfig): boolean {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 0) {
            this.currentTiles.splice(idx, 1);
            this.updateTileList(this.currentTiles);
            return true;
        }
        return false;
    }


    /**
     * Disables an MVP tile with the specified id.
     * @param id - MVP tile id
     */
    disableMVPTile(id: string): void {
        const tileIdx = this.currentTiles.findIndex(t => {
            if (t.type === TILE_TYPE.MVP) {
                return t.id === id;
            }
        });
        if (tileIdx >= 0) {
            this.currentTiles[tileIdx].selected = false;
        }
        this.updateTileList(this.currentTiles);
    }


    /**
     * Updates MVP config and thus the underlying tile.
     * @param config - new config
     */
    updateMVPTileConfig(config: MvpConfig): void {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 1) {
            this.currentTiles[idx].mvpConfig = config;
        } else {
            return;
        }

        this.updateTileList(this.currentTiles);
    }


    /**
     * Update the position of an MVP tile.
     * @param config - MVP tile config
     */
    updateMVPTilePosition(config: MvpConfig): void {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 0) {
            const tile = this.currentTiles[idx];
            this.currentTiles.splice(idx, 1);
            this.currentTiles.splice(config.dashboardConfiguration.position, 0, tile);
        }
        this.updateTileList(this.currentTiles);
    }


    /**
     * Returns the current tile list
     */
    getCurrentTiles(isVisionUser: boolean = false): TileDef[] {
        this.isVisionUser = isVisionUser;

        if(isVisionUser){
            const filteredTiles = this.currentTiles.filter((tile) => tile.type !== TILE_TYPE.FINANCE);
            this.currentTiles = filteredTiles;
            return filteredTiles
        }
        return this.currentTiles;
    }


    /**
     * Returns the current tile list as an observable
     */
    getCurrentTiles$(isVisionUser: boolean = false): Observable<TileDef[]> {
        this.isVisionUser = isVisionUser;

        if(isVisionUser){
            const filteredTiles = this.currentTiles.filter((tile) => tile.type !== TILE_TYPE.FINANCE);
            this.currentTiles = filteredTiles;
            return of(filteredTiles);
        }
        return of(this.currentTiles);
    }


    /**
     * Removes all tiles
     */
    clearTiles(): void {
        this.currentTiles = [];
        this.updateTileList(this.currentTiles);
    }


    /**
     * Initialize the service.
     * Loads previous user config - tile layout form teh localStorage.
     * Initializes with the default set of tiles if there is no user config present.
     */
    init(): void {
        const tiles = this.userService.getActiveUserTiles();
        if (tiles) {
            this.currentTiles = tiles;
            this.getCurrentTiles(this.isVisionUser);
        } else {
            this.currentTiles = defaultTilesTyped;
            this.getCurrentTiles(this.isVisionUser);
            this.userService.setActiveUserTiles(this.currentTiles);
            this.userService.setConsumptionAlertAttribute(false);
        }
        this.translateTiles();
        this.selectionChanged.next(this.currentTiles);
    }

    private translateTiles(): void {
        const translateObservables = this.currentTiles.map(tile =>
            this.translate.get([tile.title, tile.info]).pipe(
                map(translations => {
                    tile.title = translations[tile.title];
                    tile.info = translations[tile.info];
                })
            )
        );

        forkJoin(translateObservables).subscribe(() => {
            this.translate.get([PowerCheckerTile.title, PowerCheckerTile.info]).subscribe(translations => {
                PowerCheckerTile.title = translations[PowerCheckerTile.title];
                PowerCheckerTile.info = translations[PowerCheckerTile.info];
            });

            this.translate.get([MVPTile.title, MVPTile.info]).subscribe(translations => {
                MVPTile.title = translations[MVPTile.title];
                MVPTile.info = translations[MVPTile.info];
            });
        });
    }


    /**
     * Removes legacy MVP tiles.
     * This function had relevance once the 'multiple MVP tiles' feature was implemented.
     * It is kept as a safety mechanism.
     * Should at some point be removed or validated for its necessity.
     */
    removeLegacyMVPTiles(): void {
        let i = 0;
        for (const tile of this.currentTiles) {
            if (tile.type === TILE_TYPE.MVP) {
                if (!tile.mvpConfig) {
                    this.currentTiles.splice(i, 1);
                }
            }
            ++i;
        }
        this.updateTileList(this.currentTiles);
    }


    /**
     * Updates the selected attribute state of a tile type.
     * @param value - new selected value
     * @param tile - tile type
     * @private
     */
    private setTileSelectedState(value: boolean, tile: TILE_TYPE): void {
        this.currentTiles.forEach((it) => {
            if (it.type === tile) {
                it.selected = value;
            }
        });
        this.updateTileList(this.currentTiles);
    }


    /**
     * Creates an MVP Tile by config
     * @param config
     * @private
     */
    private createNewMVPTile(config: MvpConfig): TileDef {
        const thumbnail = `${config.base_url}/${config.id}/res/tiles/l.png`;
        return {
            type: TILE_TYPE.MVP,
            id: config.id,
            title: config.title,
            info: config.description,
            thumbnail,
            selected: config.dashboardConfiguration.forceAdd,
            addIndex: this.currentTiles.length - 1,
            mvpConfig: config,
            detailColor: config.colors.primary
        };
    }
}


export enum TILE_TYPE {
    LIVE = 'LIVE',
    TODAY = 'TODAY',
    APPLIANCES = 'APPLIANCES',
    COMPARISON = 'COMPARISON',
    METER = 'METER',
    FINANCE = 'FINANCE',
    CONSUMPTION_ALERT = 'CONSUMPTION_ALERT',
    PHASE_CHECKER = 'PHASE_CHECKER',
    POWER_CHECKER = 'POWER_CHECKER',
    MVP = 'MVP',
    HOUSEHOLD_COMPARISON = 'HOUSEHOLD_COMPARISON',
}


export interface TileDef {
    type: TILE_TYPE;
    id?: string;
    title: string;
    info: string;
    thumbnail: string;
    selected: boolean;
    addIndex: number;
    detailColor?: string;
    mvpConfig?: MvpConfig;
}


const thumbnailPath = '/assets/img/thumbs/';
const thumbnailFileType = '.webp';
const getThumbnailPath = (tile: string): string => {
    return `${thumbnailPath}${tile}${thumbnailFileType}`;
};

export const defaultTilesTyped: TileDef[] = [
    {
        type: TILE_TYPE.LIVE,
        title: 'screens.dashboard.tiles.live.title',
        info: 'screens.dashboard.tiles.live.info',
        thumbnail: getThumbnailPath('live'),
        selected: true,
        addIndex: 0,
        detailColor: '#00aae1'
    },
    {
        type: TILE_TYPE.HOUSEHOLD_COMPARISON,
        title: 'screens.dashboard.tiles.householdComparison.title',
        info: 'screens.dashboard.tiles.householdComparison.info',
        thumbnail: getThumbnailPath('household-comparison'),
        selected: true,
        addIndex: 1,
        detailColor: '#64b42d'
    },
    {
        type: TILE_TYPE.TODAY,
        title: 'screens.dashboard.tiles.today.title',
        info: 'screens.dashboard.tiles.today.info',
        thumbnail: getThumbnailPath('today'),
        selected: true,
        addIndex: 2,
        detailColor: '#64b42d'
    },
    {
        type: TILE_TYPE.APPLIANCES,
        title: 'screens.dashboard.tiles.appliances.title',
        info: 'screens.dashboard.tiles.appliances.info',
        thumbnail: getThumbnailPath('appliances'),
        selected: true,
        addIndex: 3,
        detailColor: '#c81e82'
    },
    {
        type: TILE_TYPE.COMPARISON,
        title: 'screens.dashboard.tiles.comparison.title',
        info: 'screens.dashboard.tiles.comparison.info',
        thumbnail: getThumbnailPath('comparison'),
        selected: true,
        addIndex: 4,
        detailColor: '#f59b00'
    },
    {
        type: TILE_TYPE.METER,
        title: 'screens.dashboard.tiles.meter.title',
        info: 'screens.dashboard.tiles.meter.info',
        thumbnail: getThumbnailPath('meter'),
        selected: true,
        addIndex: 5,
        detailColor: '#e60055'
    },
    {
        type: TILE_TYPE.FINANCE,
        title: 'screens.dashboard.tiles.finance.title',
        info: 'screens.dashboard.tiles.finance.info',
        thumbnail: getThumbnailPath('finance'),
        selected: true,
        addIndex: 6,
        detailColor: '#009ba5'
    },
    {
        type: TILE_TYPE.CONSUMPTION_ALERT,
        title: 'screens.dashboard.tiles.consumptionAlert.title',
        info: 'screens.dashboard.tiles.consumptionAlert.info',
        thumbnail: getThumbnailPath('consumption-alert'),
        selected: true,
        addIndex: 7,
        detailColor: '#eb4b0a'
    },

];


const PowerCheckerTile: TileDef = {
    type: TILE_TYPE.POWER_CHECKER,
    info: 'screens.dashboard.tiles.powerChecker.info',
    title: 'screens.dashboard.tiles.powerChecker.ionaTitle',
    thumbnail: getThumbnailPath('powerchecker'),
    selected: true,
    addIndex: 7,
    detailColor: '#00aae1'
};


const MVPTile: TileDef = {
    type: TILE_TYPE.MVP,
    id: '',
    title: '',
    info: '',
    thumbnail: '',
    selected: false,
    addIndex: 8,
};
