import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {BaseComponent} from '../../classes/base-component';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'iona-app',
    templateUrl: './contact.component.html',
    viewProviders: []
})

export class ContactComponent extends BaseComponent implements OnInit, AfterViewInit {
    readonly phone: string = '0800-8888863';
    readonly fax: string = '+4920112-20000';
    readonly email: string = 'kundenservice@iona-energy.com';

    constructor(public application: ApplicationService,
                private title: Title,
                private userService: UserService,
                private optInService: LocalOptInService,
                private gtm: GTMWrapperService,
                private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Info | iONA');
        this.optInService.checkStatus();
    }


    ngAfterViewInit() {
        this.trackViewLoadedEvent();
    }


    openPhone(): void {
        window.open(`tel:${this.phone}`);
    }


    openFax(): void {
        window.open(`fax:${this.fax}`);
    }


    openMail(): void {
        window.open(`mailto:${this.email}`, '');
        this.trackEmailButtonClick();
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewLoadedEvent(): void {
        this.gtm.trackEvent({
            event: 'view',
            eventCategory: 'interaction',
            eventAction: 'view',
            journeyId: 'direct contact',
            toolId: 'direct contact',
            elementId: 'direct contact',
            stepId: 'direct contact'
        });
    }

    private trackEmailButtonClick(): void {
        this.gtm.trackEvent({
            event: 'click-link',
            eventCategory: 'interaction',
            eventAction: 'view',
            journeyId: 'direct contact',
            toolId: 'direct contact',
            elementId: 'direct contact',
            stepId: 'direct contact',
            linkText: this.translate.instant('screens.contact.contactService'),
            linkPath: 'not_exists'
        });
    }
}
