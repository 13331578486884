import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FinancialTrend} from '../../services/finance-data.service';

@Component({
    selector: 'app-finance-trend-visualization',
    templateUrl: './finance-trend-visualization.component.html',
    styleUrls: ['./finance-trend-visualization.component.scss']
})
export class FinanceTrendVisualizationComponent implements OnInit {

    @Input() financialTrend: FinancialTrend

    constructor() {
    }

    ngOnInit(): void {
    }

}
