import * as moment from 'moment';
import {duration} from 'moment';
import {TranslateService} from '@ngx-translate/core';

export const getMonthName = (idx: number, translate: TranslateService): string => {
    let date: Date = new Date();
    date.setMonth(date.getMonth() - idx);
    const monthIndex = date.getMonth();
    const translatedMonths = translate.instant('months');
    return translatedMonths[monthIndex];
};

export const getDate = (offset: number): string => {
    let date: any = new Date();
    date.setDate(date.getDate() - offset);

    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
};

export const getWeek = (date): number => {
    const now: any = new Date(date);
    now.setHours(0, 0, 0);
    now.setDate(now.getDate() + 4 - (now.getDay() || 7));

    const start: any = new Date(now.getFullYear(), 0, 1);

    return Math.ceil((((now.valueOf() - start.valueOf()) / 86400000) + 1) / 7);
};

export const dateDifference = (date: Date): string => {
    let diff: any = new Date().getTime() - date.getTime();

    const days: any = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff = diff % (1000 * 60 * 60 * 24);

    const hours: any = Math.floor(diff / (1000 * 60 * 60));
    diff = diff % (1000 * 60 * 60);

    const minutes: any = Math.floor(diff / (1000 * 60));
    diff = diff % (1000 * 60);

    const seconds: any = Math.floor(diff / 1000);

    if (days > 0) {
        if (days === 1) {
            return days + ' Tag';
        } else {
            return days + ' Tagen';
        }
    } else if (hours > 0) {
        if (hours === 1) {
            return hours + ' Stunde';
        } else {
            return hours + ' Stunden';
        }
    } else if (minutes > 0) {
        if (minutes === 1) {
            return minutes + ' Minute';
        } else {
            return minutes + ' Minuten';
        }
    } else if (seconds > 0) {
        if (seconds === 1) {
            return seconds + ' Sekunde';
        } else {
            return seconds + ' Sekunden';
        }
    } else {
        return 'jetzt';
    }
};

export const determineDurationPassed = (since: any, translate: TranslateService): string => {
    const date = moment(new Date(since));
    const dur = duration(moment().diff(date));
    let s = '';
    const secs = dur.asSeconds();
    if (secs < 60) {
        translate.get('screens.dashboard.live.duration.justNow').subscribe((res: string) => {
            s = res;
        });
    } else if (secs >= 60 && secs < 3600) {
        const minutes = `${dur.minutes()} ${secs === 60 ?
            translate.instant('screens.dashboard.live.duration.minute') :
            translate.instant('screens.dashboard.live.duration.minutes')}`;
        translate.get('screens.dashboard.live.duration.since',
            {value: minutes}).subscribe((res: string) => {
            s = res;
        });
    } else if (secs >= 3600 && secs < 86400) {
        const hours = `${dur.hours()} ${secs === 60 ?
            translate.instant('screens.dashboard.live.duration.hour') :
            translate.instant('screens.dashboard.live.duration.hours')}`;
        translate.get('screens.dashboard.live.duration.since',
            {value: hours}).subscribe((res: string) => {
            s = res;
        });
    } else {
        const formattedDate = date.format('DD.MM.YYYY');
        translate.get('screens.dashboard.live.duration.since',
            {value: formattedDate}).subscribe((res: string) => {
            s = res;
        });
    }
    return s;
};
