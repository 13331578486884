<div class="overlay-full-height sidebar-width">
    <div class="overlay-container-cdk p-a-n">

        <div class="row-align-right m-v-l">
            <button class="iona-icon-only-button close" (click)="close()"></button>
        </div>

        <h1 class="font-medium">{{ 'screens.dashboard.sort' | translate }}</h1>

        <div class="tile-sort-list" cdkDropList (cdkDropListDropped)="dropElement($event)">
            <ng-container *ngFor="let tile of sortableTiles; let pos = index">
                <ng-container *ngIf="tile.selected; else invisible">
                    <div class="sort-tiles-item row font-regular cursor-pointer"
                         cdkDrag>
                        <i class="icon-dots"></i>
                        <div class="idx font-medium p-h-s">{{ pos + 1 }}</div>
                        <div class="title grow-space">{{ tile.title }}</div>
                        <button class="iona-icon-only-button trash" (click)="onRemoveTileClick(tile)"></button>
                    </div>
                </ng-container>
                <ng-template #invisible>
                    <div></div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
