<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Zählerstand; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading ">
                {{TEXTS.TITLE}}
            </h3>
            <div class="tile-content col">
                <ng-container *ngIf="meterData$ | async as meterData">
                    <ng-container [ngSwitch]="meterData.viewState">
                        <ng-container *ngSwitchCase="ViewState.ERROR">
                            <div class="grow-space center-contents">
                                {{TEXTS.ERROR}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.LOADING">
                            <div class="grow-space center-contents">
                                {{TEXTS.LOADING}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="ViewState.SUCCESS">
                            <div class="meter-data">
                                <div class="meter-value center-contents font-regular">
                                    <div>
                                        {{ meterData.meterValues.offtake | paddedMeterValue }}
                                        {{TEXTS.UNIT}}
                                    </div>
                                </div>

                                <div *ngIf="meterData.meterStats.meterSerialNumber"
                                     class="col-centered meter-info">
                                    <div class="label font-light">
                                        {{TEXTS.LABEL_METER_ID}}
                                    </div>
                                    <div class="meter-serial-number font-medium">
                                        {{meterData.meterStats.meterSerialNumber}}
                                    </div>
                                </div>
                            </div>

                            <div class="meter-connection">
                                <div class="connection-container">
                                    <div class="p-r-s">
                                        <app-connection-indicator
                                            [value]="meterData.wifiConnectionQuality"
                                            container_height="24px"
                                            [disabled]="!isMeterConnected(meterData.meterStats.connectionStatus)"
                                            [invert_colors]="false"
                                            [config]="connectionConfig">
                                        </app-connection-indicator>

                                    </div>
                                    <h4 class="connection-text font-light">
                                        {{determineMeterStatusMessage(meterData.meterStats.connectionStatus)}}
                                    </h4>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </div>

        </div>

    </div>

</div>
