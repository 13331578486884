<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Heute; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">{{LABELS.TITLE}}</h3>
            <div class="tile-content col">

                <div class="today-content grow-space">
                    <ng-container *ngIf="todayTileData$ | async as tileData">
                        <ng-container [ngSwitch]="tileData.viewState">
                            <ng-container *ngSwitchCase="ViewState.SUCCESS">
                                <div class="today-visualization grow-space">
                                    <div class="pie-wrapper">
                                        <div class="today-pie left {{tileData.leftState}}" [halfCircle]="'left'"
                                             [style.transform]="'scale(' + tileData.trend.scale.left + ')'"></div>
                                        <div class="today-pie right {{tileData.rightState}}" [halfCircle]="'right'"
                                             [style.transform]="'scale(' + tileData.trend.scale.right + ')'"></div>
                                    </div>
                                </div>

                                <app-today-tile-stats
                                    [today]="tileData.today"
                                    [comparisonDate]="tileData.comparisonDate"
                                ></app-today-tile-stats>

                                <div class="center-contents m-t-m font-regular">
                                    <ng-container
                                        *ngIf="tileData.trend.percentage > 0 && tileData.trend.percentage < 9999">
                                        <strong>
                                            {{tileData.trend.percentage}}
                                            {{LABELS.LABEL_FRAGMENT_PERCENT}}
                                            {{tileData.trend.direction > 0 ? LABELS.MORE : LABELS.LESS}}
                                        </strong>
                                        &nbsp;
                                        <span>
                                            {{LABELS.LABEL_FRAGMENT_LAST}}
                                            {{tileData.comparisonDate.date | nameOfDay}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="tileData.trend.percentage === 0">
                                        <strong>
                                            {{LABELS.LABEL_FRAGMENT_IDENTICAL}}
                                        </strong>
                                        &nbsp;
                                        <span>
                                            {{LABELS.LABEL_FRAGMENT_TO_LAST}}
                                            {{tileData.comparisonDate.date | nameOfDay}}
                                        </span>
                                    </ng-container>
                                </div>

                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.LOADING">
                                <div class="center-contents grow-space">
                                    {{LABELS.LABEL_LOADING}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.ERROR">
                                <div class="center-contents grow-space">
                                    {{LABELS.LABEL_ERROR_STATE}}
                                </div>
                            </ng-container>
                        </ng-container>

                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>
