import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SingleDayComparisonData} from '../../../shared/interfaces/today-tile-data.interfaces';
import { InitializationService } from 'src/app/services/initialization.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-today-tile-stats',
    templateUrl: './today-tile-stats.component.html',
    styleUrls: ['./today-tile-stats.component.scss']
})
export class TodayTileStatsComponent implements OnChanges, OnInit {
    TEXTS;

    @Input() today: SingleDayComparisonData;
    @Input() comparisonDate: SingleDayComparisonData;

    isVisionUser = true;


    constructor(private initialization: InitializationService, private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.checkIsVisionUser();
        this.translate.get('screens.dashboard.consumptionAlert.stats')
            .subscribe((texts: any) => {
                this.TEXTS = texts;
            });
    }


    ngOnChanges(changes: SimpleChanges) {
    }


    extractRealValue(value: string): string {
        return value.split(',')[0] + ',';
    }


    extractDecimalValue(value: string): string {
        return value.split(',')[1];
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if ('product_name' in data){
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        });
    }

    /**
     * Formats a numerical value
     * @param value
     */
    formatValue(value: number): string {
        if (value === 0.0) {
            return '-';
        }
        return value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );
    }

}
