import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'nameOfDay'
})
export class NameOfDayPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): string {
      return moment(value).locale('de').format('dddd');
  }

}
