import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {constants} from '../shared/constants/constants';
import * as moment from 'moment';
import {catchError, map, tap} from 'rxjs/operators';
import {ApplicationService} from './application.service';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConsumptionService extends BaseService {
    private readonly dateFormatMonthlyConsumption = 'YYYY-MM';

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }


    /**
     * Requests the consumption by appliance on a monthly basis
     * @param monthFrom - from date
     * @param monthTo - to date
     */
    getConsumptionForMonthsPerAppliance(monthFrom: Date, monthTo: Date): Observable<any> {
        const d1 = moment(monthFrom).format(this.dateFormatMonthlyConsumption);
        const d2 = moment(monthTo).format(this.dateFormatMonthlyConsumption);
        let url = this.API_BASE_URL + constants.api.routes.consumption.electricity.appliances.months;
        url = `${url}/${d1}/${d2}`;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.appliances}.json`;
        }
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            catchError(error => this.handleError(error))
        );
    }

    /**
     * Requests the overall consumption for a certain timeframe.
     * Consumption is returned summed by month.
     *
     * New data is only requested every 5 minutes upon request.
     * This is due to the fact, that it is highly unlikely for the consumption values of the current
     * month to change rapidly within set timeframe.
     * After all the consumption is measured in kWh.
     *
     * @param from - from date
     * @param to - from date
     */
    getConsumptionForMonths(from: Date, to: Date): Observable<MonthlyConsumptionData[]> {
        const dFrom = moment(from).format(this.dateFormatMonthlyConsumption);
        const dTo = moment(to).format(this.dateFormatMonthlyConsumption);
        let url = this.API_BASE_URL + constants.api.routes.consumption.electricity.months;
        url = `${url}/${dFrom}/${dTo}`;
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            catchError(error => this.handleError(error))
        );
    }
}


export interface MonthlyConsumptionData {
    calculated: number;
    compare: number;
    cost_calculated: number;
    cost_compare: number;
    cost_measured: number;
    fixed_cost: number;
    measured: number;
    temperature: number;
    timestamp: string;
}
