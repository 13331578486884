<div class="tile-container">

    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: MVP; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading ">{{config.title}}</h3>
            <div class="tile-content">
                <div *ngIf="imageUrl !== ''" class="tile-chart-abs mvp-image-container"
                     [style.backgroundImage]="imageUrl">
                </div>
                <!--                <div class="error grey" [hidden]="!mvpResError"></div>-->
                <!--                <div class="error-text" [hidden]="!mvpResError">Daten sind noch <br/>nicht verfügbar</div>-->
            </div>
        </div>
    </div>
</div>
