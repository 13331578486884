<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Abschlags-Check; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">
                {{LABELS.TITLE}}
            </h3>
            <div class="tile-content col">
                    <ng-container *ngIf="{
                                financeData: financeData$ | async,
                                financeError: financeDataError$ | async
                                 } as vm">


                        <div class="finance-status-container">
                            <ng-container *ngIf="vm.financeData && !vm.financeError ; else loadingAnimation">
                                <app-finance-trend-visualization
                                    [financialTrend]="vm.financeData">
                                </app-finance-trend-visualization>
                            </ng-container>

                            <ng-template #loadingAnimation>
                                <div class="animation-container"
                                     lottie [options]="lottieConfig">
                                </div>
                            </ng-template>
                        </div>

                        <div class="finance-status-wording font-regular">
                            <ng-container *ngIf="vm.financeData && !vm.financeError; else emptySubtitle">
                                <div *ngIf="vm.financeData.trend < 0">
                                    {{LABELS.LABEL_FRAGMENT_CIRCA}}
                                    <div class="font-medium">
                                        {{ vm.financeData.amount }}
                                        {{LABELS.UNIT_FINANCE}}
                                    </div>
                                    {{LABELS.LABEL_FRAGMENT_CREDIT}}
                                </div>
                                <div *ngIf="vm.financeData.trend === 0">
                                    {{LABELS.LABEL_FRAGMENT_ESTIMATED}}
                                    <div class="font-medium">
                                        {{LABELS.LABEL_FRAGMENT_EVEN}}
                                    </div>
                                </div>
                                <div *ngIf="vm.financeData.trend > 0">
                                    {{LABELS.LABEL_FRAGMENT_CIRCA}}
                                    <div class="font-medium">
                                        {{ vm.financeData.amount }}
                                        {{LABELS.UNIT_FINANCE}}
                                    </div>
                                    {{LABELS.LABEL_FRAGMENT_ADDITIONAL_PAYMENT}}
                                </div>
                            </ng-container>
                            <ng-template #emptySubtitle>
                                <h4>
                                    {{LABELS.LABEL_EMPTY_STATE}}
                                </h4>
                            </ng-template>
                        </div>
                    </ng-container>

            </div>

        </div>
    </div>
</div>
