<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>{{ 'screens.profile.twoFactorAuthTitle' | translate }}</h1>
        <p>
            {{ 'screens.profile.twoFactorAuthDescription' | translate }}
        </p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <input id="code-input" class="iona-input" type="text"
                       placeholder="{{ 'screens.profile.codePlaceholder' | translate }}"
                       (keydown)="onKeyDown($event)"
                       (keyup)="onKeyUp($event)"
                       formControlName="code">
                <label for="code-input"></label>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="45px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="iona-button orange"
                    type="submit"
                    [disabled]="submitDisabled || isLoading">
                {{ 'screens.profile.submitButton' | translate }}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
