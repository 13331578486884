<div class="today-stats">
    <div>
        <div class="font-regular day m-b-s">
            {{comparisonDate.date | date: 'dd.MM.yyyy'}}
        </div>
        <div *ngIf="!isVisionUser" class="font-light cost m-b-s">
            <ng-container
                *ngIf="comparisonDate.costs === 0.0; else leftSideCosts">
                -
                <small class="font-light">
                    {{TEXTS.UNIT_MONEY}}
                </small>
            </ng-container>
            <ng-template #leftSideCosts>
                {{ extractRealValue(formatValue(comparisonDate.costs)) }}
                <small class="font-light">
                    {{ extractDecimalValue(formatValue(comparisonDate.costs)) }}
                    {{ TEXTS.UNIT_MONEY }}
                </small>
            </ng-template>
        </div>
        <div class="font-regular consumption m-b-s">
            <ng-container
                *ngIf="comparisonDate.consumption === 0.0; else leftSideConsumption">
                -
            </ng-container>
            <ng-template #leftSideConsumption>
                {{ comparisonDate.consumption | todayValueFormat }}
            </ng-template>
            {{ TEXTS.UNIT_CONSUMPTION }}
        </div>
    </div>
    <div>
        <div class="font-regular day m-b-s">
            {{ TEXTS.LABEL_COMPARISON_TODAY }}
        </div>
        <div *ngIf="!isVisionUser" class="font-light cost m-b-s">
            <ng-container *ngIf="today.costs === 0.0; else rightSideCosts">
                -
                <small class="font-light">
                    {{TEXTS.UNIT_MONEY}}
                </small>
            </ng-container>
            <ng-template #rightSideCosts>
                {{ extractRealValue(formatValue(today.costs)) }}
                <small class="font-light">
                    {{ extractDecimalValue(formatValue(today.costs)) }}
                    {{ TEXTS.UNIT_MONEY}}
                </small>
            </ng-template>
        </div>
        <div class="font-regular consumption m-b-s">
            <ng-container
                *ngIf="today.consumption === 0.0; else rightSideConsumption">
                -
            </ng-container>
            <ng-template #rightSideConsumption>
                {{ today.consumption | todayValueFormat }}
            </ng-template>
            {{TEXTS.UNIT_CONSUMPTION}}
        </div>
    </div>

</div>
