import {Injectable} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import * as moment from 'moment';
import {map, mergeMap} from 'rxjs/operators';
import {
    ComparisonDisplayMode,
    ComparisonTimeframe,
    DynamicComparisonDates
} from '../../tiles/compare/comparison-details/comparison-details.component';
import {UserService} from '../user.service';
import {UserTariffService} from '../user-tariff.service';
import {BaseResponse} from '../../shared/interfaces/base-response.interfaces';
import {constants} from '../../shared/constants/constants';
import {HttpClient} from '@angular/common/http';
import {ApplicationService} from '../application.service';
import {HappyHourService} from '../happy-hour.service';
import {
    ConsumptionResponseData
} from '../../shared/interfaces/plain-responses/electricity-response.interface';
import {ElectricityService} from '../electricity.service';


@Injectable({
    providedIn: 'root'
})
export class ComparisonDataProviderService {
    private readonly maxLengthOfPlottableSeries = 6;

    private readonly minYear = 2018; // todo include min year in request structure
    private readonly lhsColor = '#d27300';
    private readonly rhsColor = '#f59b00';

    private readonly lhsArrayPosition = 0;
    private readonly rhsArrayPosition = 1;

    private readonly happyHourDayFormat = 'YYYY-MM-DD';
    private readonly happyHourMonthFormat = 'YYYY-MM';

    private currentTimeframe: ComparisonTimeframe = null;
    private currentOffset = 0;
    private currentDisplayMode: ComparisonDisplayMode = ComparisonDisplayMode.COST;
    private stepForwardDisabled = false;
    private stepBackDisabled = false;

    private cachedDemoConsumptionData: ConsumptionResponseData[] = null;

    private currentComparisonDates: ComparisonDates | null = null;
    private currentCalendarWeekEnd: number;
    private currentCalendarWeekStart: number;


    constructor(
        private http: HttpClient,
        private electricityService: ElectricityService,
        private userService: UserService,
        private userTariffService: UserTariffService,
        private applicationService: ApplicationService,
        private happyHourService: HappyHourService,
    ) {

    }


    /**
     * Resets the current timeframe and offset
     * @private
     */
    resetTimeframeAndOffset(): void {
        this.currentOffset = 0;
        this.currentComparisonDates = null;
    }


    /**
     * Returns if stepping backward is disabled.
     * This is the case if the earliest comparison date becomes older than the minimum defined year
     */
    isStepBackDisabled(): boolean {
        return this.stepBackDisabled;
    }


    /**
     * Returns if stepping forward is disabled.
     * This is the case if the latest comparison date for the rhs is the current date
     */
    isStepForwardDisabled(): boolean {
        return this.stepForwardDisabled;
    }


    /**
     * Returns plottable data for the comparison chart in static mode.
     * @param newOffset
     * @param newTimeframe
     * @param newDisplayMode
     */
    getStaticComparisonDataForOffset$(
        newOffset: number,
        newTimeframe: ComparisonTimeframe,
        newDisplayMode: ComparisonDisplayMode)
        : Observable<ComparisonMapping[]> {

        return this.constructStaticComparisonDatesForOffsetTimeframe$(
            newTimeframe, newOffset, newDisplayMode
        ).pipe(
            mergeMap((dates) => {
                this.currentTimeframe = newTimeframe;
                this.currentDisplayMode = newDisplayMode;
                return this.requestDataForComparisonDates$(
                    this.currentComparisonDates, newTimeframe, newDisplayMode
                );
            }),
            mergeMap((response) => {
                if (!response) {
                    console.log('Conflict: No response.');
                    return throwError(null);
                }
                if (response.length === 0) {
                    console.log('Conflict: Empty response.');
                    return throwError(null);
                }
                return of(response);
            }),
            mergeMap(data => {
                if (data.length !== 2) {
                    console.log('Conflict: Unexpected amount of responses.');
                    return throwError(
                        () => new Error('Conflict: Unexpected amount of responses.'));
                }
                return of(data);
            }),
            mergeMap(filteredData =>
                this.mapStaticComparisonDataToSeries$(filteredData, newTimeframe, newDisplayMode)
            ),
            mergeMap(mappings => {
                if (newTimeframe !== ComparisonTimeframe.YEAR) {
                    return this.padMissingDataPointsInSeries$(mappings);
                }
                return of(mappings);
            })
        );
    }


    /**
     * Returns plottable data for the comparison chart in dynamic mode.
     * @param rawDates
     * @param newDisplayMode
     * @param newTimeframe
     */
    getDynamicComparisonDataForComparisonDates$(
        rawDates: DynamicComparisonDates,
        newTimeframe: ComparisonTimeframe,
        newDisplayMode: ComparisonDisplayMode
    ): Observable<ComparisonMapping[]> {
        return this.constructDynamicComparisonDatesForTimeframe$(newTimeframe, rawDates).pipe(
            mergeMap((dates) =>
                this.requestDataForComparisonDates$(dates, newTimeframe, newDisplayMode)
            ),
            mergeMap((response) => {
                if (!response) {
                    return throwError(null);
                }
                if (response.length === 0) {
                    return throwError(null);
                }
                return of(response);
            }),
            mergeMap(data => {
                if (data.length !== 2) {
                    console.log('Conflict: Unexpected amount of responses.');
                    return throwError(
                        () => new Error('Conflict: Unexpected amount of responses.'));
                }
                return of(data);
            }),
            mergeMap(data =>
                this.mapDynamicComparisonDataToSeries$(data, newTimeframe, newDisplayMode)
            )
        );
    }


    /**
     * Returns plottable data for the comparison chart in demo mode.
     * @param newOffset
     * @param newTimeframe
     * @param newDisplayMode
     */
    getStaticDemoDataForOffsetTimeframe$(
        newOffset: number,
        newTimeframe: ComparisonTimeframe,
        newDisplayMode: ComparisonDisplayMode
    ): Observable<any> {
        return this.constructStaticComparisonDatesForOffsetTimeframe$(
            newTimeframe,
            newOffset,
            newDisplayMode
        ).pipe(
            mergeMap((comparisonDates) =>
                this.requestDemoDataForTimeFrame$(newTimeframe).pipe(
                    mergeMap((data) =>
                        this.alignDemoDataToTimeframe$(data, newTimeframe)
                    ),
                    mergeMap((entireDataset) =>
                        this.filterDemoDataForComparisonDates$(
                            entireDataset, comparisonDates
                        )
                    )
                )
            ),
            mergeMap((filteredData) =>
                this.mapStaticComparisonDataToSeries$(
                    filteredData, newTimeframe, newDisplayMode
                )
            ),
            mergeMap((mappings) => {
                if (newTimeframe !== ComparisonTimeframe.YEAR) {
                    return this.padMissingDataPointsInSeries$(mappings);
                }
                return of(mappings);
            })
        );
    }


    /**
     * Returns plottable demo data for the comparison chart in dynamic mode.
     * @param rawDates
     * @param newTimeframe
     * @param displayMode
     */
    getDynamicDemoDataForComparisonDates$(
        rawDates: DynamicComparisonDates,
        newTimeframe: ComparisonTimeframe,
        displayMode: ComparisonDisplayMode
    ): Observable<ComparisonMapping[]> {
        return this.constructDynamicComparisonDatesForTimeframe$(newTimeframe, rawDates).pipe(
            mergeMap((comparisonDates) =>
                this.requestDemoDataForTimeFrame$(newTimeframe).pipe(
                    mergeMap((data) =>
                        this.alignDemoDataToTimeframe$(data, newTimeframe)
                    ),
                    mergeMap((entireDataset) =>
                        this.filterDemoDataForDynamicComparisonDates$(
                            entireDataset, comparisonDates
                        )
                    )
                )
            ),
            mergeMap(data =>
                this.mapDynamicComparisonDataToSeries$(data, newTimeframe, displayMode)
            )
        );
    }


    /**
     * Returns plottable data for the comparison chart in enviaM happy hour mode.
     * @param newOffset
     * @param newTimeframe
     * @param newDisplayMode
     */
    getStaticHappyHourDataForOffsetTimeframe$(
        newOffset: number,
        newTimeframe: ComparisonTimeframe,
        newDisplayMode: ComparisonDisplayMode
    ): Observable<any> {
        return this.constructStaticComparisonDatesForOffsetTimeframe$(
            newTimeframe,
            newOffset,
            newDisplayMode
        ).pipe(
            mergeMap((comparisonDates) =>
                this.formatDynamicComparisonDatesForHappyHourRequest$(
                    comparisonDates, newTimeframe
                )
            ),
            mergeMap((formattedDates) =>
                this.requestHappyHourDataForComparisonDates$(formattedDates)
            ),
            mergeMap((zippedResponse: HappyHourComparisonConsumptionData[]) =>
                this.mapStaticComparisonDataToSeries$(
                    zippedResponse, newTimeframe, newDisplayMode
                )
            ),
            mergeMap(mappings => {
                if (newTimeframe !== ComparisonTimeframe.YEAR) {
                    this.padMissingDataPointsInSeries$(mappings);
                }
                return of(mappings);
            })
        );
    }


    /**
     * Returns plottable data for the comparison chart in enviaM happy hour mode.
     * @param dates
     * @param newTimeframe
     * @param newDisplayMode
     */
    getDynamicHappyHourDataForComparisonDates$(
        dates: DynamicComparisonDates,
        newTimeframe: ComparisonTimeframe,
        newDisplayMode: ComparisonDisplayMode
    ): Observable<any> {
        return this.constructDynamicComparisonDatesForTimeframe$(newTimeframe, dates).pipe(
            mergeMap((comparisonDates) =>
                this.formatDynamicComparisonDatesForHappyHourRequest$(
                    comparisonDates, newTimeframe
                )
            ),
            mergeMap((formattedDates) =>
                this.requestHappyHourDataForComparisonDates$(formattedDates)
            ),
            mergeMap((zippedResponse) =>
                this.mapDynamicComparisonDataToSeries$(
                    zippedResponse, newTimeframe, newDisplayMode
                )
            )
        );
    }


    /**
     * Generate Requests to be sent based on the selected timeframe and passed comparison dates.
     * Uses zip to combine the requests for lhs and rhs.
     * Response data is identified by the type property which is either 'lhs' or 'rhs'.
     * @param dates
     * @param timeframe
     * @param displayMode
     * @private
     */
    private requestDataForComparisonDates$(
        dates: ComparisonDates,
        timeframe: ComparisonTimeframe,
        displayMode: ComparisonDisplayMode
    ): Observable<ComparisonConsumptionData[]> {

        let requestTimeframe;
        switch (timeframe) {
            case ComparisonTimeframe.DAY:
            case ComparisonTimeframe.WEEK:
                requestTimeframe = 'days';
                break;
            default:
                requestTimeframe = 'months';
                break;
        }

        let lhsRequest: Observable<any>;
        let rhsRequest: Observable<any>;
        if (displayMode === ComparisonDisplayMode.FEEDIN) {
            rhsRequest = this.electricityService.getFeedinForTimeframe(
                dates.startRhs, dates.endRhs, requestTimeframe
            ).pipe(
                map(data => {
                    return {data, type: 'rhs'};
                })
            );
            lhsRequest = this.electricityService.getFeedinForTimeframe(
                dates.startLhs, dates.endLhs, requestTimeframe
            ).pipe(
                map(data => {
                    return {data, type: 'lhs'};
                })
            );
        } else {
            rhsRequest = this.electricityService.getConsumptionForTimeframe(
                dates.startRhs, dates.endRhs, requestTimeframe
            ).pipe(
                map(data => {
                    return {data, type: 'rhs'};
                })
            );
            lhsRequest = this.electricityService.getConsumptionForTimeframe(
                dates.startLhs, dates.endLhs, requestTimeframe
            ).pipe(
                map(data => {
                    return {data, type: 'lhs'};
                })
            );
        }
        return zip(lhsRequest, rhsRequest);
    }


    private requestHappyHourDataForComparisonDates$(
        dates: FormattedHappyHourRequestParameters
    ): Observable<ComparisonConsumptionData[]> {
        const lhsRequest = this.happyHourService.getConsumptionFor(
            dates.apiMode, dates.startLhs, dates.endLhs
        ).pipe(
            map((data) => {
                return {type: 'lhs', data} as HappyHourComparisonConsumptionData;
            })
        );
        const rhsRequest = this.happyHourService.getConsumptionFor(
            dates.apiMode, dates.startRhs, dates.endRhs
        ).pipe(
            map((data) => {
                return {type: 'rhs', data} as HappyHourComparisonConsumptionData;
            })
        );
        return zip(lhsRequest, rhsRequest);
    }


    /**
     * Maps response data for YEAR timeframe.
     * Uses monthly data to calculate yearly consumption.
     * @param consumptionResponseData
     * @param displayMode
     * @param isLastYear
     * @private
     */
    private mapDataForTimeframeYear(
        consumptionResponseData: ConsumptionResponseData[],
        displayMode: ComparisonDisplayMode,
        isLastYear: boolean
    ): ComparisonMapping {
        let totalConsumption = 0;
        for (const consumptionDataPoint of consumptionResponseData) {
            totalConsumption += this.determineSeriesValue(
                consumptionDataPoint,
                displayMode === ComparisonDisplayMode.COST
            );
        }
        const mappedConsumption = [totalConsumption / 1000];
        const year = moment(consumptionResponseData[0].timestamp).year().toString();
        const categories = [''];
        return {
            series: {
                color: isLastYear ? this.lhsColor : this.rhsColor,
                data: mappedConsumption,
                name: year
            },
            categories
        };
    }


    /**
     * Maps response data for WEEK timeframe.
     * Uses day-based data to calculate weekly consumption.
     * @param consumptionResponseData
     * @param displayMode
     * @param isLastYear
     * @private
     */
    private mapDataForTimeframeWeek(
        consumptionResponseData: ConsumptionResponseData[],
        displayMode: ComparisonDisplayMode,
        isLastYear: boolean
    ): ComparisonMapping {
        const consumptions: { calendarWeek: number, consumption: number, timestamp: string }[] = [];
        for (const consumptionDataPoint of consumptionResponseData) {
            const calendarWeek =
                moment(consumptionDataPoint.timestamp).locale('de').week();
            const value = this.determineSeriesValue(
                consumptionDataPoint,
                displayMode === ComparisonDisplayMode.COST
            );
            const foundIndex = consumptions.map(
                el => el.calendarWeek
            ).indexOf(calendarWeek);

            if (foundIndex >= 0) {
                consumptions[foundIndex].consumption += value;
            } else {
                consumptions.push(
                    {
                        calendarWeek,
                        timestamp: consumptionDataPoint.timestamp,
                        consumption: value
                    }
                );
            }
        }
        const mapped = consumptions.map(consumption => consumption.consumption / 1000);
        const categories = [];
        for (const calculatedConsumption of consumptions) {
            categories.push(`KW ${calculatedConsumption.calendarWeek}`);
        }
        // due to the fact, that weeks may overlap in years, select a somewhat central data point
        // to determine the year
        const centralDataPointIdx = Math.floor(consumptions.length / 2);
        const name = moment(
            consumptionResponseData[centralDataPointIdx].timestamp
        ).year().toString();
        return {
            series: {
                color: isLastYear ? this.lhsColor : this.rhsColor,
                data: mapped,
                name,
            },
            categories
        };
    }


    /**
     * Maps response data for MONTH or DAY timeframe
     * Uses either month or day based data
     * @param consumptionResponseData
     * @param displayMode
     * @param isLastYear
     * @param isDayMapping
     * @private
     */
    private mapDataForTimeFrameDayMonth(
        consumptionResponseData: ConsumptionResponseData[],
        displayMode: ComparisonDisplayMode,
        isLastYear: boolean,
        isDayMapping: boolean,
    ): ComparisonMapping {
        const mapped = consumptionResponseData.map(consumption => {
            const value = this.determineSeriesValue(
                consumption,
                displayMode === ComparisonDisplayMode.COST
            );
            return value / 1000;
        });
        const categories = [];
        for (const consumptionDataPoint of consumptionResponseData) {
            if (isDayMapping) {
                const date = moment(consumptionDataPoint.timestamp).date();
                const month = moment(consumptionDataPoint.timestamp)
                    .locale('de')
                    .format('MMMM');
                categories.push(`${date} ${month}`);
            } else {
                categories.push(
                    moment(consumptionDataPoint.timestamp)
                        .locale('de')
                        .format('MMMM')
                );
            }
        }
        return {
            series: {
                color: isLastYear ? this.lhsColor : this.rhsColor,
                name: moment(consumptionResponseData.last().timestamp).year().toString(),
                data: mapped
            },
            categories,
        };
    }


    /**
     * Maps ComparisonConsumptionData to the series format for DYNAMIC mode.
     *  - Mapping is straight forward since all acquired data only needs to be summed up.
     *  - Timeframe is used to determine the date format.
     *  - The position of rhs and lhs is predefined by the properties
     *    - lhsArrayPosition
     *    - rhsArrayPosition
     * @param comparisonData
     * @param timeframe
     * @param displayMode
     * @private
     */
    private mapDynamicComparisonDataToSeries$(
        comparisonData: ComparisonConsumptionData[],
        timeframe: ComparisonTimeframe,
        displayMode: ComparisonDisplayMode
    ): Observable<ComparisonMapping[]> {
        const finalSeries: ComparisonMapping[] = new Array(2);
        for (const element of comparisonData) {
            const currentDatasetType = element.type;
            let consumptionSummed = 0;
            for (const consumption of element.data) {
                consumptionSummed += this.determineSeriesValue(
                    consumption,
                    displayMode === ComparisonDisplayMode.COST
                );
            }
            const lastTimestamp = moment(element.data.last().timestamp);
            let dateFormatted = '';
            switch (timeframe) {
                case ComparisonTimeframe.DAY:
                    dateFormatted = lastTimestamp.format('DD.MM.YYYY');
                    break;
                case ComparisonTimeframe.WEEK:
                    dateFormatted = lastTimestamp.format('W YYYY');
                    break;
                case ComparisonTimeframe.MONTH:
                    dateFormatted = lastTimestamp.locale('de').format('MMMM YYYY');
                    break;
                case ComparisonTimeframe.YEAR:
                    dateFormatted = lastTimestamp.format('YYYY');
                    break;
            }

            const series: ComparisonMapping = {
                series: {
                    name: dateFormatted,
                    data: [consumptionSummed / 1000],
                    color: currentDatasetType === 'lhs' ?
                        this.lhsColor : this.rhsColor,
                },
                categories: ['', '']
            };

            if (currentDatasetType === 'lhs') {
                finalSeries[this.lhsArrayPosition] = series;
            } else {
                finalSeries[this.rhsArrayPosition] = series;
            }
        }
        return of(finalSeries);
    }


    /**
     * Constructs the dates for the comparison.
     * - The dates are calculated based on the offset and timeframe.
     * - If the timeframe has not changed but the displaymode has, the dates are not recalculated.
     * @param timeframe
     * @param newOffset
     * @param newDisplayMode
     * @private
     */
    private constructStaticComparisonDatesForOffsetTimeframe$(
        timeframe: ComparisonTimeframe, newOffset: number, newDisplayMode: ComparisonDisplayMode
    ): Observable<ComparisonDates> {

        if (this.currentTimeframe !== timeframe) {
            // console.log(' === TIMEFRAME HAS CHANGED - Resetting! === ');
            this.resetTimeframeAndOffset();
        } else {
            if (this.currentDisplayMode !== newDisplayMode) {
                this.currentDisplayMode = newDisplayMode;
                return of(this.currentComparisonDates);
            }
        }

        let unit: any = '';
        switch (timeframe) {
            case ComparisonTimeframe.DAY:
                unit = 'days';
                break;
            case ComparisonTimeframe.MONTH:
                unit = 'months';
                break;
            case ComparisonTimeframe.YEAR:
                unit = 'years';
                break;
            case ComparisonTimeframe.WEEK:
                unit = 'weeks';
                break;
        }

        let endRhs;
        let startRhs;
        let endLhs;
        let startLhs;
        const isBackwardMovement = this.currentOffset < newOffset;

        if (!this.currentComparisonDates) {
            if (timeframe === ComparisonTimeframe.WEEK) {
                this.currentCalendarWeekEnd = moment().locale('de').week();
                this.currentCalendarWeekStart =
                    this.currentCalendarWeekEnd - this.maxLengthOfPlottableSeries - 1;
                endRhs = moment()
                    .locale('de')
                    .endOf('week');
                startRhs = moment(endRhs)
                    .subtract(this.maxLengthOfPlottableSeries - 1, unit)
                    .startOf('week');
                endLhs = moment()
                    .locale('de')
                    .set({year: endRhs.year() - 1})
                    .set({week: this.currentCalendarWeekEnd})
                    .endOf('week');
                startLhs = moment(endLhs)
                    .subtract(this.maxLengthOfPlottableSeries - 1, unit)
                    .startOf('week');

                const endWeek = moment(endRhs).week();
                const startWeek = moment(startRhs).week();

                if (startWeek > endWeek) {
                    startRhs = moment(endRhs)
                        .locale('de')
                        .set({week: 1})
                        .startOf('week');
                    startLhs = moment(endLhs)
                        .locale('de')
                        .set({week: 1})
                        .startOf('week');
                }

            } else {
                endRhs = moment().locale('de').endOf(unit);
                startRhs = moment(endRhs).subtract(this.maxLengthOfPlottableSeries - 1, unit);

                if (startRhs.year() !== endRhs.year()) {
                    if (isBackwardMovement) {
                        startRhs = moment(endRhs).startOf('year');
                    }
                }
            }
        } else {
            if (timeframe === ComparisonTimeframe.WEEK) {
                if (isBackwardMovement) {
                    endRhs = moment(this.currentComparisonDates.startRhs)
                        .locale('de')
                        .subtract(1, 'day');
                    startRhs = moment(endRhs)
                        .subtract(this.maxLengthOfPlottableSeries - 1, 'weeks')
                        .locale('de')
                        .startOf('week');
                    endLhs = moment(this.currentComparisonDates.startLhs)
                        .locale('de')
                        .subtract(1, 'day');
                    startLhs = moment(endLhs)
                        .locale('de')
                        .subtract(this.maxLengthOfPlottableSeries - 1, 'weeks')
                        .startOf('week');

                    const startWeek = startRhs.week();
                    const endWeek = endRhs.week();
                    if (startWeek > endWeek) {
                        startRhs = moment(endRhs)
                            .locale('de')
                            .set({week: 1})
                            .startOf('week');
                        startLhs = moment(endLhs)
                            .locale('de')
                            .set({week: 1})
                            .set({year: endRhs.year() - 1})
                            .startOf('week');
                    }
                } else {
                    startRhs = moment(this.currentComparisonDates.endRhs)
                        .locale('de')
                        .add(1, 'day');
                    endRhs = moment(startRhs)
                        .locale('de')
                        .add(this.maxLengthOfPlottableSeries - 1, 'weeks')
                        .endOf('week');
                    startLhs = moment(this.currentComparisonDates.endLhs)
                        .locale('de')
                        .add(1, 'day');
                    endLhs = moment(startLhs)
                        .locale('de')
                        .add(this.maxLengthOfPlottableSeries - 1, 'weeks')
                        .endOf('week');

                    const startWeek = startRhs.week();
                    const endWeek = endRhs.week();
                    if (startWeek > endWeek) {
                        endRhs = moment(startRhs)
                            .locale('de')
                            .set({week: 52})
                            .endOf('week');
                        endLhs = moment(startLhs)
                            .locale('de')
                            .set({week: 52})
                            .endOf('week');
                    }
                }

            } else {
                if (isBackwardMovement) {
                    endRhs = moment(this.currentComparisonDates.startRhs).subtract(1, unit);
                    startRhs = moment(this.currentComparisonDates.startRhs)
                        .subtract(this.maxLengthOfPlottableSeries, unit);
                    if (startRhs.year() !== endRhs.year()) {
                        startRhs = moment(endRhs).startOf('year');
                    }
                } else {
                    startRhs = moment(this.currentComparisonDates.endRhs).add(1, unit);
                    endRhs = moment(startRhs).add(this.maxLengthOfPlottableSeries - 1, unit);
                    if (startRhs.year() !== endRhs.year()) {
                        endRhs = moment(startRhs).endOf('year');
                    }
                }
            }
        }


        // determine start and end dates for lhs by subtracting one year from rhs
        if (timeframe !== ComparisonTimeframe.WEEK) {
            startLhs = moment(startRhs).subtract(1, 'year');
            endLhs = moment(endRhs).subtract(1, 'year');
        }

        // if the end date is in the future, set it to today's date to prevent API errors
        this.stepForwardDisabled = false;
        if (endRhs.toDate() > moment().toDate()) {
            if (timeframe === ComparisonTimeframe.WEEK) {
                endRhs = moment().locale('de').endOf('week');
                endLhs = moment()
                    .locale('de')
                    .set({year: endRhs.year() - 1})
                    .set({week: endRhs.week()})
                    .endOf('week');
            } else if (timeframe === ComparisonTimeframe.YEAR) {
                endRhs = moment();
                endLhs = moment(endRhs)
                    .subtract(1, 'year')
                    .endOf('year');
            } else {
                endRhs = moment();
                endLhs = moment(endRhs).subtract(1, 'year');
            }
            this.stepForwardDisabled = true;
        }

        // disable back if the minimum date is reached
        this.stepBackDisabled = startRhs.year() < this.minYear;

        this.currentOffset = newOffset;
        this.currentComparisonDates = {
            startRhs: startRhs.toDate(),
            endRhs: endRhs.toDate(),
            startLhs: startLhs.toDate(),
            endLhs: endLhs.toDate()
        };
        return of(this.currentComparisonDates);

    }


    /**
     * Constructs the dates for dynamic comparison based on dropdown selected values.
     * @param timeframe
     * @param comparisonDatesRaw
     * @private
     */
    private constructDynamicComparisonDatesForTimeframe$(
        timeframe: ComparisonTimeframe,
        comparisonDatesRaw: DynamicComparisonDates
    ): Observable<ComparisonDates> {
        let lhsStart;
        let lhsEnd;

        let rhsStart;
        let rhsEnd;

        switch (timeframe) {
            case ComparisonTimeframe.DAY:
                lhsStart = moment()
                    .set('year', comparisonDatesRaw.lhsYear)
                    .set('month', comparisonDatesRaw.lhsMonth - 1)
                    .set('date', comparisonDatesRaw.lhsDay)
                    .startOf('day')
                    .toDate();
                lhsEnd = moment(lhsStart).endOf('day').toDate();
                rhsStart = moment()
                    .set('year', comparisonDatesRaw.rhsYear)
                    .set('month', comparisonDatesRaw.rhsMonth - 1)
                    .set('date', comparisonDatesRaw.rhsDay)
                    .startOf('day')
                    .toDate();
                rhsEnd = moment(rhsStart).endOf('day').toDate();
                break;
            case ComparisonTimeframe.WEEK: { // Woche
                lhsStart = moment()
                    .locale('de')
                    .set('year', comparisonDatesRaw.lhsYear)
                    .set('week', comparisonDatesRaw.lhsCalendarWeek)
                    .startOf('week')
                    .toDate();
                lhsEnd = moment(lhsStart)
                    .locale('de')
                    .endOf('week')
                    .toDate();
                rhsStart = moment()
                    .locale('de')
                    .set('year', comparisonDatesRaw.rhsYear)
                    .set('week', comparisonDatesRaw.rhsCalendarWeek)
                    .startOf('week')
                    .toDate();
                rhsEnd = moment(rhsStart)
                    .locale('de')
                    .endOf('week')
                    .toDate();
                break;
            }
            case ComparisonTimeframe.MONTH: { // Monat
                lhsStart = moment()
                    .set('month', comparisonDatesRaw.lhsMonth - 1)
                    .set('year', comparisonDatesRaw.lhsYear)
                    .startOf('month')
                    .toDate();
                lhsEnd = lhsStart;
                rhsStart = moment()
                    .set('month', comparisonDatesRaw.rhsMonth - 1)
                    .set('year', comparisonDatesRaw.rhsYear)
                    .startOf('month')
                    .toDate();
                rhsEnd = rhsStart;
                break;
            }
            case ComparisonTimeframe.YEAR: { // Jahr
                lhsStart = moment()
                    .set('year', comparisonDatesRaw.lhsYear)
                    .startOf('year')
                    .toDate();
                lhsEnd = moment()
                    .set('year', comparisonDatesRaw.lhsYear)
                    .endOf('year')
                    .toDate();
                rhsStart = moment()
                    .set('year', comparisonDatesRaw.rhsYear)
                    .startOf('year')
                    .toDate();
                rhsEnd = moment()
                    .set('year', comparisonDatesRaw.rhsYear)
                    .endOf('year')
                    .toDate();
                break;
            }
        }
        return of({
            startRhs: rhsStart,
            endRhs: rhsEnd,
            startLhs: lhsStart,
            endLhs: lhsEnd
        });
    }


    private formatDynamicComparisonDatesForHappyHourRequest$(
        comparisonDates: ComparisonDates,
        timeframe: ComparisonTimeframe
    ): Observable<FormattedHappyHourRequestParameters> {
        let rhsStart;
        let rhsEnd;
        let lhsStart;
        let lhsEnd;
        let apiMode: 'day' | 'week' | 'month' | 'year' = 'day';
        switch (timeframe) {
            case ComparisonTimeframe.DAY:
                rhsStart = moment(comparisonDates.startRhs).format(this.happyHourDayFormat);
                rhsEnd = moment(comparisonDates.endRhs).format(this.happyHourDayFormat);
                lhsStart = moment(comparisonDates.startLhs).format(this.happyHourDayFormat);
                lhsEnd = moment(comparisonDates.endLhs).format(this.happyHourDayFormat);
                apiMode = 'day';
                break;
            case ComparisonTimeframe.WEEK:
                rhsStart = moment(comparisonDates.startRhs).format(this.happyHourDayFormat);
                rhsEnd = moment(comparisonDates.endRhs).format(this.happyHourDayFormat);
                lhsStart = moment(comparisonDates.startLhs).format(this.happyHourDayFormat);
                lhsEnd = moment(comparisonDates.endLhs).format(this.happyHourDayFormat);
                apiMode = 'week';
                break;
            case ComparisonTimeframe.MONTH:
                rhsStart = moment(comparisonDates.startRhs).format(this.happyHourMonthFormat);
                rhsEnd = moment(comparisonDates.endRhs).format(this.happyHourMonthFormat);
                lhsStart = moment(comparisonDates.startLhs).format(this.happyHourMonthFormat);
                lhsEnd = moment(comparisonDates.endLhs).format(this.happyHourMonthFormat);
                apiMode = 'month';
                break;
            case ComparisonTimeframe.YEAR:
                rhsStart = moment(comparisonDates.startRhs).year();
                rhsEnd = moment(comparisonDates.endRhs).year();
                lhsStart = moment(comparisonDates.startLhs).year();
                lhsEnd = moment(comparisonDates.endLhs).year();
                apiMode = 'year';
                break;
        }
        return of({
            startRhs: rhsStart,
            endRhs: rhsEnd,
            startLhs: lhsStart,
            endLhs: lhsEnd,
            apiMode
        });
    }


    /**
     * Determines the value for the series based on the display mode
     * @param consumptionDataPoint
     * @param forCost
     * @private
     */
    private determineSeriesValue(
        consumptionDataPoint: ConsumptionResponseData,
        forCost = false
    ): number {
        let value = 0;
        let costMultiplier = 1;

        // if in demo mode just return the value
        if (this.applicationService.isDemoMode()) {
            return consumptionDataPoint.measured;
        }

        if (forCost) {
            const parsedTimestamp = new Date(consumptionDataPoint.timestamp);
            costMultiplier = this.userTariffService
                .getTariffMultiplier(parsedTimestamp);


            if (this.userService.isERNAUser()) {
                value = 'measured' in consumptionDataPoint
                    ? consumptionDataPoint.measured * costMultiplier
                    : 0;
            } else {
                value = 'cost_measured' in consumptionDataPoint
                    ? consumptionDataPoint.cost_measured * 1000
                    : 0;
            }
        } else {
            value = 'measured' in consumptionDataPoint
                ? consumptionDataPoint.measured * costMultiplier
                : 0;
        }
        return value;
    }


    /**
     *
     * @param timeframe
     * @private
     */
    private requestDemoDataForTimeFrame$(
        timeframe: ComparisonTimeframe,
    ): Observable<ConsumptionResponseData[]> {

        let datasetFile = '';
        switch (timeframe) {
            case ComparisonTimeframe.DAY:
            case ComparisonTimeframe.WEEK:
                datasetFile = constants.demo.files.consumptionDays;
                break;
            case ComparisonTimeframe.YEAR:
            case ComparisonTimeframe.MONTH:
                datasetFile = constants.demo.files.consumptionMonths;
                break;
        }

        if (this.currentTimeframe === timeframe && this.cachedDemoConsumptionData) {
            return of(this.cachedDemoConsumptionData);
        }

        const fileUrl = `assets/data/demo/${datasetFile}.json`;
        return this.http.get(fileUrl).pipe(
            mergeMap((fileContents: BaseResponse) => {
                try {
                    return of(fileContents.data);
                } catch (error) {
                    return throwError(() => error);
                }
            }),
            mergeMap((data: ConsumptionResponseData[]) => {
                this.cachedDemoConsumptionData = data;
                this.currentTimeframe = timeframe;
                data.reverse();
                return of(data);
            })
        );
    }


    /**
     * Aligns the data to the timeframe.
     * The function will only update the dates from the current date.
     * @param dataset
     * @param timeframe
     * @private
     */
    private alignDemoDataToTimeframe$(
        dataset: ConsumptionResponseData[],
        timeframe: ComparisonTimeframe
    ): Observable<ConsumptionResponseData[]> {

        let alignmentUnit = '';
        let alignmentStartUnit = '';
        switch (timeframe) {
            case ComparisonTimeframe.DAY:
                alignmentStartUnit = 'day';
                alignmentUnit = 'days';
                break;
            case ComparisonTimeframe.WEEK:
                alignmentStartUnit = 'week';
                alignmentUnit = 'days';
                break;
            case ComparisonTimeframe.YEAR:
            case ComparisonTimeframe.MONTH:
                alignmentStartUnit = 'month';
                alignmentUnit = 'months';
                break;
        }
        const startDate = moment();
        const alignedData = dataset.map((element, idx) => {
            const date = moment(startDate)
                .set('year', moment().year())
                .subtract(idx, alignmentUnit as any)
                .startOf(alignmentStartUnit as any);
            element.timestamp = date.toDate().toString();
            return element;
        });

        alignedData.reverse();

        return of(alignedData);
    }


    /**
     * Filters the data for the given comparison dates.
     *  - For lhs the function subtracts one year from the rhs dates
     *  - Hence lhs data will always contain the same values as rhs data
     * @param dataset
     * @param comparisonDates
     * @private
     */
    private filterDemoDataForComparisonDates$(
        dataset: ConsumptionResponseData[],
        comparisonDates: ComparisonDates
    ): Observable<ComparisonConsumptionData[]> {
        const filtered = dataset.filter((element) => {
            const elementTs = moment(element.timestamp).toDate();
            return elementTs >= comparisonDates.startRhs && elementTs <= comparisonDates.endRhs;
        });
        const copiedData = JSON.parse(JSON.stringify(filtered));
        const filteredLhs = copiedData.map((element) => {
            const date = moment(element.timestamp)
                .subtract(1, 'year')
                .toDate();
            element.timestamp = date.toString();
            return element;
        });
        return of([
            {type: 'rhs', data: filtered},
            {type: 'lhs', data: filteredLhs}
        ]);
    }


    /**
     * Filters the data for the dynamic comparison dates.
     * This is different from static mode since we actually have to filter the data.
     * @param dataset
     * @param comparisonDates
     * @private
     */
    private filterDemoDataForDynamicComparisonDates$(
        dataset: ConsumptionResponseData[],
        comparisonDates: ComparisonDates
    ): Observable<ComparisonConsumptionData[]> {
        const filteredRhs = dataset.filter((element) => {
            const elementTs = moment(element.timestamp).toDate();
            return elementTs >= comparisonDates.startRhs && elementTs <= comparisonDates.endRhs;
        });
        const filteredLhs = dataset.filter((element) => {
            const elementTs = moment(element.timestamp).toDate();
            return elementTs >= comparisonDates.startLhs && elementTs <= comparisonDates.endLhs;
        });
        return of([
            {type: 'rhs', data: filteredRhs},
            {type: 'lhs', data: filteredLhs}
        ]);
    }


    /**
     * Maps ComparisonConsumptionData to the series format for STATIC mode.
     *  - Mapping is done based on the timeframe and display mode.
     *  - The position of rhs and lhs is predefined by the properties
     *    - lhsArrayPosition
     *    - rhsArrayPosition
     * @param comparisonData
     * @param timeframe
     * @param displayMode
     * @private
     */
    private mapStaticComparisonDataToSeries$(
        comparisonData: ComparisonConsumptionData[],
        timeframe: ComparisonTimeframe,
        displayMode: ComparisonDisplayMode,
    ): Observable<ComparisonMapping[]> {
        const finalSeries = new Array(2);
        for (const dataset of comparisonData) {
            let comparisonMapping = null;
            switch (timeframe) {
                case ComparisonTimeframe.DAY:
                    comparisonMapping = this.mapDataForTimeFrameDayMonth(
                        dataset.data,
                        displayMode,
                        dataset.type === 'lhs',
                        true,
                    );
                    break;
                case ComparisonTimeframe.WEEK:
                    comparisonMapping = this.mapDataForTimeframeWeek(
                        dataset.data,
                        displayMode,
                        dataset.type === 'lhs',
                    );
                    break;
                case ComparisonTimeframe.MONTH:
                    comparisonMapping = this.mapDataForTimeFrameDayMonth(
                        dataset.data,
                        displayMode,
                        dataset.type === 'lhs',
                        false,
                    );
                    break;
                case ComparisonTimeframe.YEAR:
                    comparisonMapping = this.mapDataForTimeframeYear(
                        dataset.data,
                        displayMode,
                        dataset.type === 'lhs',
                    );
                    break;
            }
            if (dataset.type === 'lhs') {
                finalSeries[this.lhsArrayPosition] = comparisonMapping;
            } else {
                finalSeries[this.rhsArrayPosition] = comparisonMapping;
            }
        }
        return of(finalSeries);
    }


    /**
     * Padds missing data points in the series.
     * If the amount of data points in the series is shorter than the maximum defined amount of
     * plottable series instert 0 values and empty categories.
     * This is necessary to ensure that each column in the diagram is plotted in the same with.
     * @param mappings
     */
    padMissingDataPointsInSeries$(mappings: ComparisonMapping[]): Observable<ComparisonMapping[]> {
        if (mappings.first().series.data.length < this.maxLengthOfPlottableSeries) {
            const padded = mappings.map(element => {
                const difference = this.maxLengthOfPlottableSeries - element.series.data.length;
                for (let i = 0; i < difference; i++) {
                    element.series.data.push(0);
                    element.categories.push('');
                }
                return element;
            });
            return of(padded);
        }
        return of(mappings);
    }
}


export interface ComparisonDates {
    startRhs: Date;
    endRhs: Date;
    startLhs: Date;
    endLhs: Date;
}


export interface ComparisonConsumptionData {
    data: ConsumptionResponseData[];
    type: string;
}


export interface HappyHourComparisonConsumptionData {
    data: ConsumptionResponseData[];
    type: string;
}


interface ComparisonSeries {
    name: string;
    data: number[];
    color: string;
}


export interface ComparisonMapping {
    series: ComparisonSeries;
    categories: string[];
}


interface FormattedHappyHourRequestParameters {
    startRhs: string;
    endRhs: string;
    startLhs: string;
    endLhs: string;
    apiMode: 'day' | 'week' | 'month' | 'year';
}

