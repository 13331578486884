import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {getProviderImprintUrl} from '../lib/ProviderUtil';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ImprintService extends BaseService {

    imprintUrl = '';

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
    ) {
        super(http, auth, user);
    }

    getImprint(): Observable<any> {
        let provider = this.user.getActiveUserProvider();
        if (provider === 'innogy') {
            provider = 'e.on';
        }
        const imprintUrl = getProviderImprintUrl(provider);
        this.imprintUrl = imprintUrl;
        console.log('url', imprintUrl);
        return this.http.get(imprintUrl, {
            responseType: 'text'
        });
    }

}
