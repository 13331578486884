<div class="overlay">
    <div class="info-view-main">
        <h1>{{ 'ionaSpecific.popovers.infoView.title' | translate }}</h1>
        <div class="banner-image">
            <img src="/assets/images/iona_goes_esc.svg" width="400px" alt="">
        </div>
        <p>{{ 'ionaSpecific.popovers.infoView.p1' | translate }}</p>
        <p>{{ 'ionaSpecific.popovers.infoView.p2' | translate }}</p>
        <div class="esc-links">
            <div>
                <button class="iona-button orange" (click)="openESC()">{{ 'ionaSpecific.popovers.infoView.buttonText' | translate }}</button>
            </div>
            <div>
                <a href="https://smartcontrol.eon.de" target="_blank">https://smartcontrol.eon.de</a>
            </div>
        </div>
        <p>
            {{ 'ionaSpecific.popovers.infoView.p3' | translate }} <br>
            <ng-container *ngIf="showContinue">
                {{ 'ionaSpecific.popovers.infoView.ngIfText' | translate }}
            </ng-container>
        </p>
        <button *ngIf="showContinue" class="continue-button" (click)="close()">{{ 'common.continue' | translate }}</button>
    </div>
</div>
