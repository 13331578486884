<div class="login-container">

    <div class="bg-video">
        <video width="100%" height="100%" autoplay muted loop>
            <source src="../../../assets/media/background.mp4" type="video/mp4">
        </video>
    </div>

    <div class="login-card">
        <div class="app-logo-container center-contents">
            <div class="app-icon"></div>
        </div>

        <div class="top-logo center-contents">
            <h1>iONA</h1>
        </div>

        <ng-container [ngSwitch]="state">
            <!--
              - INITIAL
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.INITIAL">
                <h1 class="innogy-headline m-t-l">{{'ionaSpecific.login.loginTexts.welcomeLine' | translate}}</h1>

                <div class="grow-space">
                    <p>{{'ionaSpecific.login.loginTexts.firstTime1' | translate}}<strong>{{'ionaSpecific.login.loginTexts.code' | translate}}</strong>{{'ionaSpecific.login.loginTexts.firstTime2' | translate}}</p>
                </div>

                <div class="center-contents btn-container">
                    <button class="iona-button scale white" routerLink="registrieren">
                        {{'ionaSpecific.login.loginTexts.register' | translate}}
                    </button>
                </div>

                <div class="center-contents">
                    <a href="javascript:;" class="white"
                       (click)="changeState(LoginState.LOGIN)">{{'ionaSpecific.login.loginTexts.hasPassword' | translate}}</a>
                </div>

                <div class="demo-btn cursor-pointer font-medium">
                    <a href="javascript:;" class="side white" (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? ('screens.login.demoMode' | translate ) : ''}}
                    </a>
                </div>
            </ng-container>

            <!--
              - LOGIN
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.LOGIN">
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="grow-space">
                        <!-- EMAIL -->
                        <!------------------------------------------>
                        <div class="btn-container">
                            <input id="loginEmail" type="email" class="iona-input white"
                                   [placeholder]="'screens.login.placeholderEmail' | translate "
                                   formControlName="email">
                            <label for="loginEmail"></label>
                        </div>
                        <div class="text-field-error font-regular">
                            <div *ngIf="displayEmailError">
                                {{'screens.login.emailError2' | translate}}
                            </div>
                        </div>
                        <fieldset class="input">
                            <input [placeholder]="'common.password' | translate"
                                   [type]="passwordVisible ? 'text' : 'password'"
                                   formControlName="password">
                            <legend>{{'common.password' | translate}}</legend>
                            <i [class.password-hidden]="!passwordVisible"
                               [class.password-visible]="passwordVisible"
                               (click)="passwordVisible = !passwordVisible">
                            </i>
                        </fieldset>

                        <div class="text-field-error font-regular">
                            <div *ngIf="displayPasswordError">
                                {{ 'screens.login.passwordErrorLength' | translate}}
                            </div>
                        </div>

                        <!-- PASSWORD FORGOTTEN -->
                        <!------------------------------------------>
                        <div class="split-row">
                            <div class="demo-btn cursor-pointer font-medium">
                                <a href="javascript:;" class="side white" (click)="continueWithDemoMode()">
                                    {{state === LoginState.LOGIN || state === LoginState.INITIAL ? ('screens.login.demoMode' | translate ) : ''}}
                                </a>
                            </div>
                            <div class="password-reset font-medium">
                                <a href="javascript:;" class="white" (click)="onResetPasswordLinkClick()">
                                    {{'ionaSpecific.login.loginTexts.forgotPassword' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- SUBMIT -->
                    <!------------------------------------------>
                    <div class="center-contents btn-container">
                        <button type="submit" class="iona-button scale white"
                                [disabled]="loginDisabled">
                            {{'common.login' | translate}}
                        </button>
                    </div>

                    <div class="center-contents">
                        <a href="javascript:;" class="white" target="_self" (click)="onRegisterLinkClick()">
                            {{'ionaSpecific.login.loginTexts.register' | translate}}
                        </a>
                    </div>
                </form>
            </ng-container>

            <!--
              - PASSWORD RESET
              - ==============================================================================
              -->
            <ng-container *ngSwitchCase="LoginState.PASSWORD_RESET">
                <h1 class="innogy-headline">{{'ionaSpecific.login.loginTexts.welcomeLine' | translate}}</h1>

                <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassword()">
                    <div class="grow-space p-t-l">
                        <input id="reset-email" type="email" class="iona-input white"
                               [placeholder]="'screens.login.placeholderEmail' | translate "
                               formControlName="email">
                        <label for="reset-email"></label>
                    </div>
                    <div class="text-field-error font-regular">
                        <div *ngIf="displayEmailResetError">
                            <i class="icon-error"></i>
                            {{'screens.login.emailError2' | translate}}
                        </div>
                    </div>

                    <div class="center-contents btn-container">
                        <button class="iona-button scale white" type="submit"
                                (click)="resetPassword()"
                                [disabled]="emailResetDisabled">
                            {{'screens.login.reset' | translate }}
                        </button>
                    </div>
                </form>

                <div class="center-contents">
                    <a href="javascript:;" target="_self" class="white"
                       (click)="changeState(LoginState.LOGIN)">{{'ionaSpecific.login.loginTexts.backToLogin' | translate}}</a>
                </div>

                <div class="demo-btn cursor-pointer font-medium">
                    <a href="javascript:;" class="side" (click)="continueWithDemoMode()">
                        {{state === LoginState.LOGIN || state === LoginState.INITIAL ? ('screens.login.demoMode' | translate ) : ''}}
                    </a>
                </div>
            </ng-container>

        </ng-container>

    </div>
</div>
