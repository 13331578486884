<div class="container inflate">
    <div class="diagram inflate" [chart]="chart"></div>
    <div class="callout-container">
        <div class="callout" [style.opacity]="calloutHidden ? 0 : 1">
            <ng-container [ngSwitch]="isDetail">
                <ng-container *ngSwitchCase="true">
                    <div class="appliance-label-detail font-light">
                        {{currentCallout.label}}
                    </div>
                    <span *ngIf="!isVisionUser" class="divider"></span>
                    <div *ngIf="!isVisionUser" class="appliance-usage-detail font-light">
                        {{currentCallout.value}}
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <div class="appliance-icon"
                         [style.-webkit-mask-image]="currentCallout.image"
                         [style.mask-image]="currentCallout.image"
                         [style.background-color]="currentCallout.color">
                    </div>
                    <div class="appliance-category"
                         [style.color]="currentCallout.color">
                        {{currentCallout.label}}
                    </div>
                    <div class="appliance-usage">
                        {{currentCallout.value}}
                    </div>
                    <span *ngIf="currentCallout.applianceProfileComplete"
                          class="profile-incomplete-indicator"></span>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
