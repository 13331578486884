import {Component, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {AnimationOptions} from 'ngx-lottie';
import {FinanceDataService, FinancialTrend} from '../../../services/finance-data.service';
import {ignoreElements, Observable, of, share} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from '../../../services/user.service';
import {isFakeMousedownFromScreenReader} from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-finance-tile',
    templateUrl: './finance-tile.component.html',
    styleUrls: ['./finance-tile.component.scss'],
    providers: [Globals]
})

export class FinanceTileComponent implements OnInit {


    constructor(private globals: Globals,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private financeDataService: FinanceDataService,
                public userService: UserService,
                private translate: TranslateService) {
    }
    private readonly type: TILE_TYPE = TILE_TYPE.FINANCE;
    LABELS;

    financeData$: Observable<FinancialTrend> = this.financeDataService.getFinancialTrend();
    financeDataError$: Observable<boolean> = this.financeData$.pipe(
        ignoreElements(),
        catchError((error) => of(true))
    );

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/finance.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Smart Meter',
    };


    protected readonly isFakeMousedownFromScreenReader = isFakeMousedownFromScreenReader;


    ngOnInit() {
        this.translate.get('screens.dashboard.financeDetail.financeTile.labels')
            .subscribe((labels: any) => {
                this.LABELS = labels;
            });
    }


    /**
     * Handles on tile clicked Event
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Handles tracking events on opening detail
     */
    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /*
     * ANALYTICS
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Finance',
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Finance-Details'
            }
        });
    }
}
