import {
    AfterViewInit,
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {of} from 'rxjs';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import {ApiService} from '../../../services/api.service';
import {MvpConfig} from '../../../services/mvp.service';

@Component({
    selector: 'app-mvp-details',
    templateUrl: './mvp-details.component.html',
    styleUrls: ['./mvp-details.component.scss']
})
export class MvpDetailsComponent extends BasePopover implements OnInit, OnChanges, AfterViewInit {

    config: MvpConfig = null;
    detailsUrl: string = null;

    infoVisible = false;
    contentHeight = 0;

    @ViewChild('frame', {static: true}) iFrame: ElementRef<HTMLIFrameElement>;
    @ViewChild('detailContainer', {static: true}) detailContainer: ElementRef<HTMLDivElement>;
    @ViewChild('headContainer', {static: true}) headContainer: ElementRef<HTMLDivElement>;
    @ViewChild('titleContainer', {static: true}) titleContainer: ElementRef<HTMLHeadingElement>;

    constructor(private renderer: Renderer2,
                protected popoverRef: PopoverRef,
                private auth: ApiService) {
        super(popoverRef);
        this.config = this.popoverRef.data.config;
    }

    ngOnInit() {
        this.initialize();
    }

    ngAfterViewInit() {
        this.updateDetailContentContainerSize();
        this.renderer.listen(window, 'resize', (event) => {
            this.updateDetailContentContainerSize();
        });
        // this.renderer.listen(this.iFrame.nativeElement, 'error', (error) => {
        //     console.log('error in iframe', error);
        // });
        if (this.config) {
            try {
                this.renderer.setStyle(
                    this.headContainer.nativeElement, 'background-color', this.config.colors.primary
                );
                this.renderer.setStyle(
                    this.titleContainer.nativeElement, 'color', this.config.colors.text
                );
            } catch (e) {
                console.log('Error: Setting the header color is not possible since either' +
                    ' primary or text color not defined!');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initialize();
    }

    onIFrameLoaded(): void {
        const frame = this.iFrame.nativeElement;
        frame.contentWindow.postMessage({access_token: this.auth.getToken()}, '*');
    }

    private initialize(): void {
        if (this.config.id) {
            const url = `${this.config.base_url}/${this.config.id}/detail/`;
            of({url}).subscribe(
                (res) => {
                    this.onUrlAvailable(res.url);
                }
            );
        }
    }

    private onUrlAvailable(url: string): void {
        const f = this.iFrame.nativeElement as HTMLIFrameElement;
        this.detailsUrl = url;
        f.src = url;
    }

    private updateDetailContentContainerSize(): void {
        this.contentHeight = window.innerHeight * 0.65;
        this.renderer.setStyle(this.detailContainer.nativeElement,
            'height',
            `${this.contentHeight}px`);
    }
}
