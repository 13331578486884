<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">
            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.consumptionAlert.title' | translate }}</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Verbrauchs-Alarm"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">{{ 'screens.dashboard.consumptionAlert.info.title' | translate }}</h2>
                    <p>
                        {{ 'screens.dashboard.consumptionAlert.info.textIona' | translate }}
                    </p>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="!showEmptyState; else emptyState">
                    <app-consumption-alert-chart
                        [isDetail]="true"
                        (chartLoaded)="onChartLoaded($event)">
                    </app-consumption-alert-chart>

                    <div class="diagram-controls">
                        <div class="left">
                            <button class="iona-icon-only-button prev"
                                    [disabled]="currentDataOffset === maxDataOffset"
                                    (click)="stepBack()"></button>
                        </div>
                        <div class="right">
                            <button class="iona-icon-only-button next"
                                    [disabled]="currentDataOffset === 0"
                                    (click)="stepForward()"></button>
                            <div class="m-r-m"></div>
                            <button class="iona-button small outline"
                                    [disabled]="currentDataOffset === 0"
                                    (click)="resetHistory()">
                                {{ 'common.now' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #emptyState>
                    <div class="animation-wrapper">
                        <div class="animation-container"
                             lottie [options]="lottieConfig">
                        </div>
                    </div>
                </ng-template>
            </section>

        </div>
    </div>
</div>

