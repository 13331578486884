<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n"> {{editMode ? 'Tarif bearbeiten' : 'Neuer Tarif'}}</h2>
            </div>
            <button class="iona-icon-only-button close" (click)="close(null)"></button>
        </div>

        <div class="content">
            <div class="tariff-creation">
                <form [formGroup]="form" (ngSubmit)="submit('edit')">
                    <div>
                        <input type="text" class="iona-input" placeholder="Tarifname"
                               formControlName="name" name="name">
                    </div>


                    <div class="date-input center-contents">
                        <input id="dayInput" type="date"
                               placeholder="Tarifbeginn" name="start"
                               formControlName="dateStart"
                               [min]="minDate" (change)="onDateChange($event.target.value)"
                               onkeydown="return false" onmousedown="return false">
                        <label [ngClass]="{placeholder: !displayDate}"
                               for="dayInput">{{displayDate ? moment(data.dateStart, dateFormat).format(dateFormatDisplay) : 'Tarifbeginn'}}</label>
                    </div>

                    <div>
                        <input type="number" class="iona-input" placeholder="Grundpreis in €"
                               formControlName="basePrice"
                               name="baseprice">
                    </div>

                    <div>
                        <input type="number" class="iona-input" placeholder="Arbeitspreis in ct"
                               formControlName="workPrice"
                               name="workprice">
                    </div>
                    <div>
                        <button type="submit" class="iona-button orange" [disabled]="form.invalid">
                            Speichern
                        </button>
                    </div>
                    <ng-container>
                        <div class="m-t-m">
                            <button *ngIf="editMode" type="submit" class="iona-button orange"
                                    (click)="submit('delete')">
                                Löschen
                            </button>
                        </div>
                    </ng-container>
                </form>
            </div>
        </div>

    </div>
</div>
