<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>{{ 'popovers.mfaLoginPopover.twoFactorTitle' | translate }}</h1>
        <p>{{ 'popovers.mfaLoginPopover.twoFactorDescription' | translate }}</p>
        <form [formGroup]="form" (ngSubmit)="submitCode()">
            <div>
                <input #inputField id="code-input" class="iona-input" type="tel"
                       pattern="^[0-9]*$"
                       (keydown)="onKeyDown($event)"
                       (keyup)="onKeyUp($event)"
                       placeholder="{{ 'popovers.mfaLoginPopover.codePlaceholder' | translate }}"
                       formControlName="code">
                <label for="code-input"></label>
                <div class="loading-indicator"
                     [class.hidden]="!isLoading">
                    <ng-lottie class="loading-spinner" height="45px"
                               [options]="loadingAnimation"></ng-lottie>
                </div>
            </div>
            <button class="iona-button orange" type="submit"
            [disabled]="submitDisabled">{{ 'popovers.mfaLoginPopover.submitButtonText' | translate }}</button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
