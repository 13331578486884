import {Pipe, PipeTransform} from '@angular/core';
import {padNumber} from '../../lib/Utility';


@Pipe({
    name: 'paddedMeterValue'
})
export class PaddedMeterValuePipe implements PipeTransform {

    transform(value: number | undefined, ...args: unknown[]): unknown {
        if (value === undefined) {
            return '––––––';
        }
        return padNumber(Math.round(value / 1000), 6);
    }

}
