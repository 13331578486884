import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

    submitDisabled = true;
    oldPassword = '';
    firstNewPassword = '';
    secondNewPassword = '';

    form = new UntypedFormGroup({
        oldPassword: new UntypedFormControl('', [Validators.required]),
        newPassword1: new UntypedFormControl('', [Validators.required]),
        newPassword2: new UntypedFormControl('', [Validators.required]),
    });

    passwordRegex = /^(?=.*?[A-Z])(?!.*[ßüöäÜÖÄ])(?=.*?[0-9]).{8,}$/;
    currentPasswordScore = 0;

    private zxcvbnLocalOptions = {
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
        },
    };


    constructor(public popoverRef: PopoverRef,
                private gtm: GTMWrapperService,
                private translate: TranslateService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }


    ngOnInit(): void {
        this.initialize();
    }


    ngAfterViewInit(): void {
        this.trackViewAppearedEvent();
    }


    /**
     * Closes the popover
     * @param value
     */
    close(value = false): void {
        this.popoverRef.close(value);
    }


    /**
     * Form Submit event
     */
    submitChange(): void {
        this.popoverRef.close(
            {
                success: true,
                values: {
                    oldPassword: this.oldPassword,
                    firstNewPassword: this.firstNewPassword,
                    secondNewPassword: this.secondNewPassword,
                }
            }
        );
    }


    /**
     * Returns the text for the password score rating
     */
    determinePasswordScoreRatingText(): string {
        if (this.currentPasswordScore <= 2) {
            return this.translate.instant('common.unsecure');
        } else if (this.currentPasswordScore === 3) {
            return this.translate.instant('common.secure');
        }
        return this.translate.instant('common.verySafe');
    }


    /**
     * Dumb-Validates the input
     * @private
     */
    private validateInput(): void {
        if (this.oldPassword === '' || this.firstNewPassword === '' || this.secondNewPassword === '') {
            this.submitDisabled = true;
            return;
        }
        if (this.firstNewPassword === this.secondNewPassword) {
            this.submitDisabled = !this.passwordRegex.test(this.firstNewPassword);
            return;
        }
        this.submitDisabled = true;
    }


    /**
     * Initializes the popover data if passed
     * @private
     */
    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }


    /**
     * Initializes the component
     * @private
     */
    private initialize(): void {
        zxcvbnOptions.setOptions(this.zxcvbnLocalOptions);
        this.form.valueChanges.subscribe((valueChange) => {
            this.oldPassword = valueChange.oldPassword;
            this.firstNewPassword = valueChange.newPassword1;
            this.currentPasswordScore = zxcvbn(this.firstNewPassword).score;
            this.secondNewPassword = valueChange.newPassword2;
            this.validateInput();
        });
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewAppearedEvent(): void {
        this.gtm.trackEvent({
            event: 'start',
            eventCategory: 'interaction',
            eventAction: 'start',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit password'
        });
    }


}
