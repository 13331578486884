import {ViewState} from './view-state.enum';
import {
    ApplianceDiagramSeriesData
} from '../../tiles/appliances/appliances-tile/appliances-tile.component';


export interface AppliancesTileData {
    series: ApplianceDiagramSeriesDataWrapper;
    nilmDataAvailable: boolean;
    nilmProfileComplete: boolean;
    nilmRetrainingInProgress: boolean;
    currentMonth: string;
    viewState: ViewState;
}


export const initialAppliancesTileData: AppliancesTileData = {
    series: {
        series: [],
        nilm: []
    },
    nilmDataAvailable: false,
    nilmProfileComplete: false,
    nilmRetrainingInProgress: false,
    currentMonth: '',
    viewState: ViewState.INITIAL
};


export interface ApplianceDiagramSeriesDataWrapper {
    series: ApplianceDiagramSeriesData[];
    nilm: boolean[];
}
