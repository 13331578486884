<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>{{ 'screens.profile.twoFactorAuthTitle' | translate }}</h1>
        <p>
            {{ 'screens.profile.twoFactorAuthDescription1' | translate }}
            <strong>{{ 'common.code' | translate }}</strong>:
        </p>
        <p class="secret-code" (click)="copyCode()">
            {{setupCode}}
            <i></i>
        </p>
        <div>
            <button class="iona-button orange" (click)="close(true)">{{ 'common.continue' | translate }}</button>
        </div>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close()"></button>
    </div>
</div>
