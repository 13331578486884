<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">{{'screens.dashboard.tiles.appliances.title' | translate}}</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <!-- INFO TEXT -->
            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <ng-container [ngSwitch]="currentDisplayMode">
                        <ng-container *ngSwitchCase="DisplayMode.DATA">
                            <h2 class="font-bold">{{TEXTS.info.title}}</h2>
                            <p *ngFor="let paragraph of TEXTS.info.ionaParagraphs">
                                {{paragraph}}
                            </p>
                        </ng-container>
                        <ng-container *ngSwitchCase="DisplayMode.RETRAINING">
                            <h2 class="font-bold">{{TEXTS.infoAlt.title}}</h2>
                            <p *ngFor="let paragraph of TEXTS.infoAlt.paragraphs">
                                {{paragraph}}
                            </p>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <!-- BANNER -->
            <ng-container *ngIf="detailBanner$ | async as detailBannerVisibility">
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showProfileUpdateBanner && profileUpdateBannerVisible"
                    [bannerData]="profileUpdateBanner"
                    (hideBanner)="hideProfileUpdateBanner()"
                    (bannerInteraction)="onProfileUpdateBannerInteraction()">
                </app-detail-banner>
                <app-detail-banner
                    *ngIf="detailBannerVisibility.showRetrainingBanner"
                    [bannerData]="retrainingBanner"
                    (sideButtonInteraction)="showRetrainingView()">
                </app-detail-banner>
            </ng-container>


            <!-- TAB BAR -->
            <div class="tab-bar appliances">
                <div [class.active]="currentMode === DataMode.MONTH && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.MONTH)">
                    {{'screens.dashboard.appliances.appliancesDetailLabels.tabTitleOverviewMonths' | translate}}
                </div>
                <div [class.active]="currentMode === DataMode.YEAR && currentDisplayMode === DisplayMode.DATA"
                     (click)="setMode(DataMode.YEAR)">
                    {{'screens.dashboard.appliances.appliancesDetailLabels.tabTitleOverviewYears' | translate}}
                </div>
                <div class="proxy-tab"></div>
                <ng-container *ngIf="currentNilmPhaseAllowsRetraining$ | async as retrainingAllowed">
                    <ng-container *ngIf="retrainingAllowed">
                        <div [class.active]="currentDisplayMode === DisplayMode.RETRAINING"
                             (click)="setDisplayMode(DisplayMode.RETRAINING)">
                            {{'screens.dashboard.appliances.appliancesDetailLabels.tabTitleRetraining' | translate}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <section class="detail-content">
                <ng-template
                    [cdkPortalOutlet]="selectedPortal"
                    (attached)="onComponentAttachedToPortal($event)">
                </ng-template>
            </section>

        </div>
    </div>
</div>
