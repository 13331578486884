import {Observable, of, retryWhen, Subject, takeUntil, throwError, timer} from 'rxjs';
import {
    PopoverConfigService
} from '../../popovers/static.popover.config';
import {ToastrService} from 'ngx-toastr';
import {HeartbeatService} from '../../services/heartbeat.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, concatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {InitializationService} from '../../services/initialization.service';
import {AnalyticsService} from '../../services/analytics.service';
import {Globals} from '../../services/globals.service';
import {UserService} from '../../services/user.service';
import {Tariff} from '../../classes/user';
import {Title} from '@angular/platform-browser';
import {RegistrationService} from '../../services/registration.service';
import {MeterStatuses} from '../../shared/enums/meter-statuses.enum';
import {ApiService} from '../../services/api.service';
import {AnimationItem} from 'lottie-web';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {Popover} from '../../popovers/popover/popover.service';
import * as moment from 'moment';
import {onboardingSteps} from '../../shared/constants/onboarding.constants';
import {constants} from '../../shared/constants/constants';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {InfoViewComponent} from '../../popovers/info-view/info-view.component';
import {TextMaskConfig} from 'angular2-text-mask';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {OptInService} from '../../services/opt-in.service';
import {ErrorDialogComponent} from '../../components/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SupportModalComponent} from '../../components/support-modal/support-modal.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'iona-app',
    templateUrl: './register.component.html',
    styleUrls: [
        'register.component.scss',
        'register-base.component.scss',
        'register-connection.component.scss',
        'register-sections.component.scss'
    ],
    viewProviders: [ApiService],
    providers: [Globals]
})
export class RegisterComponent implements OnInit, OnDestroy {

    private readonly debugLog = true;

    private readonly plugMacValidationRegex = /^[0-9A-Fa-f]{12}$/g;
    private readonly opticalReaderMacValidationRegex = /^([0-9A-Fa-f]{2}[:-]?){7}([0-9A-Fa-f]{2})$/g;
    private readonly registrationOnboardRetryDuration = 300000;
    private readonly registrationOnboardRetryInterval = 5000;
    private readonly firmwareUpdateSearchDuration = 300000;
    private readonly firmwareUpdateSearchInterval = 5000;
    private readonly firmwareUpdateMaxDuration: 7200000;

    private preventPinErrorPopover = false;
    private preventPinInfoPopover = false;
    private popoverOpen = false;

    private registrationTries = 0;
    private registrationSub = null;
    private lastRegistrationCall = new Date().getTime();
    private meterPin = null;

    private passwordRegex = /^(?=.*?[A-Z])(?!.*[ßüöäÜÖÄ])(?=.*?[0-9]).{8,}$/;
    private zxcvbnLocalOptions = {
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
        },
    };

    readonly onboardingSteps = onboardingSteps;

    readonly moment = moment;
    readonly macAddressMask: TextMaskConfig = {
        mask: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };
    readonly macPlaceholder = ' ____ : ____ : ____ ';
    readonly opticalReaderMacAddressMask: TextMaskConfig = {
        mask: [
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, ':',
            /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/
        ]
    };
    readonly opticalReaderMacPlaceholder = ' ____ : ____ : ____ : ____ ';

    currentStep = this.onboardingSteps.accountCreation;
    // currentStep = this.onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission;

    registrationOnboardSuccessful = false;
    firmwareUpdateStatus: 'loading' | 'uptodate' | 'error' = 'loading';
    commissionStatus: 'initial' | 'loading' | 'success' | 'error' = 'initial';
    firmwareIsUpdating = false;
    firmwareUpdateFinishedOrFailed = false;
    lastMeterStatus = null;
    checkMeterStatusInterval: any = [];

    tries = 0;  // number of failed tries to unlock the meter
    meterStatus = 0;    // current meter status

    device = 'box';
    deviceTitle: string;


    statusTries: any = {
        step0: 0,
        step1: 0,
        step2: 0,
    };
    statusError: any = {
        title: '',
        text: ''
    };
    connect: any = {
        type: null,
        tries: 0
    };
    user: any = {   // Nutzerdaten
        voucher: null,
        email: null,
        password: null,
        mac: null
    };

    input: any = { // Inhalt Input-Feld der MAC
        mac: ''
    };
    tariffInfo: Tariff = {
        name: '',
        basePrice: null,
        workPrice: null,
        dateStart: null,
        dateEnd: null,
    };
    // animation
    saveTariffDisabled = true;
    // currentStep = this.onboardingSteps.passwordEntry;
    // currentStep = this.onboardingSteps.analyticsOptIn;
    lottieConfig2 = {
        path: 'assets/anim/onboarding_meter_animation_new.json',
        autoplay: false,
        name: 'Smart Meter Animation',
        loop: true,
        renderer: 'canvas'
    };
    loadingLottieConfig = {
        path: 'assets/anim/onboarding-loading.json',
        autoplay: true,
        name: 'Loading Animation',
        loop: true,
        renderer: 'svg'
    };

    animationReady = false;
    anim: AnimationItem;
    isERNAUser = false;
    isEnviamUser = false;
    readerPIN = null;
    firstMac = null;
    secondMac = null;
    plugMac = null;
    opticalReaderMac = null;
    navigationFromDashboard = false;
    passwordForm = new UntypedFormGroup({
        password1: new UntypedFormControl('', [Validators.required]),
        password2: new UntypedFormControl('', [Validators.required])
    });
    setPasswordDisabled = true;
    currentPasswordScore = 0;
    voucherForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        voucher: new FormControl('', [Validators.required])
    });
    deviceMacForm = new FormGroup({
        mac: new FormControl('', [Validators.required])
    });
    readerMacForm = new FormGroup({
        mac: new FormControl('', [
            Validators.required,
            Validators.minLength(16)
        ])
    });


    constructor(
        public userService: UserService,
        public apiService: ApiService,
        private titleService: Title,
        private router: Router,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private globals: Globals,
        private application: ApplicationService,
        private registration: RegistrationService,
        private initialization: InitializationService,
        private analytics: AnalyticsService,
        private auth: AuthService,
        private popover: Popover,
        private heartbeat: HeartbeatService,
        private meter: MeterService,
        private opticalReader: OpticalReaderService,
        private localOptInService: LocalOptInService,
        private optInService: OptInService,
        private updateService: FirmwareUpdateService,
        public dialog: MatDialog,
        private popoverConfigService: PopoverConfigService,
        private translate: TranslateService
    ) {
    }


    ngOnInit() {
        this.translate.get('screens.register.pageTitle.registerIona').subscribe((translatedTitle: string) => {
            this.titleService.setTitle(translatedTitle);
        });

        localStorage.setItem('userOnboardingInProgress', 'in_progress');
        // get previously stored device
        const stored_device = this.userService.getUserDevice();
        if (this.debugLog) {
            console.log('OnInit: got stored device', stored_device);
        }
        if (stored_device !== null && stored_device !== undefined) {
            if (stored_device === constants.application.devices.box) {
                this.device = 'box';
                this.deviceTitle = 'iONA-Box';
            } else if (stored_device === constants.application.devices.plug) {
                this.device = 'plug';
                this.deviceTitle = 'PowerChecker';
            } else if (stored_device === constants.application.devices.plug_optical) {
                this.device = 'plug';
                this.deviceTitle = 'PowerChecker';
                this.isERNAUser = true;
            } else if (stored_device === constants.application.devices.smart_box ||
                stored_device === constants.application.devices.smart_box2) {
                this.device = 'plug';
                this.deviceTitle = 'iONA Box 2.0';
                this.isERNAUser = true;
            }
        }

        this.initializeRouteParamterHandling();
        zxcvbnOptions.setOptions(this.zxcvbnLocalOptions);
        this.setupPasswordFormHandling();
    }


    ngOnDestroy() {
        clearInterval(this.checkMeterStatusInterval);
    }

    resetMacAddressEntry(): void {
        this.deviceMacForm.setValue({mac: ''});
        this.plugMacValidationRegex.lastIndex = 0;
        this.readerMacForm.setValue({mac: ''});
        this.opticalReaderMacValidationRegex.lastIndex = 0;
        this.setStep(onboardingSteps.devices.plug.macEntry);
        this.commissionStatus = 'initial';
    }

    openSupportDialog(): void {
        const dialogRef = this.dialog.open(SupportModalComponent, {
            width: '400px',
            data: {
                text: this.translate.instant('screens.register.onBoarding.supportDialogTextIona'),
                showCloseButton: false,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The other modal was closed');
        });
    }

    openFAQ(): void {
        window.open('https://app-content.n2g-iona.net/help/iona/home?showNav=true', '_blank');
    }

    openErrorDialog(): void {
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '400px',
            data: {
                title: this.translate.instant('common.errors.connectionFailed'),
                message: this.translate.instant('common.errors.connectionFailed'),
                showButton1: true,
                button1Text: this.translate.instant('common.errors.retryButton'),
                showButton2: true,
                button2Text: this.translate.instant('common.supportButton'),
                showButton3: true,
                button3Text: this.translate.instant('common.faqButton'),
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'button1') {
                this.resetMacAddressEntry();
            } else if (result === 'button2') {
                this.openSupportDialog();
            } else if (result === 'button3') {
                this.openFAQ();
            }
        });
    }

    getDeviceTitle() {
        return this.deviceTitle === 'iONA Box 2.0' ? 'iONA Box 2.0' : 'PowerChecker';
    }

    isSmartBox2() {
        return this.deviceTitle === 'iONA Box 2.0';
    }

    hardwareNotReady(): void {
        this.apiService.logoutUser();
        this.router.navigate(['login']);
    }


    determinePasswordScoreRatingText(): string {
        if (this.currentPasswordScore <= 2) {
            return this.translate.instant('screens.register.passwordRatings.unsafe');
        } else if (this.currentPasswordScore === 3) {
            return this.translate.instant('screens.register.passwordRatings.secure');
        }
        return this.translate.instant('screens.register.passwordRatings.verySecure');
    }


    validateVoucher() {
        const {email, voucher} = this.voucherForm.value;
        if (voucher.toLowerCase().startsWith('igy')) {
            this.popover.open({
                content: InfoViewComponent,
                hasBackdrop: true,
                position: 'absolute',
                placement: 'center center',
                data: {showContinue: false}
            }).afterClosed$.subscribe({
                next: () => null
            });
            return;
        }

        this.registration.validateVoucher(
            voucher.toUpperCase(),
            email
        ).subscribe({
                next: () => {
                    this.user.voucher = voucher.toUpperCase();
                    this.user.email = email;
                    this.setStep(this.onboardingSteps.passwordEntry);
                    this.trackAnalyticsVoucherEntered(true);
                },
                error: (error) => {
                    const code = error.error.code;
                    if (code === 106) {
                        this.toast.error(this.translate.instant('ionaSpecific.onBoarding.errors.voucherUnknown'));
                    } else {
                        this.toast.error(this.translate.instant('ionaSpecific.onBoarding.errors.voucherValidationFailed'));
                    }
                    this.trackAnalyticsVoucherEntered(false);
                }
            }
        );
    }


    /**
     * Set First MAC Adress of the box
     * @param value
     */
    setFirstMACAddress(value: string): void {
        this.firstMac = value.replace(/:/g, '');
        this.setStep(this.onboardingSteps.devices.box.macEntry2);
    }


    /**
     * Sets the MAC Address of the Plug
     */
    setPlugMacAddress(): void {
        const plainMac = this.deviceMacForm.value.mac.replace(/:/g, '');
        if (this.plugMacValidationRegex.test(plainMac)) {
            this.plugMac = plainMac;
            this.setStep(this.onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress);
            this.initializeContinuousRegistrationCheck();
            return;
        }
        this.toast.error(this.translate.instant('common.errors.invalidMACAddressFormat'));
    }


    /**
     * Sets the MAC Adress of the optical reader
     */
    setOpticalReaderMacAddress(): void {
        const plainMac = this.readerMacForm.value.mac.replace(/:/g, '');
        if (this.opticalReaderMacValidationRegex.test(plainMac)) {
            this.opticalReaderMac = plainMac;
            this.setStep(this.onboardingSteps.devices.plug.orSetup.positioningOR);
            return;
        }
        this.toast.error(this.translate.instant('common.errors.invalidMACAddressFormat'));
    }


    /**
     * Starts the manual commissioning process where the connection between the plug and the
     * optical reader is established.
     */
    startCommission(): void {
        const maxRetries = 30;

        this.opticalReader.startCommission(this.opticalReaderMac)
            .pipe(
                retryWhen(errors => errors.pipe(
                    concatMap((error, attempt) => {
                        if (attempt < maxRetries) {
                            console.log(`Retrying commissioning attempt ${attempt + 1}`);
                            this.commissionStatus = 'loading';

                            return timer(10000);
                        }

                        return throwError(new Error('Maximum retries exceeded'));
                    })
                ))
            )
            .subscribe({
                next: () => {
                    this.commissionStatus = 'success';
                },
                error: () => {
                    this.commissionStatus = 'error';
                    this.openErrorDialog();
                    this.toast.error(this.translate.instant('common.errors.errorStartingOpticalReaderCommissioning'));
                }
            });
    }

    startFirmwareUpdate(): void {
        this.startContinuousFirmwareUpdateSearch();
        this.setStep(onboardingSteps.devices.plug.orSetup.infoProcessDuration);
    }

    /**
     * Register the user with password and voucher code
     */
    registerUser() {
        const values = this.passwordForm.value;
        if (values.password1 !== values.password2) {
            this.toast.error(this.translate.instant('common.errors.passwordsDoNotMatch'));
            return;
        }

        const user = {
            email_address: this.user.email,
            pincode: values.password1,
            voucher_code: this.user.voucher.toUpperCase()
        };


        this.registration.registerUser(user).pipe(
            mergeMap(response => {
                console.log('response', response);
                this.user.password = values.password1;
                return this.auth.login(this.user.email, this.user.password, true);
            }),
            mergeMap(loginResponse => {
                return this.registration.getModel();
            })
        ).subscribe({
            next: (result) => {
                this.userService.setActiveUserProvider(result.labelpartner);
                this.userService.updateUserDevice(result.model_identifier);
                this.isERNAUser = this.userService.isEDGUser();
                this.isEnviamUser = this.userService.getActiveUserProvider().toLowerCase() === 'enviam';
                switch (result.model_identifier) {
                    case constants.application.devices.plug:
                        this.selectDevice('plug');
                        break;
                    case constants.application.devices.plug_optical:
                        this.selectDevice('plug');
                        break;
                    case constants.application.devices.smart_box:
                        this.selectDevice('plug', false, true, constants.application.devices.smart_box);
                        break;
                    case constants.application.devices.smart_box2:
                        this.selectDevice('plug', false, true, constants.application.devices.smart_box2);
                        break;
                    default:
                        this.selectDevice('box');
                }
            },
            error: (error) => {
                console.log('Error: ', error);
                if ('error' in error) {
                    if ('error' in error.error) {
                        if (error.error.error.code === 264) {
                            const msg = error.error.error.message;
                            if (msg.includes('MAX length')) {
                                this.toast.error(
                                    this.translate.instant('common.errors.passwordTooLong'),
                                    this.translate.instant('common.errors.invalidPassword'),
                                    {timeOut: 6000}
                                );
                            } else if (msg.includes('Special characters')) {
                                this.toast.error(
                                    this.translate.instant('common.errors.invalidPasswordChars'),
                                    this.translate.instant('common.errors.invalidPassword'),
                                    {timeOut: 6000}
                                );
                            }
                        }
                        if (error.error.error.code === 102) {
                            const msg = error.error.error.message;
                            if (msg.includes('already active on')) {
                                this.toast.error(
                                    this.translate.instant('common.errors.accountAlreadyCreated'),
                                    this.translate.instant('common.errors.error'),
                                    {timeOut: 6000}
                                );
                            }

                        }
                    }
                }
                if (error._body) {
                    const errorParsed: any = JSON.parse(error._body);
                    switch (errorParsed.error.code) {
                        case 102: {
                            this.toast.info(this.translate.instant('common.info.alreadyRegistered'));
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);

                            break;
                        }
                        case 264:
                            console.log('CASE 264 ');
                            break;
                        default: {
                            this.toast.error(this.translate.instant('common.errors.registrationFailed'));
                        }
                    }
                } else {
                    this.toast.error(this.translate.instant('common.errors.registrationFailed'));
                }
            }
        });
    }


    /**
     * Meter registrieren
     * @param mac
     * @param throwError
     */
    registerDevice(mac: string, throwError: boolean = true) {
        if (!mac) {
            mac = this.secondMac;
        }
        mac = mac.replace(/:/g, '');
        if (mac.indexOf('_') < 0) {
            const macPart1 = mac.substring(0, 6);
            const macPart2 = mac.substring(6);
            const finalMac = macPart1 + '0000' + macPart2;

            this.apiService.registerDevice(finalMac).subscribe(
                (response: any) => {
                    this.registerDeviceSuccess(finalMac);
                    if (this.device === 'plug') {
                        if (this.isERNAUser && this.isEnviamUser) {
                            this.connectMeterWithPin();
                        }
                        this.setStep(this.onboardingSteps.devices.plug.connecting);
                    } else {
                        this.setStep(this.onboardingSteps.devices.box.connecting);
                    }
                },
                (error: any) => {
                    console.log('Error:', error);
                    if (error.error.error.code === 102) {
                        if (this.device === 'plug') {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        } else {
                            this.setStep(this.onboardingSteps.devices.box.connecting);
                        }
                        this.registerDeviceSuccess(finalMac);
                    } else if (error.error.error.code === 110) {
                        this.toast.error(this.translate.instant('common.errors.registrationFailed'));
                    } else if (error.error.error.code === 999) {
                        this.toast.error(this.translate.instant('common.errors.registrationFailed'));
                    } else if ('_body' in error) {
                        let jsonError;

                        try {
                            jsonError = JSON.parse(error._body);
                        } catch (e) {
                            if (throwError) {
                                this.registerDeviceError();
                            }
                        }
                    } else if (throwError) {
                        this.registerDeviceError();
                    }
                },
            );
        }
    }


    private enterMeterStatusUpdate() {
        this.startMeterStatusUpdate();

        this.analytics.trackEvent({
            action: 'onboarding_finish',
            properties: {
                category: 'Onboarding',
                label: 'mode: ' + this.connect.type + '; gateway_id: ' + this.user.mac
            }
        });

        this.apiService.optInDevice().subscribe(
            (response: any) => {
                // console.log('opt in succeeded', response);
            },
            (e) => {
                // console.log('opt in failed with:', e.error.error.code, e.error.error.message);
            }
        );

        this.setStep(this.onboardingSteps.devices.plug.connecting);
    }


    private registerDeviceSuccess(newMac: any) {
        this.startMeterStatusUpdate();

        this.analytics.trackEvent({
            action: 'onboarding_finish',
            properties: {
                category: 'Onboarding',
                label: 'mode: ' + this.connect.type + '; gateway_id: ' + this.user.mac
            }
        });

        this.apiService.optInDevice().subscribe(
            (response: any) => {
                // console.log('opt in succeeded', response);
            },
            (e) => {
                // console.log('opt in failed with:', e.error.error.code, e.error.error.message);
            }
        );
    }


    private registerDeviceError() {
        this.connect.tries++;

        switch (this.connect.type) {
            case 'LAN': {
                // Local Storage MAC entfernen
                this.input.mac = '';

                this.setStep(this.onboardingSteps.devices.box.notFoundError);

                break;
            }
            case 'AP': {
                if (this.connect.tries >= 3) {
                    this.setStep('Ü');
                } else {
                    this.setStep(this.onboardingSteps.devices.box.connectionError);
                }

                break;
            }
        }
    }


    handleAnimation(anim: any): void {
        this.anim = anim;
        this.anim.stop();

        setTimeout(() => {
            this.playAnimationSegment(0);
            this.animationReady = true;
            this.startMeterStatusUpdate(this.navigationFromDashboard);
        }, 100);
    }


    private connectMeterWithPin(): void {
        if (this.readerPIN !== null) {
            this.meter.putOpticalReaderPin(this.readerPIN).subscribe({next: null});
        }
    }


    private playAnimationSegment(step: number): void {
        if (this.anim === null || this.anim === undefined) {
            return;
        }
        if (!this.animationReady) {
            return;
        }

        if (step === 0) {
            setTimeout(() => {
                this.anim.playSegments([0, 85], true);
            }, 150);
        } else if (step === 1) {
            setTimeout(() => {
                this.anim.playSegments([205, 318], true);
            }, 150);
        } else if (step === 2) {
            setTimeout(() => {
                this.anim.playSegments([350, 508], true);
            }, 150);
        } else if (step === 3) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
            // setTimeout(() => {
            //     this.anim.playSegments([655, 655], true);
            // }, 150);
        } else if (step === 4) {
            setTimeout(() => {
                this.anim.playSegments([657, 959], true);
            }, 150);
        }
    }


    private startMeterStatusUpdate(alreadyStarted = false) {
        if (this.debugLog) {
            console.log('meta call');
        }
        if (this.isERNAUser) {
            this.playAnimationSegment(0);
            if (this.isEnviamUser) {
                const sub = timer(0, 5000).pipe(
                    mergeMap((t) => {
                        if (!alreadyStarted && this.readerPIN !== null) {
                            return this.meter.putOpticalReaderPin(this.readerPIN).pipe(
                                map((res) => true),
                                catchError(() => of(false))
                            );
                        }
                        return of(true);
                    }),
                    map((res) => {
                        if (res) {
                            return true;
                        }
                    })
                ).subscribe((res) => {
                    if (res) {
                        sub.unsubscribe();
                        this.ernaEnviamMeterStatusCall();
                        this.checkMeterStatusInterval = setInterval(() => {
                            this.ernaEnviamMeterStatusCall();
                        }, 5000);
                    }
                });
            } else {
                this.ernaMeterStatusCall();
                this.checkMeterStatusInterval = setInterval(() => {
                    this.ernaMeterStatusCall();
                }, 5000);
            }
        } else {
            this.meterStatusCall();
            this.checkMeterStatusInterval = setInterval(() => {
                this.meterStatusCall();
            }, 5000);
        }
    }


    private meterStatusCall() {
        if (this.debugLog) {
            console.log('meter status call box');
        }
        this.apiService.getDeviceStatus().subscribe(
            (response: any) => {
                if (!('data' in response)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                if (!('current_status' in response.data)) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (response.data.current_status.toUpperCase()) {
                    case 'READY_FOR_METER_INCLUSION':
                        if (this.meterStatus != 1) {
                            this.playAnimationSegment(1);
                            this.meterStatus = 1;
                            this.globals.resetIsMeterConnected();
                        }

                        break;
                    case 'COMMISSION_IN_PROGRESS':
                        if (this.meterStatus != 2) {
                            this.playAnimationSegment(2);
                            this.meterStatus = 2;
                            this.globals.resetIsMeterConnected();
                        }

                        break;
                    case 'CONNECTED_WITH_METER':
                        if (this.meterStatus != 3) {
                            this.playAnimationSegment(3);
                            this.meterStatus = 3;
                            this.globals.setIsMeterConnected();
                            clearInterval(this.checkMeterStatusInterval);
                        }

                        break;
                    default:
                        this.playAnimationSegment(0);
                }
            },
            (error) => {
                console.log('Error: ', error);
                this.increaseMeterStatusErrorTries();
            });
    }


    /**
     * Versuche der Meter-Registrierung. Ab bestimmter Anzahl an Versuchen wird ein Error-Text angezeigt
     */
    private increaseMeterStatusErrorTries() {
        // Versuche erhöhen
        switch (this.meterStatus) {
            case 0:
                this.statusTries.step0++;
                break;
            case 1:
                this.statusTries.step1++;
                break;
            case 2:
                this.statusTries.step2++;
                break;
        }

        // Prüfen ob Maximum erreicht
        switch (this.meterStatus) {
            case 0: // Step0 5 Minuten -> 60 Calls
                if (this.statusTries.step0 >= 60) {
                    this.statusError.title = this.translate.instant('common.errors.noRegistrationPossibleTitle');
                    this.statusError.text = this.translate.instant('common.errors.noRegistrationPossibleText');
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 1: // Step1 2 Minuten -> 24 Calls
                // if (this.statusTries.step1 >= 60) {
                if (this.statusTries.step1 >= 24) {
                    this.statusError.title = this.translate.instant('common.errors.smartMeterNotFound');
                    this.statusError.text = this.statusError.title = this.translate.instant('common.errors.smartMeterSearchFailure');
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
            case 2: // Step2 10 Minuten -> 120 Calls
                if (this.statusTries.step2 >= 120) {
                    this.statusError.title = this.translate.instant('common.errors.noSmartMeterConnection');
                    this.statusError.text = this.translate.instant('common.errors.failedToConnectToSmartMeter');
                    this.statusTries = {step0: 0, step1: 0, step2: 0, };
                    this.setStep('S');
                    clearInterval(this.checkMeterStatusInterval);
                }
                break;
        }
    }


    retryMeterStatus() {
        this.statusTries = {step0: 0, step1: 0, step2: 0, };
        this.startMeterStatusUpdate();
        if (this.device === 'plug') {
            this.setStep(this.onboardingSteps.devices.plug.connecting);
        } else {
            this.setStep(this.onboardingSteps.devices.box.connecting);
        }
    }

    setStep(step: string) {
        console.log('setting current step to: ', step);
        this.currentStep = step;

        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.powerConnect:
            case this.onboardingSteps.devices.box.lanConnect: {
                this.connect = {
                    type: 'LAN',
                    tries: 0
                };
                break;
            }
            case this.onboardingSteps.devices.box.macEntry: {
                if (this.input.mac !== null && this.input.mac.length > 0) {
                    this.registerDevice(this.input.mac, false);
                }

                break;
            }
            case 'W':
            case 'X': {
                this.connect = {
                    type: 'AP',
                    tries: 0
                };

                break;
            }
            case this.onboardingSteps.devices.plug.connecting:
            case this.onboardingSteps.devices.box.connecting:
                if (this.user.email !== null && this.user.email !== undefined) {
                    this.apiService.loginUser(this.user.email, this.user.password);
                }
                break;
            case this.onboardingSteps.accountSetup:
                // this.heartbeat.startLiveUpdate();
                break;
            case this.onboardingSteps.opticalReader.deviceSelection:
                this.startInitialRegistration();
                break;
        }

        // analytics stuff
        switch (this.currentStep) {
            case this.onboardingSteps.devices.box.lanConnect:
            case 'X': {
                this.analytics.trackEvent({
                    action: 'onboarding_start',
                    properties: {
                        category: 'Onboarding',
                        label: 'mode: ' + this.connect.type
                    }
                });

                break;
            }
            case 'I': {
                if (this.connect.type !== null) {
                    this.analytics.trackEvent({
                        action: 'onboarding_cancel',
                        properties: {
                            category: 'Onboarding',
                            label: 'mode: ' + this.connect.type
                        }
                    });
                }

                break;
            }
        }
    }


    /**
     * On Device selected
     * @param device
     */
    selectDevice(device, skipStep = false, smart_reader = false, model = ''): void {
        this.device = device;
        let deviceToSet = '';
        switch (device) {
            case 'box':
                this.deviceTitle = 'iONA-Box';
                deviceToSet = constants.application.devices.box;
                break;
            case 'plug':
                if (smart_reader) {
                    this.deviceTitle = 'iONA Box 2.0';
                    deviceToSet = model;
                } else {
                    this.deviceTitle = 'PowerChecker';
                    deviceToSet = constants.application.devices.plug;
                    if (this.isERNAUser) {
                        if (this.isEnviamUser) {
                            deviceToSet = constants.application.devices.plug_optical;
                        }
                    }
                }
                break;
            default:
                this.deviceTitle = 'iONA-Box';
                break;
        }
        this.userService.updateUserDevice(deviceToSet);
        // this.setStep(this.onboardingSteps.analyticsOptIn);
        if (!skipStep) {
            this.setStep(this.onboardingSteps.hardwarePresent);
        }
    }


    checkOptInResponse(
        trackingEnabled: boolean,
        optInEnabled: boolean,
        dataOptInEnabled
    ): void {
        this.analytics.changeTrackingState(trackingEnabled);
        if (!optInEnabled) {
            this.localOptInService.triggerOptInPopover(true)
                .subscribe(finalResult => {
                    if (finalResult) {
                        // this.continueOnboardingAfterPermissionCheck();
                        this.sendDataAnalyticsOptin(dataOptInEnabled);
                    } else {
                        this.apiService.logoutUser();
                    }
                });
        } else {
            this.sendDataAnalyticsOptin(dataOptInEnabled);
            // this.continueOnboardingAfterPermissionCheck();
        }
    }


    onProviderFormChange(evt): void {
        for (const key of Object.keys(this.tariffInfo)) {
            const el = this.tariffInfo[key];
            if (key !== 'dateEnd') {
                this.saveTariffDisabled = (el === null || el === undefined) || el === '';
            }
        }
    }


    saveProviderInfo(): void {
        this.tariffInfo.workPrice = this.tariffInfo.workPrice / 100;
        if (this.isERNAUser) {
            this.userService.addTarrifInfo(this.tariffInfo);
        }
        this.router.navigate(['/']);
    }


    setPIN(pin: string): void {
        if (pin !== null) {
            this.readerPIN = parseInt(pin, 10);
        }
        this.connectMeterWithPin();
        this.enterMeterStatusUpdate();
    }

    skipTariffs(): void {
        if (this.checkMeterStatusInterval) {
            clearInterval(this.checkMeterStatusInterval);
        }
        this.router.navigate(['/']);
    }


    afterOrOnboardingComplete(): void {
        if (this.isERNAUser) {
            if (this.isEnviamUser) {
                this.checkPinExistence();
            } else {
                this.enterMeterStatusUpdate();
                this.connectMeterWithPin();
            }
        } else {
            this.registerDevice(this.secondMac, true);
        }
    }


    afterConnectionFinished(): void {
        if (this.navigationFromDashboard) {
            this.router.navigate(['/']);
            return;
        }

        if (this.isERNAUser) {
            if (this.isEnviamUser) {
                this.setStep(onboardingSteps.accountSetup);
            } else {
                this.setStep(onboardingSteps.tariffEntry);
            }
        } else {
            this.setStep(onboardingSteps.accountSetup);
        }
    }


    private ernaEnviamMeterStatusCall() {
        if (this.debugLog) {
            console.log('erna enviam status call');
        }
        this.opticalReader.getOpticalReaderStatus(true).pipe(
            mergeMap((response: any) => this.mapMeterStatusResponse(response))
        ).subscribe(
            (data: any) => {
                if (!data) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                this.meterPin = data.smartreader.pincode;
                const pincodeThere = data.smartreader.pincode
                    ? data.smartreader.pincode !== '' || data.smartreader.pincode !== null
                    : false;
                const entryModeValid = data.smartreader.pin_entry_mode === 'optical' ||
                    data.smartreader.pin_entry_mode === 'unknown';
                const pinInputRequired = data.smartreader.pin_entry_mode === 'manual_push_button' ||
                    data.smartreader.pin_entry_mode === 'manual_torch';
                const meterUnlocked = data.smartreader.meter_unlocked;

                // pasted here from status CONNECTED_WITH_METER 01.02.2022
                const skipToPinVerification = false;
                if (this.userService.isEDGUser() && this.userService.isEnviamUser()) {
                    if (!meterUnlocked && pinInputRequired) {
                        if (!this.preventPinInfoPopover) {
                            this.showManualPinEntryOverlay();
                        }
                    }
                }

                switch (data.current_status) {
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        if (this.meterStatus !== 1) {
                            this.meterStatus = 1;
                            this.globals.resetIsMeterConnected();
                            this.playAnimationSegment(1);
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                    // tslint:disable-next-line:no-switch-case-fall-through
                    case MeterStatuses.COMMISSION_IN_PROGRESS:
                        if (this.meterStatus !== 2) {
                            if (data.smartreader.meter_unlocked) {
                                this.globals.setIsMeterConnected();

                                if (!skipToPinVerification) {
                                    clearInterval(this.checkMeterStatusInterval);
                                    this.setStep(this.onboardingSteps.accountSetup);
                                }
                            }
                            this.meterStatus = 2;
                            this.playAnimationSegment(2);
                            this.globals.resetIsMeterConnected();
                        }
                        break;
                    case MeterStatuses.VERIFYING_PIN:
                        if (this.meterStatus !== 3) {
                            this.meterStatus = 3;
                            this.globals.setIsMeterConnected();
                            this.playAnimationSegment(3);
                        }

                        break;
                    case MeterStatuses.PIN_FAILED:
                        this.handlePinFailedState();
                        break;
                    case MeterStatuses.CONTACT_NO_DATA_FROM_METER:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.CONTACT_WRONG_METER_SERIAL:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay('radioLinkLostInfo', 'hours', 24)) {
                            break;
                        }
                        if (this.popoverOpen) {
                            return;
                        }
                        const popoverConfig = this.popoverConfigService.getRadioLinkLostPopoverConfig();
                        this.popover.open(popoverConfig).afterClosed$.subscribe(
                            () => {
                            }
                        );
                        this.popoverOpen = true;
                        break;
                    default:
                        this.meterStatus = 0;
                        this.globals.resetIsMeterConnected();
                        this.playAnimationSegment(0);
                }
            },
            (error) => {
                console.log('Error:', error);
                this.increaseMeterStatusErrorTries();
            }
        );
        return;
    }


    private ernaMeterStatusCall() {
        if (this.debugLog) {
            console.log('erna status call');
        }
        this.opticalReader.getOpticalReaderStatus(true).pipe(
            mergeMap((response) => this.mapMeterStatusResponse(response))
        ).subscribe(
            (data: any) => {
                if (!data) {
                    this.increaseMeterStatusErrorTries();
                    return;
                }

                switch (data.current_status) {
                    // case 'READY_FOR_METER_INCLUSION':
                    case MeterStatuses.INITIAL:
                        if (this.animationReady) {
                            if (this.meterStatus !== 1) {
                                this.playAnimationSegment(1);
                                this.meterStatus = 1;
                                this.globals.resetIsMeterConnected();
                            }
                        }
                        break;
                    case MeterStatuses.CONTACT_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 2) {
                                this.playAnimationSegment(2);
                                this.meterStatus = 2;
                                this.globals.resetIsMeterConnected();
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (this.animationReady) {
                            if (this.meterStatus !== 3) {
                                this.playAnimationSegment(3);
                                this.meterStatus = 3;
                                this.globals.setIsMeterConnected();
                                clearInterval(this.checkMeterStatusInterval);
                            }
                        }
                        break;
                    case MeterStatuses.CONTACT_NO_DATA_FROM_METER:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.CONTACT_WRONG_METER_SERIAL:
                        this.openErrorPopover(data);
                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay('radioLinkLostInfo', 'hours', 24)) {
                            break;
                        }
                        if (this.popoverOpen) {
                            return;
                        }
                        const popoverConfig = this.popoverConfigService.getRadioLinkLostPopoverConfig();
                        this.popover.open(popoverConfig).afterClosed$.subscribe(
                            () => {
                            }
                        );
                        this.popoverOpen = true;
                        break;
                    default:
                        this.playAnimationSegment(0);
                }

                this.increaseMeterStatusErrorTries();

            },
            (error) => {
                console.log('Error: ', error);
                this.increaseMeterStatusErrorTries();
            });
    }


    private determineUserMode(data): void {
        this.device = data.model_identifier;
        switch (data.model_identifier) {
            case constants.application.devices.plug:
                this.deviceTitle = 'PowerChecker';
                break;
            case constants.application.devices.plug_optical:
                this.deviceTitle = 'PowerChecker';
                this.isERNAUser = true;
                if (this.userService.getActiveUserProvider() === 'enviam') {
                    this.isEnviamUser = true;
                }
                break;
            default:
                this.deviceTitle = 'iONA-Box';
        }
    }


    /**
     * Starts the continuous search for firmware updates
     * @private
     */
    private startContinuousFirmwareUpdateSearch(): void {
        const destroy$ = new Subject();
        let updateStarted = false;

        let currentCycle = 0;
        const totalCycles =
            this.firmwareUpdateSearchDuration / this.firmwareUpdateSearchInterval;
        const totalCyclesUpdating =
            this.firmwareUpdateMaxDuration / this.firmwareUpdateSearchInterval;
        timer(0, this.firmwareUpdateSearchInterval).pipe(
            takeUntil(destroy$),
            mergeMap(() => this.opticalReader.getOpticalReaderStatus()),
            mergeMap((orStatus) => {
                console.log('current or status', orStatus);
                const currentStatus = orStatus.status;
                const firmwareStatus = orStatus.firmware_status.toLowerCase();

                let action: 'done' | 'startUpdate' | 'updating' | 'wait';

                if (this.lastMeterStatus === MeterStatuses.UPDATE_INSTALLING
                    && currentStatus !== MeterStatuses.UPDATE_INSTALLING) {
                    action = 'done';
                    return of({action, orStatus});
                }

                if (firmwareStatus === 'ok'){
                    action = 'done';
                } else if (firmwareStatus === 'update_installing' ||
                    currentStatus === MeterStatuses.UPDATE_INSTALLING) {
                    action = 'updating';
                } else {
                    if (!firmwareStatus || firmwareStatus === '') {
                        action = 'wait';
                    } else if (firmwareStatus === 'update_available') {
                        action = 'startUpdate';
                    } else {
                        console.log('undefined state');
                        action = 'wait';
                    }
                }
                this.lastMeterStatus = currentStatus;
                return of({action, orStatus});
            }),
            switchMap(({action, orStatus}) => {
                console.log('current action', action);
                switch (action) {
                    case 'done':
                        this.updateService.onUpdateStateReceived.next(null);
                        return of(true);
                    case 'startUpdate':
                        return of(updateStarted).pipe(
                            mergeMap((started) => {
                                if (!started) {
                                    updateStarted = true;
                                    this.firmwareUpdateStatus = 'loading';
                                    return this.updateService.startUpdate()
                                        .pipe(map(() => false));
                                }
                                return of(false);
                            })
                        );
                    case 'updating':
                        if ('update_progress' in orStatus) {
                            this.updateService.onUpdateStateReceived.next(
                                orStatus.update_progress);
                        }
                        if (!this.popoverOpen) {
                            this.popoverOpen = true;
                            const popoverConfig = this.popoverConfigService.getFirmwareUpdatePopover()
                            return this.popover.open(popoverConfig).afterClosed$
                                .pipe(map(() => {
                                    this.popoverOpen = false;
                                    return true;
                                }));
                        }
                        return of(false);
                    case 'wait':
                        this.firmwareUpdateStatus = 'loading';
                        return of(false);
                }
            })
        ).subscribe({
            next: result => {
                if (result) {
                    destroy$.next(null);
                    this.firmwareUpdateFinishedOrFailed = true;
                    this.firmwareUpdateStatus = 'uptodate';
                }

                ++currentCycle;
                if (updateStarted) {
                    if (currentCycle === totalCyclesUpdating) {
                        destroy$.next(null);
                        this.firmwareUpdateStatus = 'error';
                    }
                } else {
                    if (currentCycle === totalCycles) {
                        destroy$.next(null);
                        this.firmwareUpdateStatus = 'error';
                    }
                }
            }
        });
    }


    /**
     * Starts a timer that checks the meter status every 5 seconds
     * @private
     */
    private initializeContinuousRegistrationCheck(): void {
        const destroy$ = new Subject();
        const totalNumberOfTries =
            this.registrationOnboardRetryDuration / this.registrationOnboardRetryInterval;
        let currentNumberOfTries = 0;
        timer(0, 5000)
            .pipe(
                takeUntil(destroy$),
                switchMap(() =>
                    this.registration.registerDevice(this.plugMac).pipe(
                        mergeMap((response) => of(true)),
                        catchError((error) => {
                            try {
                                if (error.error.error.code === 102) {
                                    return of(true);
                                }
                            } catch (error) {
                                return of(false);
                            }
                        }),
                    )
                )
            )
            .subscribe({
                next: (result) => {
                    ++currentNumberOfTries;
                    if (currentNumberOfTries === totalNumberOfTries) {
                        destroy$.next(null);
                    }
                    if (result) {
                        destroy$.next(null);
                        this.registrationOnboardSuccessful = true;
                    }
                }
            });
    }


    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const storageItem = 'lastUpdateInfo';
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            return true;
        }
        return false;
    }


    private showUpdateAvailableOverlay(): void {
        if (!this.shouldTriggerAvailableUpdateOverlay()) {
            return;
        }
        if (this.popoverOpen) {
            return;
        }
        const overlayDef = this.popoverConfigService.getFirmwareUpdateAvailablePopoverConfig();
        this.popover.open(overlayDef).afterClosed$.pipe(
            mergeMap(res => of({type: MeterStatuses.CONNECTED_WITH_METER, res}))
        );
        this.popoverOpen = true;
    }


    /**
     * Trigger yet another overlay for some PIN related issue
     */
    private showManualPinEntryOverlay(): void {
        if (!this.popoverOpen) {
            const overlayDef = this.popoverConfigService.getManualPinEntryInfoPopoverConfig();
            if (this.meterPin) {
                overlayDef.data.text += `${this.translate.instant('screens.settings.currentPin', {pincode: this.meterPin})}`;
            }
            this.popover.open(overlayDef).afterClosed$.subscribe(() => {
                this.popoverOpen = false;
                this.preventPinInfoPopover = true;
            });
            this.popoverOpen = true;
        }
    }


    /**
     * Handle the PIN_FAILED Meter State
     */
    private handlePinFailedState(): void {
        const storageItem = 'lastPinInfo';

        let triggerOverlay = false;
        const lastTriggered = localStorage.getItem(storageItem);
        if (!lastTriggered) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(storageItem, moment().toDate().toString());
            triggerOverlay = true;
        }

        if (!triggerOverlay) {
            return;
        }

        const popoverConfig = this.popoverConfigService.getPinFailedPopoverConfig()
        const sub = this.popover.open(popoverConfig).afterClosed$.pipe(
            mergeMap((dialogData) => {
                if (dialogData.data === null) {
                    this.popoverOpen = false;
                    return null;
                }
                let overlayDef: any = this.popoverConfigService.getManualPinEntryPopoverConfig();
                if (dialogData.data) {
                    overlayDef = this.popoverConfigService.getPinEntryPopoverConfig();
                }
                if (this.meterPin) {
                    overlayDef.data.text += `${this.translate.instant('screens.settings.currentPin', {pincode: this.meterPin})}`;
                }
                return this.popover.open(overlayDef).afterClosed$;
            })
        ).subscribe((result: any) => {
            if (result === null) {
                this.popoverOpen = false;
                return;
            }
            if (typeof result.data === 'string') {
                // const pin = parseInt(result.data, 10);
                this.preventPinErrorPopover = true;
                this.meter.startContinuousPinEntry(result.data);
                this.meter.onPinEntrySuccess.subscribe((res) => {
                    if (res) {
                        this.preventPinErrorPopover = false;
                    }
                });
            }
            if (result) {
                this.popoverOpen = false;
            }

            this.popoverOpen = false;
        });

        this.popoverOpen = true;
    }


    private startInitialRegistration(): void {
        const mac = this.secondMac.replace(/:/g, '');
        const macPart1 = mac.substring(0, 6);
        const macPart2 = mac.substring(6);
        const finalMac = macPart1 + '0000' + macPart2;
        this.registrationSub = timer(0, 5000).pipe(
            switchMap(() =>
                this.registration.registerDevice(finalMac).pipe(
                    catchError(error => of(undefined))
                )
            ),
            mergeMap(res => res ? of(true) : of(undefined)),
            catchError(error => {
                let isSuccessError = false;
                try {
                    isSuccessError = error.error.code === 102;
                } catch (e) {
                    return of(undefined);
                }
                return isSuccessError ? of(true) : of(undefined);
            })
        ).subscribe(
            (final) => {
                if (final) {
                    this.registrationSub.unsubscribe();
                    this.registrationSub = null;
                } else {
                    if (this.registrationTries >= 60) {
                        this.registrationSub.unsubscribe();
                        this.registrationSub = null;
                    }
                    ++this.registrationTries;
                }
            },
            (error) => {
                console.log('Error: ', error);
                if (this.registrationTries >= 60) {
                    this.registrationSub.unsubscribe();
                    this.registrationSub = null;
                }
                ++this.registrationTries;
            },
        );
    }


    private openErrorPopover(data: any): void {
        if (this.popoverOpen) {
            return;
        }

        let config = null;
        let doReallyShow = true;
        switch (data.current_status.toUpperCase()) {
            case 'CONTACT_WRONG_METER_SERIAL': {
                config = this.popoverConfigService.getOnboardingWrongSerialPopoverConfig();
                const read = data.smartreader.meter_id_read;
                const provisioned = data.smartreader.meter_id_provisioned;
                doReallyShow = read !== provisioned;
                config.data.text = this.translate.instant('screens.register.onBoarding.errorMessages.wrongMeterSerial', {
                    read,
                    provisioned
                });
                break;
            }
            case 'CONTACT_NO_DATA_FROM_METER': {
                config = this.popoverConfigService.getOnboardingNoContactPopoverConfig();
                break;
            }
            default:
                config = this.popoverConfigService.getOnboardingNoContactPopoverConfig();
                break;
        }

        if (!doReallyShow) {
            return;
        }

        this.popover.open(config).afterClosed$.subscribe(
            (res) => {
                this.connectMeterWithPin();
                this.popoverOpen = false;
            }
        );
        this.popoverOpen = true;
    }


    private initializeRouteParamterHandling(): void {
        this.route.queryParams.subscribe(params => {
            const skipToOnboarding = params.jumpToOnboarding;
            const skipToMeterStatus = params.jumpToMeterstate;
            const skipToTutorial = params.jumpToTutorial;

            if (skipToOnboarding) {
                if (!this.userService.getActiveUserName()) {
                    this.router.navigate(['/registrieren']);
                    return;
                }
                this.registration.getModel().subscribe(
                    (modelResponse: any) => {
                        this.handleSkippingToOnboarding(modelResponse);
                    },
                    (error) => {
                        console.log(error);
                        this.router.navigate(['/registrieren']);
                    }
                );
                return;
            }

            if (skipToMeterStatus) {
                if (!this.userService.getActiveUserName()) {
                    this.router.navigate(['/registrieren']);
                }
                this.registration.getModel().subscribe(
                    (modelResponse) => {
                        this.isEnviamUser = this.userService.getActiveUserProvider() === 'enviam';
                        this.determineUserMode(modelResponse);
                        if (this.device === constants.application.devices.plug) {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        } else if (this.device === constants.application.devices.plug_optical) {
                            this.setStep(this.onboardingSteps.devices.plug.connecting);
                        } else {
                            this.setStep(this.onboardingSteps.devices.box.connecting);
                        }
                        // this.setStep('C');
                        this.navigationFromDashboard = true;
                        this.startMeterStatusUpdate(true);
                    },
                    error => {
                        this.router.navigate(['/registrieren']);
                        return;
                    }
                );
                return;
            }

            if (skipToTutorial) {
                this.registration.getModel().subscribe((modelResponse) => {
                    this.userService.setActiveUserProvider(modelResponse.labelpartner);
                    this.isERNAUser = this.userService.isEDGUser();
                    switch (modelResponse.model_identifier) {
                        case constants.application.devices.plug:
                            this.selectDevice('plug', true);
                            if (this.isERNAUser) {
                                this.setStep(this.onboardingSteps.devices.plug.macEntry);
                            }
                            break;
                        case constants.application.devices.plug_optical:
                            this.isERNAUser = true;
                            if (this.userService.getActiveUserProvider() === 'enviam') {
                                this.isEnviamUser = true;
                            }
                            this.selectDevice('plug', true);
                            this.setStep(this.onboardingSteps.devices.plug.macEntry);
                            break;
                        default:
                            if (this.debugLog) {
                                console.log('Device: box');
                            }
                            this.selectDevice('box');
                            this.setStep(this.onboardingSteps.devices.box.powerConnect);
                    }
                });
                return;
            }
        });
    }


    private handleSkippingToOnboarding(modelResponse): void {
        this.userService.setActiveUserProvider(modelResponse.labelpartner);
        this.isERNAUser = this.userService.isEDGUser();
        switch (modelResponse.model_identifier) {
            case constants.application.devices.plug:
                this.selectDevice('plug');
                break;
            case constants.application.devices.smart_box:
                this.selectDevice('plug', false, true, constants.application.devices.smart_box);
                this.isERNAUser = true;
                break;
            case constants.application.devices.smart_box2:
                this.selectDevice('plug', false, true, constants.application.devices.smart_box2);
                this.isERNAUser = true;
                break;
            case constants.application.devices.plug_optical:
                this.isERNAUser = true;
                if (this.userService.getActiveUserProvider() === 'enviam') {
                    this.isEnviamUser = true;
                }
                this.selectDevice('plug');
                break;
            default:
                if (this.debugLog) {
                    console.log('Device: box');
                }
                this.selectDevice('box');
                this.setStep(this.onboardingSteps.devices.box.powerConnect);
        }
    }


    private sendDataAnalyticsOptin(value: boolean): void {
        this.optInService.setAnalyticsOptIn(value).subscribe({
            next: (res) => {
                this.continueOnboardingAfterPermissionCheck();
            }
        });

    }


    private continueOnboardingAfterPermissionCheck(): void {
        switch (this.device) {
            case 'plug':
                if (this.isERNAUser) {
                    this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                    break;
                }
                this.currentStep = this.onboardingSteps.devices.plug.noteMac;
                break;
            default:
                this.setStep(this.onboardingSteps.devices.box.powerConnect);
                break;
        }
    }


    private mapMeterStatusResponse(response: any): Observable<any> {
        if (!('data' in response)) {
            if ('electricity' in response) {
                return of(response.electricity);
            }
            return of(false);
        }
        if (!('electricity' in response.data)) {
            return of(false);
        }
        if (!('current_status' in response.data.electricity)) {
            return of(false);
        }
        return of(response.data.electricity);
    }


    private setupPasswordFormHandling(): void {
        this.passwordForm.valueChanges.pipe(
            tap(values => this.currentPasswordScore = zxcvbn(values.password1).score),
            map((value) => {
                const first = this.passwordRegex.test(value.password1);
                const second = this.passwordRegex.test(value.password2);
                return first && second;
            })
        ).subscribe({
            next: result => {
                this.setPasswordDisabled = !result;
            }
        });
    }


    private checkPinExistence(): void {
        this.opticalReader.getOpticalReaderStatus(true).subscribe({
            next: (orStatus) => {
                try {
                    const pincode = orStatus.electricity.smartreader.pincode;
                    if (pincode || pincode === '') {
                        this.setPIN(pincode);
                    } else {
                        this.setStep(this.onboardingSteps.opticalReader.pinEntry);
                    }
                } catch (error) {
                    this.setStep(this.onboardingSteps.opticalReader.pinEntry);
                }
            }
        });
    }


    private trackAnalyticsVoucherEntered(value: boolean): void {
        this.analytics.trackEvent({
            action: 'onboarding_voucher_code_enter',
            properties: {
                category: 'Onboarding',
                label: `${value}`
            }
        });
    }
}
