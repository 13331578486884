<div class="overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{LABELS.TITLE}}
                    </h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Abschlags-Check"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>

                <section>
                    <ng-container *ngIf="{
                        financeData: financeData$ | async,
                        financeError: financeDataError$ | async
                        } as data">

                        <ng-container *ngIf="data.financeData && !data.financeError; else emptyStateAnimation">
                            <div class="finance-details-value center-contents">
                                <div *ngIf="data.financeData.trend < 0">
                                    <div class="status-icon-piggy"></div>
                                    <div>
                                        {{LABELS.LABEL_FRAGMENT_CIRCA}}
                                        <strong>
                                            {{ data.financeData.amount }} {{LABELS.UNIT_FINANCE}}
                                        </strong>
                                        {{LABELS.LABEL_FRAGMENT_CREDIT}}
                                    </div>
                                </div>
                                <div *ngIf="data.financeData.trend === 0">
                                    <div class="status-icon-thumb"></div>
                                    <div>
                                        <strong>
                                            {{LABELS.LABEL_CONSUMPTION_MATCHES}}
                                        </strong>
                                    </div>
                                </div>
                                <div *ngIf="data.financeData.trend > 0">
                                    <div class="status-icon-alert"></div>
                                    <div>
                                        {{LABELS.LABEL_FRAGMENT_CIRCA}}
                                        <strong>
                                            {{data.financeData.amount}}
                                            {{LABELS.UNIT_FINANCE}}
                                        </strong>
                                        {{LABELS.LABEL_FRAGMENT_ADDITIONAL_PAYMENT}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-template #emptyStateAnimation>
                            <div class="empty-state">
                                <div class="empty-state-animation-container">
                                    <div class="empty-state-animation"
                                         lottie [options]="lottieConfig"></div>
                                </div>
                                <div class="empty-state-label">
                                    {{LABELS.LABEL_EMPTY_STATE}}
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </section>
            </section>

            <ng-container *ngIf="bannerVisible">
                <app-detail-banner [bannerData]="bannerData" (hideBanner)="hideInfoBanner()"></app-detail-banner>
            </ng-container>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold"> {{TEXTS.TILE_INFO.TITLE}} </h2>
                    <p> {{TEXTS.TILE_INFO.PARAGRAPH_IONA}} </p>
                </div>
            </section>

            <section class="detail-content">
                <ng-container>
                    <ng-container *ngIf="financeData$ | async as financeData">
                        <h4>{{LABELS.LABEL_TIMEFRAME}}</h4>
                        <p class="finance-range">
                            {{formatStartDate(financeData.timeframe.begin)}}
                            -
                            {{formatEndDate(financeData.timeframe.end)}}
                        </p>
                        <ng-container [ngSwitch]="financeData.trend">
                            <p *ngSwitchCase="-1">
                                {{TEXTS.TREND.CASE_CREDIT}}
                                {{financeData.amount}}
                                {{TEXTS.TREND.CASE_UNIT}}
                            </p>
                            <p *ngSwitchCase="0">
                                {{TEXTS.TREND.CASE_MATCHES}}
                            </p>
                            <p *ngSwitchCase="1">
                                {{TEXTS.TREND.CASE_ADDITIONAL_PAYMENT}}
                                {{financeData.amount}}
                                {{TEXTS.TREND.CASE_UNIT}}
                            </p>
                        </ng-container>
                    </ng-container>
                    <p> &nbsp;</p>
                    <p> {{TEXTS.ADJUST_ONLINE}} </p>
                    <p>
                        <button class="iona-button orange" (click)="openLink()">
                            {{LABELS.BTN_LABEL_ADJUST}}
                        </button>
                    </p>
                    <p> {{TEXTS.INFO_IONA}} </p>
                </ng-container>
            </section>

        </div>
    </div>
</div>


