<div id="container">
    <app-menu activeElement="profile"></app-menu>
    <div id="inner">
        <main class="page">

            <div class="page-card">

                <h1>{{ 'common.myData' | translate }}</h1>
                <section>
                    <h2>{{ 'screens.profile.personalDataHeading' | translate }}</h2>
                    <div class="row">
                        <div>
                            <strong>{{ 'screens.profile.personalDataRealName' | translate }}</strong><br>
                            <strong>{{ 'screens.profile.personalDataUsername' | translate }}</strong>
                        </div>
                        <div>
                            {{contract.name}} <br>
                            {{determineDisplayUsername()}}
                        </div>
                    </div>

                    <div class="m-v-l button-container">
                        <button class="iona-button orange"
                                (click)="openChangeEmailPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ 'screens.profile.emailChange' | translate }}
                        </button>
                    </div>

                    <div class="m-v-l button-container">
                        <button class="iona-button orange"
                                (click)="openResetPasswordPopover()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ 'screens.profile.changePasswordButton' | translate }}
                        </button>
                    </div>

                    <div class="m-v-l button-container" *ngIf="!application.isDemoMode()">
                        <button class="iona-button orange"
                                (click)="enableDisableMFA()"
                                [disabled]="accountRewrite.accountRewriteEnabled()">
                            {{ mfaEnabled ? ('screens.profile.mfaDisableButton' | translate) : ('screens.profile.mfaEnableButton' | translate) }}
                        </button>
                    </div>
                </section>

                <section>
                    <h2>{{ 'screens.profile.providerHeading' | translate }}</h2>
                    <strong *ngIf="!isVisionUser">{{providerDisplayName}}</strong>

                    <div class="row">
                        <div>
                            <strong>{{ 'screens.profile.providerProduct' | translate }}</strong><br>
                            <strong *ngIf="!isVisionUser">{{ 'screens.profile.providerBaseprice' | translate }}</strong><br>
                            <strong *ngIf="!isVisionUser">{{ 'screens.profile.providerWorkprice' | translate }}</strong>
                        </div>
                        <div>
                            {{getCurrentTariffName()}} <br>
                            <ng-container *ngIf="!isVisionUser">
                                {{getCurrentTariffBasePrice()}} <br>
                                {{getCurrentTariffWorkPrice()}}
                            </ng-container>
                        </div>
                        <div>
                            <ng-container *ngIf="tariffs.length > 0 && activeTariff">
                                <button class="iona-button"
                                        (click)="editCurrentTariff()">
                                    {{ 'screens.profile.editTariffButton' | translate }}
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <ng-container *ngIf="isERNAUser">
                        <div class="m-v-l">
                            <button class="iona-button orange"
                                    [disabled]="createTariffDisabled"
                                    (click)="openNewTariffCreationPopover()">
                                {{ 'screens.profile.newTariff' | translate }}
                            </button>
                        </div>
                        <div class="m-v-l">
                            <button class="iona-button orange"
                                    [disabled]="tariffs.length < 1"
                                    (click)="openTariffHistoryPopover()">
                                {{ 'screens.profile.tariffHistoryButton' | translate }}
                            </button>
                        </div>
                    </ng-container>
                </section>

                <section>
                    <h2>{{buttonTitle}}</h2>
                    <h3>{{'ionaSpecific.profile.ernaDescription' | translate}}</h3>
                    <ul>
                        <li>{{'ionaSpecific.profile.ernaProvider' | translate}}</li>
                        <li>{{'ionaSpecific.profile.ernaMeter' | translate}}</li>
                        <li>{{'ionaSpecific.profile.ernaTips' | translate}}</li>
                        <li>{{'ionaSpecific.profile.ernaMuchmore' | translate}}</li>
                    </ul>
                    <button class="iona-button orange"
                            (click)="openLink()">
                        {{buttonTitle}}
                    </button>
                </section>

            </div>
        </main>
    </div>
</div>
