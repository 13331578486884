import {ViewState} from '../enums/view-state.enum';
import {ApplianceDiagramSeriesDataWrapper} from '../enums/appliances-tile-data.interfaces';


export interface AppliancesDetailListElement {
    name: string;
    kwh: string;
    cost: string;
    appliances: ApplianceDetailListItem[];
    description: string;
    accordionOpen: boolean;
    color: string | null;
    categoryProfileIncompleteOrRetraining: boolean;
}


export interface ApplianceDetailListItem {
    energy_ws: number;
    appliance_instance_id: string;
    cost: string;
    icon: string;
}


export interface AppliancesDetailData {
    series: ApplianceDiagramSeriesDataWrapper;
    categories: Array<AppliancesDetailListElement>;
    viewState: ViewState;
}


export const initialAppliancesDetailData: AppliancesDetailData = {
    series: {
        series: [],
        nilm: []
    },
    categories: [],
    viewState: ViewState.INITIAL
};
