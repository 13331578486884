<div id="container">
    <app-menu activeElement="contact"></app-menu>
    <div id="inner">
        <main class="page">
            <div class="page-card">
                <h1>{{ 'common.contact' | translate }}</h1>
                <h2>{{ 'screens.contact.sectionTitle' | translate }}</h2>
                <section>
                    <h3>{{ 'screens.contact.sections.phone.title' | translate }}</h3>
                    <p>{{ 'screens.contact.sections.phone.description' | translate }}</p>
                    <button class="iona-button orange" (click)="openPhone()">{{phone}}</button>
                </section>
                <section>
                    <h3>{{ 'screens.contact.sections.phone.titleFax' | translate }}</h3>
                    <p>{{ 'screens.contact.sections.phone.descriptionFax' | translate }}</p>
                    <button class="iona-button orange" (click)="openFax()">{{fax}}</button>
                </section>
                <section>
                    <h3>{{ 'screens.contact.sections.email.title' | translate }}</h3>
                    <p>{{ 'screens.contact.sections.email.description' | translate }}</p>
                    <button class="iona-button orange"
                            (click)="openMail()">{{ 'screens.contact.contactService' | translate }}</button>
                </section>
            </div>
        </main>
    </div>
</div>
