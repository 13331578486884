import {Injectable,} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription, timer} from 'rxjs';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import * as moment from 'moment';
import {AccountRewriteService} from './account-rewrite.service';


@Injectable({
    providedIn: 'root'
})
export class LiveDataService extends BaseService {

    // live data subject
    onLiveConsumptionReceived = new BehaviorSubject<any>(null);
    onFilteredLiveConsumptionReceived = new Subject<any>();

    private updateRate = 10000;

    private liveConsumptionSub: Subscription = null;
    private filteredConsumptionSub: Subscription = null;
    private consumptionAlertConsumptionSub: Subscription = null;

    private currentFilter = {
        offset: 5,
        limit: 0,
        interval: 1,
        level: 1
    };


    constructor(
        protected http: HttpClient,
        protected auth: ApiService,
        protected user: UserService,
        private accountRewrite: AccountRewriteService
    ) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.liveConsumptionSub) {
            this.liveConsumptionSub.unsubscribe();
            delete this.liveConsumptionSub;
        }
        if (this.filteredConsumptionSub) {
            this.filteredConsumptionSub.unsubscribe();
            delete this.filteredConsumptionSub;
        }
        if (this.consumptionAlertConsumptionSub) {
            this.consumptionAlertConsumptionSub.unsubscribe();
            delete this.consumptionAlertConsumptionSub;
        }
    }


    /**
     * Update the current live values for filtered output
     * @param offset
     * @param limit
     * @param interval
     * @param level
     */
    setLiveValues(offset: number, limit: number, interval: number, level: number): void {
        this.currentFilter.offset = offset;
        this.currentFilter.limit = limit;
        this.currentFilter.interval = interval;
        this.currentFilter.level = level;

        const temp = this.getLiveData(
            this.currentFilter.offset,
            this.currentFilter.limit,
            this.currentFilter.interval
        ).subscribe((res) => {
            if (res) {
                this.onFilteredLiveConsumptionReceived.next(res);
                temp.unsubscribe();
            }
        });
    }


    /**
     * Starts update to retrieve the current consumption
     * @param hourly
     */
    startCurrentConsumptionUpdate(hourly = false): void {
        if (this.liveConsumptionSub) {
            return;
        }
        this.liveConsumptionSub = timer(0, this.updateRate)
            .pipe(
                mergeMap((cycle) => {
                    if (!hourly) {
                        return this.getLiveData(5, 0, 1);
                    }
                    return this.getLiveData(60, 0, 1);
                })
            ).pipe(
                map((res) => res),
                catchError((error: any) => this.handleError(error))
            ).subscribe(
                (res) => {
                    if (res) {
                        this.onLiveConsumptionReceived.next(res);
                    }
                },
                error => {
                    // console.log(error);
                }
            );
    }


    /**
     * Starts the interval to retrieved live values based on set filters
     */
    startFilteredLiveConsumptionUpdate(): void {
        if (this.filteredConsumptionSub) {
            return;
        }
        this.filteredConsumptionSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => {
                    return this.getLiveData(
                        this.currentFilter.offset,
                        this.currentFilter.limit,
                        this.currentFilter.interval
                    );
                }
            )
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onFilteredLiveConsumptionReceived.next(res);
                }
            },
            error => {
                console.log(error);
            }
        );
    }


    /**
     * Request live data for a certain time frame
     * @param offset in minutes
     * @param limit in minutes
     * @param interval
     */
    getLiveData(offset: number, limit: number, interval: number, verbose = false): Observable<any> {
        const time_from: any = new Date();
        time_from.setMinutes(time_from.getMinutes() - offset);

        const time_to: any = new Date();
        time_to.setMinutes(time_to.getMinutes() - limit);

        const method = time_from.toISOString() + '/' + time_to.toISOString() + '/' + interval;
        if (verbose) {
            const from = moment(time_from).format('DD.MM.YYYY hh:mm:ss');
            const to = moment(time_to).format('DD.MM.YYYY hh:mm:ss');
        }
        return this.http.get(this.determineLiveDataUrl(method)).pipe(
            map((res) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Alternative live data call
     * @param from
     * @param to
     * @param interval
     * @param verbose
     */
    getLiveDataAlt(from: Date, to: Date, interval: number, verbose = false): Observable<any> {
        const method = from.toISOString() + '/' + to.toISOString() + '/' + interval;
        if (verbose) {
            const f = moment(from).format('DD.MM.YYYY hh:mm:ss');
            const t = moment(to).format('DD.MM.YYYY hh:mm:ss');
        }
        return this.http.get(this.determineLiveDataUrl(method)).pipe(
            map((res) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Live-data url determination wrapper
     * @param method
     */
    private determineLiveDataUrl(method: string): string {
        let url = this.API_BASE_URL + constants.api.routes.power;
        // rewrite 'feature'
        if (this.accountRewrite.accountRewriteEnabled()) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.power;
        }
        url += '/' + method;
        return url;
    }
}
