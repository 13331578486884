import {ViewState} from '../enums/view-state.enum';
import {HouseholdComparisonTimeframe} from '../enums/household-comparison-timeframe.enum';
import {
    HouseholdComparisonRankRowConfig
} from '../../components/household-comparison/household-comparison-rank-row/household-comparison-rank-row.component';


/**
 * Household comparison properties.
 * =================================================================================================
 */
export interface HouseholdComparisonProps {
    occupants: boolean;
    spaceHeating: boolean;
    waterHeating: boolean;
    propertyType: boolean;
    evCharger: boolean;
    swimmingPool: boolean;
    sauna: boolean;
}


/**
 * Household comparison data.
 * =================================================================================================
 */
export interface HouseholdComparisonData {
    consumption: number;
    rank: number;
    diagramData: HouseholdComparisonDiagramData;
    averageConsumption: number;
    viewState: ViewState;
    comparableHouseholds: number;
}


export const initialHouseholdComparisonData: HouseholdComparisonData = {
    consumption: 0,
    rank: 0,
    diagramData: {
        timeframe: HouseholdComparisonTimeframe.CURRENT_YEAR,
        data: [],
        averageBucketIdx: -1,
        averageConsumption: 0,
        highlightedBucketCalloutValue: 0
    },
    averageConsumption: 0,
    comparableHouseholds: 0,
    viewState: ViewState.INITIAL,
};


export interface HouseholdComparisonDiagramData {
    timeframe: HouseholdComparisonTimeframe;
    data: Array<HouseholdComparisonDiagramDataEntry>;
    averageBucketIdx: number;
    averageConsumption: number;
    highlightedBucketCalloutValue: number;
}


export interface HouseholdComparisonDiagramDataEntry {
    y: number;
    x: number;
    highlighted: boolean;
}


/**
 * Household comparison appliances data.
 * =================================================================================================
 */
export interface HouseholdComparisonAppliancesData {
    totalConsumptionMe: number;
    totalConsumptionOthers: number;
    viewState: ViewState;
    deviceCategories: Array<HouseholdComparisonAppliancesDataEntry>;
}


export interface HouseholdComparisonAppliancesDataEntry {
    name: string;
    consumptionMe: number;
    consumptionOthers: number;
    consumptionRatioMe: number;
    consumptionRatioOthers: number;
}


export const initialHouseholdComparisonAppliancesData: HouseholdComparisonAppliancesData = {
    totalConsumptionMe: 0,
    totalConsumptionOthers: 0,
    viewState: ViewState.INITIAL,
    deviceCategories: [],
};


/**
 * Household comparison ranking data.
 * =================================================================================================
 */
export enum RankingPosition {
    UPPER_THIRD = 'upperThird',
    LOWER_THIRD = 'lowerThird',
    MIDDLE = 'middle'
}


export interface HouseholdComparisonRankingData {
    // isInFirstRanks: boolean;
    // isInLastRanks: boolean;
    rowConfigs: Array<HouseholdComparisonRankRowConfig>;
    // position: RankingPosition;
    viewState: ViewState;
}


export interface HouseholdComparisonRankingDataEntry {
    rank: number;
    consumption: number;
    me: boolean;
}


export const initialHouseholdComparisonRankingData: HouseholdComparisonRankingData = {
    rowConfigs: [],
    // isInFirstRanks: false,
    // isInLastRanks: false,
    // position: RankingPosition.MIDDLE,
    viewState: ViewState.INITIAL,
};

