<div id="container">
    <app-menu activeElement="settings"></app-menu>
    <div class="page">
        <div id="inner">
            <div class="page-card">
                <h1> {{'screens.settings.title' | translate}}</h1>
                <section>
                    <h2 class="extra-space">{{'screens.settings.connections.title' | translate}}</h2>
                    <div>
                        <h3>{{ deviceIsPlug ? ('ionaSpecific.devices.plug2' | translate) : isBox2 ? ('ionaSpecific.devices.box2' | translate) : ('ionaSpecific.devices.box' | translate) }}</h3>
                        <div class="connection-container p-b-l p-t-s">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="wifiConnectionQuality"
                                                          container_height="24px"
                                                          [disabled]="wifiConnected >= 0"
                                                          [config]="wifiConnectionConfig"
                                                          [ignoreValue]="lanConnected">
                                </app-connection-indicator>
                            </div>
                            <ng-container *ngIf="lanConnected else wifiConnection">
                                <div>{{'common.connected' | translate}}</div>
                            </ng-container>
                            <ng-template #wifiConnection>
                                <div>
                                    {{ wifiConnected < 0 ? ('screens.settings.connections.wifiConnected' | translate) : ('screens.settings.connections.wifiDisconnected' | translate) }}
                                </div>
                            </ng-template>
                        </div>

                        <h3>{{'screens.settings.meter.title' | translate}}</h3>
                        <div class="connection-container p-r-s p-b-l">
                            <div class="p-r-s">
                                <app-connection-indicator [value]="meterConnectionQuality"
                                                          [container_height]="'24px'"
                                                          [disabled]="meterStatus !== 'connected'"
                                                          [config]="meterConnectionConfig">
                                </app-connection-indicator>
                            </div>
                            <div>{{determineMeterStatusMessage()}}</div>
                        </div>

                        <ng-container *ngIf="pincode">
                            <h3>{{'screens.settings.pincode.title' | translate}}</h3>
                            <div class="p-r-s">
                                {{pincode}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser && userService.isEnviamUser() && pinEntryUnknownOrOptical">
                            <button class="iona-button orange m-t-l"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onPinEntryOpen()">
                                {{'screens.settings.pinEntry.button' | translate}}
                            </button>
                        </ng-container>

                        <ng-container *ngIf="isEDGUser">
                            <h3 class="m-t-l">{{'screens.settings.batteryStatus.title' | translate}}</h3>
                            <div class="p-t-s">
                                <app-battery-load [currentBatteryState]="currentBatteryState"
                                                  [isAvailable]="currentBatteryState > -1">
                                </app-battery-load>
                            </div>

                            <button class="iona-button orange m-t-x"
                                    [disabled]="currentBatteryState === 0"
                                    (click)="onEnergySaverOpen()">
                                {{'screens.settings.energySaver.button' | translate}}
                            </button>
                        </ng-container>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">{{'screens.settings.onlineMode.title' | translate}}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="optin-checkbox">
                            <input #optInCb type="checkbox" name="checkbox" id="optin-checkbox"
                                   [checked]="config.optIn"
                                   (change)="setOptIn(optInCb.checked)">
                            {{'screens.settings.onlineMode.label' | translate}}
                        </label>
                        <p>
                            {{ 'ionaSpecific.settings.onlineMode.description' | translate: {device: (deviceIsPlug ? ('ionaSpecific.devices.plug3' | translate) : (isBox2 ? ('ionaSpecific.devices.box4' | translate) : ('ionaSpecific.devices.box3' | translate)))} }}
                        </p>
                    </div>
                    <h2 class="extra-space">{{ 'screens.settings.anonymousDataProcessing.title' | translate }}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="hhc-optout-checkbox">
                            <input #hhcOptOutCb type="checkbox" name="checkbox" id="hhc-optout-checkbox"
                                   [checked]="config.householdComparisonOptOut"
                                   (change)="setHouseholdComparisonOptOut(hhcOptOutCb.checked)">
                            {{ 'screens.settings.anonymousDataProcessing.label' | translate }}
                        </label>
                        <p>
                            {{ 'screens.settings.anonymousDataProcessing.description' | translate }}
                        </p>
                    </div>

                    <h2 class="extra-space">{{ 'screens.settings.optInDataProcessing.title' | translate }}</h2>
                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="data-optin-checkbox">
                            <input #dataOptInCheckbox type="checkbox" name="checkbox" id="data-optin-checkbox"
                                   [checked]="config.dataOptin"
                                   (change)="setDataOptinCheckBox(dataOptInCheckbox.checked)">
                            {{ 'screens.settings.optInDataProcessing.label' | translate }}
                        </label>
                        <p>
                            {{'ionaSpecific.settings.optInDataProcessing.description' | translate}}
                        </p>
                    </div>
                </section>

                <section>
                    <h2 class="extra-space">{{ 'screens.settings.language.title' | translate }}</h2>
                    <p>
                        {{ 'screens.settings.language.description' | translate }}
                    </p>
                    <button class="iona-button orange" (click)="openLanguageModal()">
                        {{ 'screens.settings.language.changeButton' | translate }}
                    </button>
                </section>

                <button class="iona-button orange" (click)="saveSettings()">
                    {{ 'screens.settings.saveButton' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
