import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {
    HouseholdComparisonDetailViewMode
} from '../../../shared/enums/household-comparison-detail-view-mode.enum';
import {
    HouseholdComparisonTotalComponent
} from '../../../components/household-comparison/household-comparison-total/household-comparison-total.component';
import {
    HouseholdComparisonDataProviderService
} from '../../../services/data-provider/household-comparison-data-provider.service';
import {
    HouseholdComparisonTimeframe
} from '../../../shared/enums/household-comparison-timeframe.enum';
import {
    HouseholdComparisonAppliancesComponent
} from '../../../components/household-comparison/household-comparison-appliances/household-comparison-appliances.component';
import {
    HouseholdComparisonRankComponent
} from '../../../components/household-comparison/household-comparison-rank/household-comparison-rank.component';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-household-comparison-detail',
    templateUrl: './household-comparison-detail.component.html',
    styleUrls: ['./household-comparison-detail.component.scss']
})
export class HouseholdComparisonDetailComponent extends BasePopover implements OnInit {


    constructor(
        protected popoverRef: PopoverRef,
        public dataProviderService: HouseholdComparisonDataProviderService,
        private cdRef: ChangeDetectorRef,
        private router: Router,
        private translate: TranslateService
    ) {
        super(popoverRef);
    }
    LABELS;
    TEXTS;
    readonly ViewMode = HouseholdComparisonDetailViewMode;
    readonly Timeframes = HouseholdComparisonTimeframe;

    currentViewMode = HouseholdComparisonDetailViewMode.TOTAL;
    currentTimeframe = HouseholdComparisonTimeframe.LAST_MONTH;

    infoVisible = false;
    sidebarHidden = false;

    selectedPortal: Portal<any>;
    totalViewPortal: ComponentPortal<HouseholdComparisonTotalComponent>;
    appliancesViewPortal: ComponentPortal<HouseholdComparisonAppliancesComponent>;
    rankViewPortal: ComponentPortal<HouseholdComparisonRankComponent>;

    householdComparisonData$ = this.dataProviderService.combinedClusterRankingData$;


    protected readonly open = open;


    ngOnInit(): void {
        this.initPortals();
        this.initializeDetailView();
        this.translate.get('screens.dashboard.householdComparison.detailTexts').subscribe(texts => {
            this.TEXTS = texts;
        });

        this.translate.get('screens.dashboard.householdComparison.detailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }


    /**
     * Called when a component is attached to the portal.
     * @param ref
     */
    onComponentAttachedToPortal(ref: any): void {
        const instance = ref.instance;
        this.sidebarHidden = instance instanceof HouseholdComparisonRankComponent;
        this.cdRef.detectChanges();
    }


    /**
     * Called when the view mode is changed in the detail view tab bar.
     * @param newMode
     */
    setMode(newMode: HouseholdComparisonDetailViewMode): void {
        this.currentViewMode = newMode;
        switch (newMode) {
            case HouseholdComparisonDetailViewMode.TOTAL:
                this.selectedPortal = this.totalViewPortal;
                break;
            case HouseholdComparisonDetailViewMode.APPLIANCES:
                this.selectedPortal = this.appliancesViewPortal;
                break;
            case HouseholdComparisonDetailViewMode.RANKING:
                this.selectedPortal = this.rankViewPortal;
                break;
        }
    }


    /**
     * Called when the timeframe is changed in the detail view select.
     * @param timeframe
     */
    timeframeChanged(timeframe: HouseholdComparisonTimeframe): void {
        this.dataProviderService.setTimeframe(timeframe);
    }


    /**
     * Determines the display value for the sidebar.
     */
    determineSidebarDisplay(): string {
        return this.sidebarHidden ? 'none' : 'block';
    }


    /**
     * Routes to settings page
     */
    routeToSettings(): void {
        this.close();
        this.router.navigate(['einstellungen']);
    }


    /**
     * Initialies the portal with the total view component.
     * @private
     */
    private initializeDetailView(): void {
        this.currentTimeframe = this.dataProviderService.getCurrentStoredTimeframe();

        this.selectedPortal = this.totalViewPortal;
    }


    /**
     * Initializes the portals for the different view modes.
     * @private
     */
    private initPortals(): void {
        this.totalViewPortal = new ComponentPortal(HouseholdComparisonTotalComponent);
        this.appliancesViewPortal = new ComponentPortal(HouseholdComparisonAppliancesComponent);
        this.rankViewPortal = new ComponentPortal(HouseholdComparisonRankComponent);
    }
}
