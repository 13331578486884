export const liveDetailZoomLevels: Array<LiveZoomLevel> = [
    {
        name: 'screens.dashboard.liveDetailZoomLevels.day',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPer15Minutes',
        level: 4,
        interval: 15 * 60,
        offset: 60 * 24,
        resolution: 60 * 60 * 4,
        format: '%H:%M {{timeUnit}}'
    },
    {
        name: 'screens.dashboard.liveDetailZoomLevels.twelveHours',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerMinute',
        level: 3,
        interval: 60,
        offset: 60 * 12,
        resolution: 60 * 60,
        format: '%H:%M {{timeUnit}}'
    },
    {
        name: 'screens.dashboard.liveDetailZoomLevels.oneHour',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerMinute',
        level: 2,
        interval: 60,
        offset: 60,
        resolution: 60 * 15,
        format: '%H:%M {{timeUnit}}'
    },
    {
        name: 'screens.dashboard.liveDetailZoomLevels.fiveMinutes',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerSecond',
        level: 1,
        interval: 1,
        offset: 5,
        resolution: 60,
        format: '%H:%M:%S {{timeUnit}}'
    }
];

export const liveDetailERNAZoomLevels: Array<LiveZoomLevel> = [
    {
        name: 'screens.dashboard.liveDetailZoomLevels.oneMonth',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerDay',
        level: 3,
        interval: 60 * 60 * 24,
        offset: 60 * 24 * 30,
        resolution: 60 * 60 * 24 * 2,
        format: '%d.%m.%Y'
    },
    {
        name: 'screens.dashboard.liveDetailZoomLevels.oneWeek',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerDay',
        level: 2,
        interval: 60 * 60 * 24,
        offset: 60 * 24 * 7,
        resolution: 60 * 60 * 24,
        format: '%d.%m.%Y'
    },
    {
        name: 'screens.dashboard.liveDetailZoomLevels.oneDay',
        hint: 'screens.dashboard.liveDetailZoomLevels.hintPerHour',
        level: 1,
        interval: 60,
        offset: 60 * 24,
        resolution: 60 * 60 * 2,
        format: '%H:%M Uhr'
    },
];

export interface LiveZoomLevel {
    name: string;
    hint: string;
    level: number;
    interval: number;
    offset: number;
    resolution: number;
    format: string;

}
