<div class="finance-status-icon-container" appMatchWidth>
    <div class="finance-status-icon"
         [class.status-icon-piggy]="financialTrend.trend< 0"
         [class.status-icon-thumb]="financialTrend.trend === 0"
         [class.status-icon-alert]="financialTrend.trend > 0">
        &nbsp;
    </div>

    <div class="status-circle circle"
         [style.width.%]="financialTrend.trend < 0 ? financialTrend.percentage : 100"
         [style.height.%]="financialTrend.trend < 0 ? financialTrend.percentage : 100">
    </div>

    <div class="status-circle-outline circle"
         [style.width.%]="financialTrend.trend > 0 ? financialTrend.percentage : 100"
         [style.height.%]="financialTrend.trend > 0 ? financialTrend.percentage : 100">
    </div>
</div>
