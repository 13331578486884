import {HouseholdComparisonTimeframe} from '../enums/household-comparison-timeframe.enum';


/**
 * Payload
 * =================================================================================================
 */
export interface BenchmarkFilterPayloadProps {
    occupants?: string;
    space_heating?: string;
    ev_charger?: number;
    swimming_pool?: number;
    sauna?: number;
    water_heating?: string;
    property_type?: string;
}


export const emptyBenchmarkClusterPayloadProps: BenchmarkFilterPayloadProps = {
    occupants: '',
    space_heating: '',
    water_heating: '',
    property_type: '',
    ev_charger: 0,
    swimming_pool: 0,
    sauna: 0,
};


export interface BenchmarkFilterMetaPayload {
    payload: BenchmarkFilterPayload;
}


export interface BenchmarkFilterPayload {
    period: string;
    props?: BenchmarkFilterPayloadProps;
}


/**
 * Cluster
 * =================================================================================================
 */
export interface BenchmarkClusterRow {
    buckets: number;
    count: number;
    avg: number;
    min: number;
    max: number;
    me?: number;
    global_avg?: number;
}


export interface BenchmarkClusterResponse {
    global_avg: number;
    count: number;
    rows: Array<BenchmarkClusterRow>;
}


/**
 * Ranking
 * =================================================================================================
 */
export interface BenchmarkRankingRow {
    rank: number;
    electricity_total: string;
}


export interface BenchmarkRankingResponse {
    rank_me: number;
    rows: Array<BenchmarkRankingRow>;
}


/**
 * Device Categories
 * =================================================================================================
 */
export interface BenchmarkDeviceCategoryRow {
    cat: string;
    me_m: number;
    other_m: number;
    me: number;
    other: number;
}


export interface BenchmarkDeviceCategoriesResponse {
    count: number;
    rows: Array<BenchmarkDeviceCategoryRow>;
}


/**
 * Opt Out
 * =================================================================================================
 */
export interface BenchmarkOptOutResponse {
    meter_serial_number: string;
    created_at: string;
}
