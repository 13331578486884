import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {InitializationService} from '../../services/initialization.service';
import {map, mergeMap} from 'rxjs/operators';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {SmartBridgeService} from '../../services/smart-bridge.service';
import {Popover} from '../../popovers/popover/popover.service';
import {ActivatedRoute} from '@angular/router';
import {
    EnergySaverControlComponent
} from '../../popovers/energy-saver-control/energy-saver-control.component';
import {
    MeterConnectionConfig,
    WifiConnectionConfig
} from '../../shared/constants/connection.constants';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {PopoverConfigService} from '../../popovers/static.popover.config';
import {iif, Observable, of, throwError, zip} from 'rxjs';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {BenchmarkService} from '../../services/benchmark.service';
import {OptInService} from '../../services/opt-in.service';
import {MatDialog} from '@angular/material/dialog';
import {LanguageModalComponent} from '../../components/language-modal/language-modal.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {
    config: any = {
        tracking: false,
        optIn: 1,
        householdComparisonOptOut: true,
        dataOptin: false,
    };

    // connection qualities
    wifiConnectionQuality = 0;
    wifiConnected = 0;
    lanConnected = false;
    wifiConnectionConfig = WifiConnectionConfig;

    meterConnectionQuality = 0;
    meterStatus = 'disconnected';
    meterConnectionConfig = MeterConnectionConfig;
    pincode = null;

    deviceIsPlug = false;
    isBox2 = false;

    isEDGUser = false;
    currentBatteryState = 0;
    currentEnergySaverThreshold = 1;
    pinEntryUnknownOrOptical = false;


    constructor(
        public application: ApplicationService,
        public userService: UserService,
        private pageTitle: Title,
        private notificationService: ToastrService,
        private meterService: MeterService,
        private versionService: VersionService,
        private registrationService: RegistrationService,
        private initializationService: InitializationService,
        private analyticsService: TrackAnalyticsService,
        private opticalReader: OpticalReaderService,
        private smartBridge: SmartBridgeService,
        private popover: Popover,
        private route: ActivatedRoute,
        private localOptInService: LocalOptInService,
        private benchmarkService: BenchmarkService,
        private optInService: OptInService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private popoverConfigService: PopoverConfigService
    ) {
        super();
    }


    ngOnInit() {
        this.initComponent();
    }

    openLanguageModal(): void {
        const dialogRef = this.dialog.open(LanguageModalComponent, {
            width: '',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {});
    }


    ngOnDestroy() {
    }


    /**
     * Save the made changes
     */
    saveSettings() {
        if (this.application.isDemoMode()) {
            this.notificationService.info(this.translate.instant('common.demoModeInfo'));
            return;
        }
        this.analyticsService.changeTrackingState(this.config.tracking);

        const optinDevice$ = this.registrationService.optInDevice(this.config.optIn);
        const optinAnalyticsData$ = this.optInService.setAnalyticsOptIn(this.config.dataOptin);
        zip([optinDevice$, optinAnalyticsData$]).subscribe({
            next: ([optinDevice, optinAnalyticsData]) => {
                this.notificationService.success(this.translate.instant('screens.settings.settingsSaved'));
            }
        });

        this.setBenchmarkingOptOut();

        this.localOptInService.checkStatus();
    }


    /**
     * Determine the current Meter status message
     */
    determineMeterStatusMessage(): string {
        switch (this.meterStatus) {
            case 'connected':
                return this.translate.instant('common.connected');
            default:
                return this.translate.instant('common.notConnected');
        }
    }


    /**
     * Opens the EnergySaverConfig Overlay
     */
    onEnergySaverOpen(): void {
        this.popover.open({
            content: EnergySaverControlComponent,
            data: {threshold: this.currentEnergySaverThreshold},
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe((res) => {
        });
    }


    /**
     * Set the tracking settings
     */
    setTracking(value: boolean) {
        this.config.tracking = value;
    }


    /**
     * Set the opt-in settings
     */
    setOptIn(value: boolean) {
        this.config.optIn = value;
    }


    /**
     * Set the opt-out settings for the household comparison feature.
     */
    setHouseholdComparisonOptOut(value: boolean) {
        this.config.householdComparisonOptOut = value;
    }


    setDataOptinCheckBox(value: boolean): void {
        this.config.dataOptin = value;
    }


    /**
     * Opens the Pin Entry Dialog
     */
    onPinEntryOpen(): void {
        const config = this.popoverConfigService.getManualPinEntryPopoverConfig();
        if (this.pincode) {
            config.data.text += ` ${this.translate.instant('screens.settings.currentPin', { pincode: this.pincode })}`;
        }
        this.popover.open(config).afterClosed$.pipe(
            mergeMap((value: any) => iif(
                () => value.data,
                this.meterService.putOpticalReaderPin(value.data),
                of(false)
            ))
        ).subscribe(res => {
        });
    }


    /**
     * Base component initialization
     */
    private initComponent(): void {
        this.translate.get('ionaSpecific.settings.pageTitle').subscribe((title: string) => {
            this.pageTitle.setTitle(title);
        });
        this.setupOptInHandling();

        const device = this.userService.getUserDevice();
        this.deviceIsPlug = device === constants.application.devices.plug
            || device === constants.application.devices.plug_optical;
        this.isBox2 = device === constants.application.devices.smart_box || device === constants.application.devices.smart_box2;

        this.isEDGUser = this.userService.isEDGUser();
        this.addSub(this.initializationService.getMeterConnectionInfo()
            .subscribe(status => this.meterStatus = status));

        this.setupMeterStatusHandling();

        if (this.isEDGUser) {
            this.getOpticalReaderBatteryStatus();
        }

        this.config.tracking = this.analyticsService.getTrackingState();
        this.listenForRouteChanges();

        // benchmark optout
        this.benchmarkService.getOptOutSetting().subscribe({
            next: (res) => {
                this.config.householdComparisonOptOut = !res;
            },
        });

        // data optin
        this.optInService.getAnalyticsOptIn().subscribe({
            next: (enabled) => {
                this.config.dataOptin = enabled;
            }
        });
    }


    /**
     * Listen for changes on the current component route
     *  if the field 'open' container 'energy-saver' directly
     *  open the energy saver configuration overlay
     */
    private listenForRouteChanges(): void {
        this.route.params.subscribe((params) => {
            if (!('open' in params)) {
                return;
            }
            if (params.open === 'energy-saver') {
                this.onEnergySaverOpen();
            }
        });
    }


    /**
     * Request the current battery status of the optical reader
     */
    private getOpticalReaderBatteryStatus(): void {
        this.opticalReader.getOpticalReaderStatus().subscribe((res) => {
            this.currentBatteryState = res.battery_status;
            this.meterConnectionQuality = res.connection_quality;
            this.pincode = res.pincode;
        });
    }


    /**
     * Setup handling for meter/status
     * if the user is a user with an optical reader --> also check meterreader/status
     */
    private setupMeterStatusHandling(): void {
        // extract wifi connection quality
        this.meterService.getStatus().pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        );

        this.meterService.startLiveUpdate();
        this.addSub(this.meterService.onMeterStatus.pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(result =>
                this.handleMeterWifiValue(result),
            error => null,
            () => null
        ));

        if (!this.isEDGUser) {
            return;
        }

        this.addSub(this.registrationService.getOnline().pipe(
            mergeMap((res) => {
                if (!this.isEDGUser) {
                    return this.meterService.getStatus().pipe(
                        mergeMap(meterResponse => of({meterReaderResponse: null, meterResponse}))
                    );
                }
                return this.opticalReader.getOpticalReaderStatus(true);
            }),
            map((results: any) => {
                this.meterConnectionQuality = ('meter_txrssi' in results) ? results.meter_txrssi : 0;
                try {
                    this.pinEntryUnknownOrOptical =
                        results.electricity.smartreader.pin_entry_mode === 'optical' ||
                        results.electricity.smartreader.pin_entry_mode === 'unknown';
                } catch (e) {
                    return null;
                }

                return null;
            })).subscribe((res) => null));
    }


    /**
     * Utility method to extract the Wifi Connection parameter from a passed request
     * @param response
     */
    private extractMeterWifiConnection(response): Observable<number> {
        try {
            return of(response['wlan_rssi']);
        } catch (e) {
            return throwError(e);
        }
    }


    /**
     * Handle Meter Wifi Value and determine conneciton strength as well status
     * @param value
     */
    private handleMeterWifiValue(value: number): void {
        this.wifiConnectionQuality = value;
        this.wifiConnected = this.wifiConnectionQuality;
        if (this.wifiConnectionQuality === 0) {
            this.lanConnected = true;
        }
    }


    /**
     * Sets up the initial opt in handling so that users can give and revoke their permissions
     */
    private setupOptInHandling(): void {
        this.localOptInService.checkStatus();

        const s = this.localOptInService.onDialogSettingsChange.subscribe(result =>
            this.config.optIn = result
        );
        this.addSub(s);

        this.registrationService.getOptInStatus().subscribe(
            res => {
                this.config.optIn = res;
            });
    }


    /**
     * Sets benchmarking optout based on the current value.
     * @private
     */
    private setBenchmarkingOptOut() {
        // benchmark optin
        if (!this.config.householdComparisonOptOut) {
            this.benchmarkService.setOptOutSetting().subscribe({
                next: (res) => {
                }
            });
        } else {
            this.benchmarkService.deleteOptOutSetting().subscribe({
                next: (res) => {
                }
            });
        }
    }
}
