import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
    ApplianceRetrainingDataProviderService
} from '../../../services/data-provider/appliance-retraining-data-provider.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {
    AppliancesRetrainingCategoriesListItem
} from '../../../shared/interfaces/appliances-retraining-category.interfaces';
import {Popover} from '../../../popovers/popover/popover.service';
import {
    PopoverConfigService
} from '../../../popovers/static.popover.config';
import {translateApplianceLowercase} from '../../../tiles/appliances/appliances.utils';
import {NilmService} from '../../../services/nilm.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-appliances-retraining',
    templateUrl: './appliances-retraining.component.html',
    styleUrls: ['./appliances-retraining.component.scss']
})
export class AppliancesRetrainingComponent implements OnInit {
    TEXTS;
    LABELS;
    private readonly translateApplianceLowercase;


    retrainingCategoryData$: Observable<AppliancesRetrainingCategoriesListItem[]> =
        this.retrainingDataProvider.appliancesRetrainingData$.pipe(
            map(data => {
                const keys = Object.keys(data);
                const result = Object.keys(data).map(key => {
                    return {category: key, data: data[key]};
                }) as AppliancesRetrainingCategoriesListItem[];
                return result;
            })
        );


    constructor(
        private retrainingDataProvider: ApplianceRetrainingDataProviderService,
        private nilmService: NilmService,
        private popover: Popover,
        private translate: TranslateService,
        private popoverConfigService: PopoverConfigService
    ) {
        this.translateApplianceLowercase = translateApplianceLowercase(this.translate);
    }


    ngOnInit(): void {
        this.retrainingDataProvider.getAppliancesRetrainingData(false);
        this.translate.get('screens.dashboard.appliances.appliancesDetailTexts').subscribe(texts => {
            this.TEXTS = texts;
        });

        this.translate.get('screens.dashboard.appliances.appliancesDetailLabels').subscribe(labels => {
            this.LABELS = labels;
        });
    }


    /**
     * Returns a string that represents the progress of the retraining process in percent.
     * @param listItem
     */
    determineRetrainingProgressPercentageStyle(
        listItem: AppliancesRetrainingCategoriesListItem
    ): string {
        return `${listItem.data.retrainingProgressPercentage}%`;
    }


    /**
     * Returns the url of the icon that represents the appliance category.
     * @param listItem
     */
    determineIconUrl(listItem: AppliancesRetrainingCategoriesListItem): string {
        return `url(${listItem.data.iconPath})`;
    }


    /**
     * Returns the url of the icon that represents the appliance category.
     * @param listItem
     */
    onRetrainButtonClick(listItem: AppliancesRetrainingCategoriesListItem) {
        let appliance = [listItem.category];
        if (listItem.data.appliances.length > 0) {
            appliance = listItem.data.appliances.map(a => a.name);
        }
        const popoverConfig = this.popoverConfigService.getAppliancesRetrainingPopoverConfig();
        const translatedAppliance = this.translateApplianceLowercase(listItem.category.toLowerCase());

        this.translate.get('screens.dashboard.appliances.appliancesDetailTexts.retrainButton', {translatedAppliance})
            .subscribe((translation: string) => {
                popoverConfig.data.text = translation;
            });

        popoverConfig.data.text += `\n ${this.TEXTS.RETRAINING_POPOVER_INFO}`;

        this.popover.open(popoverConfig).afterClosed$.pipe(
            mergeMap(startRetraining => {
                if (startRetraining.data) {
                    return this.nilmService.startRetrainingForAppliance(appliance).pipe(
                        catchError(error => {
                            const config = this.popoverConfigService.getAppliancesRetrainingErrorPopoverConfig();
                            return this.popover.open(config).afterClosed$
                                .pipe(map(() => false));
                        }),
                    );
                }
                return of(false);
            }),
        ).subscribe({
            next: (result) => {
                if (result) {
                    this.retrainingDataProvider.disableCategory([listItem.category]);
                    this.retrainingDataProvider.getAppliancesRetrainingData(true);
                }
            },
        });
        console.log('appliance', appliance);
    }


    /**
     * Starts the retraining process for all appliances in the given list.
     * @param items
     */
    onRetrainAllButtonClick(items: AppliancesRetrainingCategoriesListItem[]) {
        const appliances = items.map((item) => {
            if (item.data.appliances.length > 0) {
                return item.data.appliances.map(a => a.name);
            }
            return item.category;
        });

        const reducedAppliances = this.flattenArray(appliances);

        const popoverConfig = this.popoverConfigService.getAppliancesRetrainingPopoverConfig();
        popoverConfig.data.text = this.translate.instant('popovers.appliancesRetraining.retrainingPopoverInfo2');

        this.popover.open(popoverConfig).afterClosed$.pipe(
            mergeMap(startRetraining => {
                if (startRetraining.data) {
                    return this.nilmService.startRetrainingForAppliance(reducedAppliances).pipe(
                        catchError(error => {
                            const config = this.popoverConfigService.getAppliancesRetrainingErrorPopoverConfig();
                            return this.popover.open(config).afterClosed$
                                .pipe(map(() => false));
                        }),
                    );
                }
            })
        ).subscribe({
            next: (result) => {
                if (result) {
                    this.retrainingDataProvider.disableCategory(
                        items.map(i => i.category)
                    );
                    this.retrainingDataProvider.setSkipProgressCalculation(true);
                    this.retrainingDataProvider.getAppliancesRetrainingData(true);
                }
            }
        });

    }


    /**
     * Flattens an array of strings or strings and string arrays to a single dimensional array
     * @param array
     * @private
     */
    private flattenArray(array: any[]): any[] {
        return array.reduce((acc, val) => acc.concat(val), []);
    }
}
