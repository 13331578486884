import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {AppliancesTileTexts} from '../../../shared/texts/tiles/appliances-tile.texts';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Observable, share} from 'rxjs';
import {ProfileUpdateService} from '../../../services/profile-update.service';
import {
    AppliancesTileDataProviderService,
} from '../../../services/data-provider/appliances-tile-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {AppliancesTileData} from '../../../shared/enums/appliances-tile-data.interfaces';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-appliances-tile',
    templateUrl: './appliances-tile.component.html',
    styleUrls: ['./appliances-tile.component.scss'],
    providers: [Globals]
})

export class AppliancesTileComponent extends BaseComponent implements OnInit, OnDestroy {

    readonly TEXTS = {
        TITLE: this.translate.instant(AppliancesTileTexts.TITLE),
        LABEL_CONSUMPTION_IN: this.translate.instant(AppliancesTileTexts.LABEL_CONSUMPTION_IN),
        ERROR: this.translate.instant(AppliancesTileTexts.ERROR),
        LOADING: this.translate.instant(AppliancesTileTexts.LOADING),
    };

    protected readonly ViewState = ViewState;
    private readonly type: TILE_TYPE = TILE_TYPE.APPLIANCES;

    showAttentionIndicator = false;

    combinedApplianceTileData$: Observable<{
        applianceTileData: AppliancesTileData
        profileUpdate: boolean
    }> = this.appliancesDataProvider.appliancesTileData$.pipe(
        share(),
        mergeMap(applianceTileData => (
            this.profileUpdate.onShowProfileIndicator.pipe(
                map(profileUpdate => ({applianceTileData, profileUpdate}))
            )
        )),
        tap((combined) => {
            this.showAttentionIndicator = this.determineAttentionIndicatorVisibility(combined);
            this.cdRef.detectChanges();
        })
    );


    constructor(
        private globals: Globals,
        private analytics: TrackAnalyticsService,
        private tiles: TileService,
        private profileUpdate: ProfileUpdateService,
        private appliancesDataProvider: AppliancesTileDataProviderService,
        private cdRef: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        super();
    }


    ngOnInit(): void {
        this.profileUpdate.checkProfileUpdateIndicatorDisplayDue();
        this.appliancesDataProvider.setupAppliancesTileDataRetrieval();
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    /**
     * Handle on tile click
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * On Detail opened
     */
    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    /**
     * Determine whether the attention indicator should be displayed
     * @param combinedTileData
     * @private
     */
    private determineAttentionIndicatorVisibility(
        combinedTileData: {
            applianceTileData: AppliancesTileData,
            profileUpdate: boolean
        }
    ): boolean {
        if (combinedTileData.applianceTileData.nilmProfileComplete) {
            return combinedTileData.profileUpdate || combinedTileData.applianceTileData.nilmRetrainingInProgress;
        }
        return combinedTileData.profileUpdate;
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Appliances'
            }
        });
    }


    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Consumer-Details'
            }
        });
    }


}


export interface ApplianceCategories {
    AlwaysOn: ApplianceCategoryValue;
    Cooking: ApplianceCategoryValue;
    ElectricVehicle: ApplianceCategoryValue;
    Entertainment: ApplianceCategoryValue;
    Laundry: ApplianceCategoryValue;
    Lighting: ApplianceCategoryValue;
    Other: ApplianceCategoryValue;
    PoolOrSauna: ApplianceCategoryValue;
    Refrigeration: ApplianceCategoryValue;
    SpaceHeating: ApplianceCategoryValue;
    WaterHeating: ApplianceCategoryValue;
}


export interface ApplianceCategoryValue {
    usage: number;
    cost: number;
}


export interface AlignedApplianceCategoryData {
    name: string;
    usage: number;
}


export interface ApplianceDiagramSeriesData {
    name: string;
    y: number;
    x?: string;
    color: string;
    sliced: boolean;
}
