<ng-container *ngIf="config">
    <div class="rank-row" *ngIf="config.rankingItem.me">
        <div class="message">
            {{config.motivationalMessage}}
        </div>
    </div>
    <div class="rank-row" [class.user]="config.rankingItem.me">
        <div class="rank">
            {{config.rankingItem.rank}}
        </div>
        <div class="value">
            {{config.rankingItem.consumption | number:'1.0-0':'de-DE'}}
            {{valueUnit}}
        </div>
        <div class="icon">
            <i class="trophy-sm white"></i>
        </div>
    </div>
    <div class="rank-row" *ngIf="config.showDots">
        <div class="spacer"><span></span><span></span><span></span></div>
    </div>
</ng-container>
