import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {ApplicationService} from './application.service';
import {
    InitializationData,
    InitializationProfile
} from '../shared/interfaces/plain-responses/initialization-response.interface';
import {MeterService} from './meter.service';


@Injectable({
    providedIn: 'root'
})
export class InitializationService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private cachedInitializationResponse: InitializationProfile | null = null;


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private router: Router,
                private application: ApplicationService,
                private meterService: MeterService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }


    get(
        forMeter = false,
        accountRewrite = false
    ): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.initialization;
        if (accountRewrite) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.initialization;
        }
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.initialization + '.json';
        }
        return this.http.get(url).pipe(
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            mergeMap((mapped: InitializationData) => {
                if (mapped && 'profile' in mapped) {
                    const isAllZeros = /^[0]+$/.test(mapped.profile?.meter_serial_number);

                    if (!isAllZeros && mapped.profile && mapped.profile?.meter_serial_number) {
                        this.meterService.validateAndCacheMeterSerialNumber(mapped.profile?.meter_serial_number);
                    } else {
                        mapped.profile.meter_serial_number = this.meterService.getMeterSerialNumberFromSession();
                    }
                }

                if (!forMeter) {
                    return of(mapped);
                }
                if (this.initializationResponseIsValid(mapped)) {
                    return of(mapped);
                }
                this.cachedInitializationResponse = mapped.profile;
                return throwError(() =>
                    new Error(
                        'Response validation for meter tile/detail failed after initial mapping.'
                    )
                );
            }),
            mergeMap(mappedResponse => this.handleDisassociateError(mappedResponse)),
            catchError((errorResponse) => this.handleInitializationError(errorResponse))
        );
    }

    /**
     * Returns initialization data from cache if available
     * @param accountRewrite
     */
    getWithCache(
        forMeter = false,
        accountRewrite = false
    ): Observable<InitializationProfile | Error> {
        if (this.cachedInitializationResponse) {
            return of(this.cachedInitializationResponse);
        }
        return this.get(forMeter, accountRewrite).pipe(
            map((data: InitializationData) => {
                return data.profile;
            })
        );
    }


    /**
     * Returns meter connection info used in settings
     */
    getMeterConnectionInfo(): Observable<string> {
        return this.get().pipe(
            mergeMap((response: any) => {
                if (!('profile' in response)) {
                    return throwError(null);
                }
                if (!('meter_status_electricity' in response.profile)) {
                    return throwError(null);
                }
                return of(response.profile.meter_status_electricity);
            }),
            map((value) => {
                if (value === 0) {
                    return 'connected';
                } else if (value === 1) {
                    return 'disconnected';
                } else {
                    return 'pending';
                }
            })
        );
    }


    /**
     * Handles whether the meter has been disassociated from the user.
     * This is caught by checking if the mac address of the bridge element is all zeroes.
     * @param mappedResponse
     * @private
     */
    private handleDisassociateError(mappedResponse: any): Observable<Error | any> {
        // handle dissoassociate
        if ('profile' in mappedResponse) {
            if ('smartbridge_mac' in mappedResponse.profile) {
                const mac = mappedResponse.profile.smartbridge_mac;
                if (mac === '0000:0000:0000') {
                    this.routeToOnboardingOnInitializationError();
                    return throwError(() =>
                        new Error('Smartbridge is not associated with the user')
                    );
                }
            }
            return of(mappedResponse);
        }
        return throwError(() =>
            new Error('Initialization response does not contain a profile')
        );
    }


    /**
     * Handles errors that occur during initialization.
     * @param errorResponse
     * @private
     */
    private handleInitializationError(errorResponse: any): Observable<Error | any> {
        console.log('Error on initialization: ', errorResponse);
        if ('error' in errorResponse) {
            if ('error' in errorResponse.error) {
                if ('code' in errorResponse.error.error) {
                    if (errorResponse.error.error.code === 125) {
                        this.routeToOnboardingOnInitializationError();
                    }
                }
            }
        }

        if ('_body' in errorResponse) {
            let jsonError;

            try {
                jsonError = JSON.parse(errorResponse._body);
            } catch (e) {
                return throwError(e);
            }

            if ('error' in jsonError) {
                if ('code' in jsonError.error && jsonError.error.code === 125) {
                    // user not yet onboarded -> Zum Onboarding springen
                    this.routeToOnboardingOnInitializationError();
                }
            }
        }

        return throwError(errorResponse);
    }


    /**
     * Shallow validation of the initialization response.
     * @param response
     * @private
     */
    private initializationResponseIsValid(response: any): boolean {
        if ('profile' in response) {
            if ('meter_status_electricity' in response.profile) {
                return true;
            }
        }
        return false;
    }


    /**
     * Routes to the onboarding page
     * @private
     */
    private routeToOnboardingOnInitializationError(): void {
        this.router.navigate(
            ['/registrieren'],
            {
                queryParams: {
                    jumpToOnboarding: true
                }
            }
        );
    }
}
