<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">{{LABELS.TITLE}}</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>

                <section>
                    <ng-container *ngIf="meterData$ | async as meterData">
                        <div class="current-meter-values">
                            <app-meter-value-display
                                [forHeader]="true"
                                [value]="meterData.meterValues.offtake | paddedMeterValue"
                                [title]="LABELS.VALUE_CONSUMPTION"
                                icon='consumption'
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>
                            <app-meter-value-display
                                *ngIf="meterData.meterValues.feedin"
                                [forHeader]="true"
                                [value]="meterData.meterValues.feedin | paddedMeterValue"
                                [title]="LABELS.VALUE_FEEDIN"
                                icon='feedin'
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>
                        </div>

                        <div *ngIf="meterData.meterStats.meterSerialNumber"
                             class="meter-serial-number center-contents font-light m-b-m">
                            {{LABELS.METER_NUMBER}}
                            &nbsp;
                            <strong>{{ meterData.meterStats.meterSerialNumber }}</strong>
                        </div>

                        <div class="connected">
                            <div class="connection-container">
                                <div class="m-r-m">
                                    <app-connection-indicator
                                        [value]="meterData.wifiConnectionQuality"
                                        container_height="24px"
                                        [disabled]="!isMeterConnected(meterData.meterStats.connectionStatus)"
                                        [invert_colors]="true"
                                        [config]="connectionConfig">
                                    </app-connection-indicator>
                                </div>
                                <h4 class="connection-text font-light"
                                    [class.connected]="isMeterConnected(meterData.meterStats.connectionStatus)"
                                    [class.disconnected]="!isMeterConnected(meterData.meterStats.connectionStatus)">
                                    {{determineMeterStatusMessage(meterData.meterStats.connectionStatus)}}
                                </h4>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">
                        {{TEXTS.TILE_INFO.TITLE}}
                    </h2>
                    <p>
                        {{TEXTS.TILE_INFO.PARAGRAPH}}
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <section class="detail-content">
                <ng-container *ngIf="false">
                    <p>
                        {{TEXTS.METER_READING.MESSAGE.REGULAR}} <strong>{{TEXTS.METER_READING.MESSAGE.STRONG}}</strong>.
                    </p>
                    <p>
                        <button class="iona-button orange">{{LABELS.BTN_LABEL_REPORT_METER_READING}}</button>
                    </p>
                </ng-container>

                <div>
                    <strong>{{TEXTS.METER_VALUE_DATE.TITLE}}</strong>
                    <p class="p-t-s">
                        {{TEXTS.METER_VALUE_DATE.PARAGRAPH}}
                    </p>
                </div>

                <div class="meter-value-date-select">
                    <div class="date-input-container m-b-m">
                        <span class="p-r-m"> {{LABELS.YOUR_CONSUMPTION}}</span>
                        <app-date-picker-button
                            [alternativeClass]="'date-picker-meter'"
                            [placeholder]="'TT.MM.JJJJ'"
                            [currentDate]="null"
                            [hasIcon]="false"
                            (dateChanged)="onDateSelectedNew($event)"
                        ></app-date-picker-button>
                    </div>

                    <div class="meter-values-container">
                        <ng-container *ngIf="dateSpecificMeterValues$ | async as dateValues">
                            <app-meter-value-display
                                [value]="dateValues.offtake | paddedMeterValue"
                                [title]="LABELS.VALUE_CONSUMPTION"
                                icon='consumption'
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>

                            <app-meter-value-display
                                *ngIf="hasFeedinData"
                                [value]="dateValues.feedin | paddedMeterValue"
                                [title]="LABELS.VALUE_FEEDIN"
                                icon="feedin"
                                [unit]="LABELS.UNIT">
                            </app-meter-value-display>
                        </ng-container>

                    </div>

                </div>

            </section>

        </div>

    </div>
</div>
