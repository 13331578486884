import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {Observable, of} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, mergeMap} from 'rxjs/operators';
import {
    OptInAnalyticsDataResponse
} from '../shared/interfaces/plain-responses/opt-in-analytics-data-response.interface';


@Injectable({
    providedIn: 'root'
})
export class OptInService extends BaseService {

    constructor(
        protected http: HttpClient,
        protected auth: ApiService,
        protected user: UserService,
    ) {
        super(http, auth, user);
    }


    setAnalyticsOptIn(value: boolean): Observable<boolean> {
        const url = this.API_BASE_URL + constants.api.routes.optin.analytics;
        const body = {enable: value};
        console.log('sending body', body);
        return this.http.put(url, body).pipe(
            mergeMap((response: any) => of(response.status === 'ok')),
            catchError(error => this.handleError(error))
        );
    }


    getAnalyticsOptIn(): Observable<boolean> {
        const url = this.API_BASE_URL + constants.api.routes.optin.analytics;
        return this.http.get<OptInAnalyticsDataResponse>(url).pipe(
            mergeMap((response: any) => of(response.data.enabled)),
            catchError(error => this.handleError(error))
        );
    }

}
