import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import * as moment from 'moment/moment';
import {ApplicationService} from './application.service';


@Injectable({
    providedIn: 'root'
})
export class ProfileUpdateService {

    onShowProfileIndicator = new BehaviorSubject<boolean>(false);


    constructor(private application: ApplicationService) {
    }


    /**
     * Checks whether a profile update is due
     * @param fromDetail
     */
    checkProfileUpdateIndicatorDisplayDue(fromDetail: boolean = false): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (fromDetail) {
            const showReminder = localStorage.getItem(
                StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER
            );
            const parsed = showReminder === '1';
            if (parsed) {
                this.onShowProfileIndicator.next(true);
                localStorage.removeItem(StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER);
            }
            return;
        }
        const lastReminderStored = localStorage.getItem(
            StorageAttributes.LAST_PROFILE_UPDATE_REMINDER
        );
        if (lastReminderStored) {
            const lastReminderParsed = moment(lastReminderStored);
            if (lastReminderParsed.isValid()) {
                const diffMonths = moment().diff(lastReminderStored, 'months');
                if (diffMonths >= 30) {
                    this.onShowProfileIndicator.next(true);
                    this.setProfileIndicatorDisplayalFlag(true);
                }
            }
            return;
        }
        this.onShowProfileIndicator.next(true);
        this.setProfileIndicatorDisplayalFlag(true);
    }


    /**
     * Removes the profile update indicator flag
     */
    removeProfileUpdateIndicatorFlag(): void {
        localStorage.setItem(
            StorageAttributes.LAST_PROFILE_UPDATE_REMINDER, new Date().toString()
        );
        localStorage.removeItem(StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER);
        this.onShowProfileIndicator.next(false);
    }


    /**
     * Removes all profile update indicator flags
     */
    removeAllFlags(): void {
        localStorage.removeItem(StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER);
        localStorage.removeItem(StorageAttributes.LAST_PROFILE_UPDATE_REMINDER);
    }


    /**
     * Sets the profile update indicator flag
     * @param value
     * @private
     */
    private setProfileIndicatorDisplayalFlag(value: boolean): void {
        localStorage.setItem(
            StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER,
            value ? '1' : '0'
        );
    }

}
