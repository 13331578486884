<p>
    {{TEXTS.retrainingInfoParagraph}}
</p>

<ng-container *ngIf="retrainingCategoryData$ | async as retrainingData">

    <button class="iona-button orange m-b-l"
            (click)="onRetrainAllButtonClick(retrainingData)">
        {{LABELS.btnRetrainAll}}
    </button>

    <div *ngFor="let entry of retrainingData"
         class="retraining-category-item"
         [class.inactive]="entry.data.isInRetraining || entry.data.disabled">

        <div class="icon"
             [style.mask-image]="determineIconUrl(entry)"
             [style.-webkit-mask-image]="determineIconUrl(entry)">
        </div>

        <div class="title font-bold">
            {{entry.data.label}}
        </div>

        <div class="progress-container"
             [class.inactive]="entry.data.isInRetraining || entry.data.disabled">
            <div class="progress-bar"
                 [style.width]="determineRetrainingProgressPercentageStyle(entry)">
            </div>
        </div>

        <button class="iona-icon-only-button play"
                [disabled]="entry.data.isInRetraining || entry.data.disabled"
                (click)="onRetrainButtonClick(entry)">
        </button>
    </div>
</ng-container>
