import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {interval, Observable} from 'rxjs';

import * as d3 from 'd3';
import {take} from 'rxjs/operators';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-remaining-time-indicator',
    templateUrl: './remaining-time-indicator.component.html',
    styleUrls: ['./remaining-time-indicator.component.scss']
})
export class RemainingTimeIndicatorComponent implements OnInit {

    @Input('size') size: number;
    @Input('refreshrate') refreshRate: number;
    @Input('overallTime') overallTime: number;
    @Input('indicatorWidth') indicatorWidth: number;
    @Input('inverted') inverted: boolean;

    @ViewChild('svgElement', {static: true}) svgElement: ElementRef;

    interval = new Observable();
    state: 'not-started' | 'running' | 'done' = 'not-started';
    message: string;
    active = false;
    invertedActive = false;

    private svg;
    private arc;
    private arcPath;
    private bgArc;
    private bgArcPath;

    private angleStep;
    private numSteps;
    private timeout;

    constructor(private renderer: Renderer2, private translate: TranslateService) {
    }

    ngOnInit() {
        this.renderer.setAttribute(this.svgElement.nativeElement, 'width', `${this.size}px`);
        this.renderer.setAttribute(this.svgElement.nativeElement, 'height', `${this.size}px`);

        this.angleStep = 360 / (this.overallTime / this.refreshRate);
        this.numSteps = this.overallTime / this.refreshRate;

        this.initialize();
    }

    /**
     * Initialize indicator
     */
    private initialize(): void {
        this.svg = d3.select(this.svgElement.nativeElement);

        // setup arcs
        this.bgArc = d3.arc()
            .innerRadius(this.size / 2 - this.indicatorWidth + 0.4)
            .outerRadius(this.size / 2 - 0.4)
            .startAngle(0)
            .endAngle(2 * Math.PI);

        this.arc = d3.arc()
            .innerRadius(this.size / 2 - this.indicatorWidth)
            .outerRadius(this.size / 2)
            .startAngle(0)
            .endAngle(0);

        // setup arc-paths
        this.bgArcPath = this.svg.append('path')
            .attr('fill', 'rgba(255,255,255,0)')
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('d', this.bgArc)
            .attr('transform', `translate(${this.size / 2}, ${this.size / 2})`);

        this.arcPath = this.svg.append('path')
            .attr('fill', 'rgba(255,255,255,0)')
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('d', this.arc)
            .attr('transform', `scale(1, -1)`)
            .attr('transform', `translate(${this.size / 2}, ${this.size / 2})`);
    }

    /**
     * Start the interval
     * @param steps
     */
    private start(steps: number): void {
        // this.interval = interval(this.refreshRate).pipe(
        // this.interval = interval(this.refreshRate * 10).pipe(
        this.interval = interval(this.refreshRate * 1000).pipe(
            take(steps)
        );
        this.interval.subscribe(
            (value: number) => {
                this.update(value * this.angleStep);
            },
            (error) => {
            },
            () => {
                this.active = false;
            }
        );
    }

    /**
     * Update the indicator
     * @param value
     */
    private update(value: number): void {
        this.arc.endAngle(this.d2r(value));
        this.arcPath.attr('d', this.arc);
    }

    /**
     * Convert degress to radians
     * @param deg
     */
    private d2r(deg: number): number {
        return deg * Math.PI / 180;
    }

    /**
     * Set the start time and determine the components state.
     * @param start_time
     * @param end_time
     */
    public setStartTime(start_time: Date, end_time: Date): void {
        let start = moment(start_time);
        const end = moment(end_time);
        const now = moment();
        const hours = this.translate.instant('common.hours');

        if (now < start) {
            if (this.inverted) {
                this.invertedActive = false;
            } else {
                this.active = false;
            }
            const ms = start.diff(now);

            // start the timer once the timeout has passed
            this.timeout = setTimeout(() => {
                this.start(this.numSteps);
            }, ms);

            const d = moment.duration(ms);
            const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

            if (d.hours() === 1 && d.minutes() === 0) {
                this.message = `in 1 ${hours}`;
            } else if (d.hours() >= 1 && d.minutes() > 0) {
                this.message = `in ${d.hours()} ${hours} ${d.minutes()} Min`;
            } else if (d.hours() < 1 && d.minutes() > 0) {
                this.message = `in ${d.minutes()} Min`;
            }

            this.state = 'not-started';

        } else if (now > start) {
            if (this.inverted) {
                this.invertedActive = true;
            } else {
                this.active = true;
            }
            if (now < end) {
                const ms = end.diff(now);
                const d = moment.duration(ms);
                this.update(this.numSteps - (d.asSeconds() / 10));
                const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

                this.message = `${this.translate.instant('common.still')} ${d.minutes()} Min`;
                this.state = 'running';
            } else {
                start = start.day(start.day() + 1);
                const ms = start.diff(end);
                const d = moment.duration(ms);
                const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

                if (d.minutes() === 0) {
                    this.message = `in ${d.hours()} ${hours}`;
                } else {
                    this.message = `in ${d.hours()} ${hours} ${this.translate.instant('common.and')} ${d.minutes()} Min`;
                }
                this.state = 'done';

                this.update(0);
            }
        }

        this.updateColor();
    }

    /**
     * Update the color of the indicator depending on being inverted and the current state
     */
    private updateColor(): void {
        switch (this.state) {
            case 'done':
            case 'not-started':
                if (this.inverted) {
                    this.arcPath.attr('fill', '#578BBE');
                    this.bgArcPath.attr('fill', '#578BBE');
                } else {
                    this.bgArcPath.attr('fill', '#EFEFEF');
                    this.arcPath.attr('fill', '#EFEFEF');
                }
                break;
            case 'running':
                if (this.inverted) {
                    this.bgArcPath.attr('fill', '#FFFFFF');
                    this.arcPath.attr('fill', '#578BBE');
                } else {
                    this.bgArcPath.attr('fill', '#163A8A');
                    this.arcPath.attr('fill', '#EFEFEF');
                }
                break;
        }
        return;
    }
}


