import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {
    modalText: string;
    imageUrl: string;
    buttonText: string;
    title: string;
    showCloseButton: boolean;

    constructor(
        public dialogRef: MatDialogRef<SupportModalComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.modalText = data.text;
        this.imageUrl = data.imageUrl || '';
        this.buttonText = data.buttonText || this.translate.instant('common.close');
        this.title = data.title || '';
        this.showCloseButton = data.showCloseButton || true;
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}
