
import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-opt-in-popover',
    templateUrl: './opt-in-popover.component.html',
    styleUrls: ['./opt-in-popover.component.scss']
})
export class OptInPopoverComponent implements OnInit {

    showFeatures = false;

    imagePath = 'assets/img/graphics/feature-icons/';
    imageExtension = '.webp';
    features;

    constructor(public popoverRef: PopoverRef, private translate: TranslateService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
        this.initializeFeatures();
    }

    ngOnInit() {
    }

    createImageUrl(element: { title: string, text: string, image: string }): string {
        return `url(${this.imagePath}${element.image}${this.imageExtension})`;
    }

    private initializeFeatures(): void {
        this.features = [
            {
                title: this.translate.instant('popovers.optInPopover.features.live.title'),
                text: this.translate.instant('popovers.optInPopover.features.live.text'),
                image: 'live'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.comparison.title'),
                text: this.translate.instant('popovers.optInPopover.features.comparison.text'),
                image: 'comparison'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.finance.title'),
                text: this.translate.instant('popovers.optInPopover.features.finance.text'),
                image: 'finance'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.appliances.title'),
                text: this.translate.instant('popovers.optInPopover.features.appliances.text'),
                image: 'appliances'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.consumptionAlert.title'),
                text: this.translate.instant(
                    'popovers.optInPopover.features.consumptionAlert.text'
                ),
                image: 'consumption-alert'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.meter.title'),
                text: this.translate.instant('popovers.optInPopover.features.meter.text'),
                image: 'meter'
            },
            {
                title: this.translate.instant('popovers.optInPopover.features.today.title'),
                text: this.translate.instant('popovers.optInPopover.features.today.text'),
                image: 'today'
            },
        ];
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData(): void {
        this.showFeatures = this.popoverRef.data.showFeatures === true;
    }

}
