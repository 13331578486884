import {Component, OnInit} from '@angular/core';
import {Angulartics2GoogleAnalytics} from 'angulartics2';
import {LanguageService} from './services/language.service';


@Component({
    selector: 'iona-app',
    templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

    ngOnInit(): void {
        this.languageService.initTranslate();
    }
    constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
                private languageService: LanguageService) {}

}
