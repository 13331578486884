import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ToastrService} from 'ngx-toastr';
import {Globals} from '../../services/globals.service';

import {UserService} from '../../services/user.service';
import {ApplicationService} from '../../services/application.service';
import {InitializationService} from '../../services/initialization.service';
import {ProfileService} from '../../services/profile.service';
import {BaseComponent} from '../../classes/base-component';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {LocalOptInService} from '../../services/local-opt-in.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-household',
    templateUrl: './household.component.html',
    styleUrls: ['./household.component.scss'],
    viewProviders: [],
    providers: [Globals]
})

export class HouseholdComponent extends BaseComponent implements OnInit {
    username: string = null;
    fullname: string = null;

    household: any = {
        Attributes: {
            PropertyType: null,
            PropertySize: null,
            PropertyAge: null,
            Occupants: null,
            SpaceHeating: null,
            WaterHeating: null,
            OvenHeating: null,
            GrillHeating: null,
            StoveHeating: null,
            Photovoltaic: null,
        },
        Appliances: []
    };

    propertyType = {
        title: this.translate.instant('screens.household.propertyQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.propertyTypes.freistehendesHaus'),
                iconName: 'PT.01'
            },
            {
                title: this.translate.instant('screens.household.propertyTypes.doppelhaushälfte'),
                iconName: 'PT.02'
            },
            {
                title: this.translate.instant('screens.household.propertyTypes.bungalow'),
                iconName: 'PT.03'
            },
            {
                title: this.translate.instant('screens.household.propertyTypes.reihenhaus'),
                iconName: 'PT.04'
            },
            {
                title: this.translate.instant('screens.household.propertyTypes.reiheneckhaus'),
                iconName: 'PT.05'
            },
            {
                title: this.translate.instant('screens.household.propertyTypes.wohnung'),
                iconName: 'PT.06'
            },
        ]
    };

    propertySize = {
        title: this.translate.instant('screens.household.sizeQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.propertySizes.oneZimmer'),
                iconName: 'PS.01'
            },
            {
                title: this.translate.instant('screens.household.propertySizes.twoZimmer'),
                iconName: 'PS.02'
            },
            {
                title: this.translate.instant('screens.household.propertySizes.threeZimmer'),
                iconName: 'PS.03'
            },
            {
                title: this.translate.instant('screens.household.propertySizes.fourPlusZimmer'),
                iconName: 'PS.04'
            },
        ]
    };

    propertyAge = {
        title: this.translate.instant('screens.household.ageQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.propertyAges.before1919'),
                iconName: 'PA.01'
            },
            {
                title: this.translate.instant('screens.household.propertyAges.1920to1975'),
                iconName: 'PA.02'
            },
            {
                title: this.translate.instant('screens.household.propertyAges.1979to1999'),
                iconName: 'PA.03'
            },
            {
                title: this.translate.instant('screens.household.propertyAges.after2000'),
                iconName: 'PA.04'
            },
        ]
    };

    occupants = {
        title: this.translate.instant('screens.household.occupantsQuestion'),
        selectedValue: null,
        items: [
            {title: this.translate.instant('screens.household.occupants.one'), iconName: 'OCC.01'},
            {title: this.translate.instant('screens.household.occupants.two'), iconName: 'OCC.02'},
            {
                title: this.translate.instant('screens.household.occupants.three'),
                iconName: 'OCC.03'
            },
            {title: this.translate.instant('screens.household.occupants.four'), iconName: 'OCC.04'},
            {
                title: this.translate.instant('screens.household.occupants.fivePlus'),
                iconName: 'OCC.05'
            },
        ]
    };

    spaceHeating = {
        title: this.translate.instant('screens.household.spaceHeatingQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.spaceHeating.gasheizung'),
                iconName: 'SH.01'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.waermespeicher'),
                iconName: 'SH.02'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.elektroheizung'),
                iconName: 'SH.03'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.warmepumpe'),
                iconName: 'SH.04'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.oelheizung'),
                iconName: 'SH.05'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.solarzelle'),
                iconName: 'SH.06'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.hybridWarmepumpe'),
                iconName: 'SH.08'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.fernwaerme'),
                iconName: 'SH.09'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.holzpellets'),
                iconName: 'SH.10'
            },
            {
                title: this.translate.instant('screens.household.spaceHeating.andere'),
                iconName: 'SH.07'
            },
        ]
    };

    waterHeating = {
        title: this.translate.instant('screens.household.waterHeatingQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.waterHeating.gasheizkessel'),
                iconName: 'WH.01'
            },
            {
                title: this.translate.instant('screens.household.waterHeating.elektroboiler'),
                iconName: 'WH.02'
            },
            {
                title: this.translate.instant('screens.household.waterHeating.oelheizkessel'),
                iconName: 'WH.03'
            },
            {
                title: this.translate.instant('screens.household.waterHeating.solartherme'),
                iconName: 'WH.04'
            },
            {
                title: this.translate.instant('screens.household.waterHeating.andere'),
                iconName: 'WH.05'
            },
        ]
    };

    photovoltaic = {
        title: this.translate.instant('screens.household.photovoltaicQuestion'),
        selectedValue: null,
        items: [
            {
                title: this.translate.instant('screens.household.photovoltaic.yes'),
                iconName: 'SOL.01'
            },
            {
                title: this.translate.instant('screens.household.photovoltaic.no'),
                iconName: 'SOL.02'
            },
        ]
    };

    appliances = {
        title: this.translate.instant('screens.household.appliancesQuestion'),
        items: [
            {
                title: this.translate.instant('screens.household.appliances.freezerCombination'),
                iconName: 'A.03',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.fridge'),
                iconName: 'A.01',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.freezer'),
                iconName: 'A.02',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.electricOven'),
                iconName: 'A.04',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.electricGrill'),
                iconName: 'A.05',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.electricStove'),
                iconName: 'A.06',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.microwave'),
                iconName: 'A.07',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.kettle'),
                iconName: 'A.08',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.toaster'),
                iconName: 'A.09',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.dishwasher'),
                iconName: 'A.10',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.washingMachine'),
                iconName: 'A.11',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.dryer'),
                iconName: 'A.12',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.iron'),
                iconName: 'A.13',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.tv'),
                iconName: 'A.14',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.dvdOrBluRayPlayer'),
                iconName: 'A.15',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.digitalTvBox'),
                iconName: 'A.16',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.gameConsole'),
                iconName: 'A.17',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.computer'),
                iconName: 'A.18',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.tablet'),
                iconName: 'A.19',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.instantaneousWaterHeater'),
                iconName: 'A.20',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.wallbox'),
                iconName: 'A.21',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.pool'),
                iconName: 'A.22',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.sauna'),
                iconName: 'A.23',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.airConditioning'),
                iconName: 'A.25',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.waterBoiler'),
                iconName: 'A.27',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.underSinkWaterBoiler'),
                iconName: 'A.28',
                count: 0,
                selected: false
            },
            {
                title: this.translate.instant('screens.household.appliances.batteryStorage'),
                iconName: 'A.29',
                count: 0,
                selected: false
            },
        ]
    };


    constructor(
        private title: Title,
        private toastrService: ToastrService,
        private globals: Globals,
        private userService: UserService,
        public application: ApplicationService,
        private initialization: InitializationService,
        private profile: ProfileService,
        private analytics: TrackAnalyticsService,
        private optInService: LocalOptInService,
        private translate: TranslateService,
    ) {
        super();
    }


    ngOnInit() {
        this.translate.get('ionaSpecific.household.pageTitle').subscribe((translatedTitle: string) => {
            this.title.setTitle(translatedTitle);
        });
        this.optInService.checkStatus();
        this.username = this.userService.getActiveUserName();
        this.initialize();
    }


    initialize(): void {
        this.addSub(this.initialization.get().subscribe({
            next: (res) =>
                this.fullname =
                    this.application.isDemoMode() ?
                        'Frau Maxi Mustermann' :
                        res.profile.customer_name,
            error: (err) => console.log('Error getting initialization', err)
        }));

        this.addSub(this.profile.getAttributes().subscribe({
            next: (res) => this.processProfileData(res),
            error: (err) => console.log('Error getting profile attributes', err)
        }));
    }


    determineAppliancesSelected(): boolean {
        for (const element of this.appliances.items) {
            if (element.selected) {
                return true;
            }
        }
        return false;
    }


    determineImagePath(element, currentItem): string {
        return `url(assets/img/icons/household/${currentItem.iconName}.png)`;
    }


    isActive(element, currentItem): boolean {
        return element.selectedValue === currentItem.iconName;
    }


    isActiveAppliance(element, currentItem): boolean {
        return currentItem.selected;
    }


    onCountChanged(item, value): void {
        // if (value === 0) {
        //     item.selected = false;
        // }
    }


    /**
     * neue Settings speichern
     */
    saveSettings() {
        let errors: any = [];

        const Appliances = {};
        for (const el of this.appliances.items) {
            if (el.selected) {
                Appliances[el.iconName] = el.count; // : null;
            }
        }
        const Attributes = {
            // GrillHeating: null, // 'GH.00',
            Occupants: this.occupants.selectedValue,
            // OvenHeating: null, // 'OH.00',
            PropertyAge: this.propertyAge.selectedValue,
            // PropertyLocation: null, // '45128',
            PropertySize: this.propertySize.selectedValue,
            PropertyType: this.propertyType.selectedValue,
            SpaceHeating: this.spaceHeating.selectedValue,
            // StoveHeating: null, // 'STH.00',
            WaterHeating: this.waterHeating.selectedValue,
            Photovoltaic: this.photovoltaic.selectedValue
        };

        const payload = {Appliances, Attributes};

        // return;
        if (errors.length > 0) {
            for (const error of errors) {
                this.toastrService.warning(error);
            }
        } else {
            // Wenn Onboarding-Prozess - Google Analytics Event
            this.analytics.trackEvent({
                action: 'onboarding_profile_end',
                properties: {
                    category: 'Onboarding'
                }
            });

            if (!this.application.isDemoMode()) {
                this.profile.setAttributes(payload).subscribe(
                    (response) => {
                        this.toastrService.success(this.translate.instant('screens.household.settingsSaved'));

                    },
                    (error) => {
                        this.toastrService.error(this.translate.instant('screens.household.errorOccurred'), this.translate.instant('common.errors.error'));
                        const error_obj = error.error;
                        if (error_obj.error.code === 180) {
                            console.log('Error:', error);
                        }
                    }
                );
            } else {
                this.toastrService.info(this.translate.instant('screens.household.demoModeWarning'));
            }
        }
    }


    /**
     * Process Profile information
     * @param attributes
     */
    private processProfileData(attributes): void {
        console.log('attributes', attributes);
        if ('Attributes' in attributes) {
            this.propertyType.selectedValue = attributes.Attributes.PropertyType;
            this.propertySize.selectedValue = attributes.Attributes.PropertySize;
            this.propertyAge.selectedValue = attributes.Attributes.PropertyAge;
            this.occupants.selectedValue = attributes.Attributes.Occupants;
            this.spaceHeating.selectedValue = attributes.Attributes.SpaceHeating;
            this.waterHeating.selectedValue = attributes.Attributes.WaterHeating;
            this.photovoltaic.selectedValue = attributes.Attributes.Photovoltaic;

            for (const element of this.appliances.items) {
                element.count = attributes.Appliances[element.iconName] || 0;
                element.selected = attributes.Appliances[element.iconName] > 0;
            }
        }
    }

}
