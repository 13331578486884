<div class="overlay-small">
    <div class="overlay-container-cdk">
        <h1>{{ 'screens.profile.passwordResetTitle' | translate }}</h1>
        <p>
            {{ 'screens.profile.passwordResetDescription' | translate }}
        </p>
        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <input type="password" class="iona-input m-b-x"
                   formControlName="oldPassword" placeholder="{{ 'screens.profile.oldPasswordLabel' | translate }}">
            <input type="password" class="iona-input m-b-m"
                   formControlName="newPassword1" placeholder="{{ 'screens.profile.newPasswordLabel' | translate }}">
            <p class="password-info">
                {{ 'screens.profile.passwordInfo' | translate }}
            </p>
            <div class="password-strength">
                        <span *ngFor="let i of [0,1,2,3,4]"
                              [class.active]="i <= currentPasswordScore"></span>
                <div class="password-score-label">
                    {{ 'common.password' | translate }}: <span>{{determinePasswordScoreRatingText()}}</span>
                </div>

            </div>
            <input type="password" class="iona-input m-b-m"
                   formControlName="newPassword2" placeholder="{{ 'screens.profile.repeatNewPasswordLabel' | translate }}">
            <button class="iona-button orange" type="submit" [disabled]="submitDisabled">{{ 'screens.profile.changeButton' | translate }}</button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close(false)"></button>
    </div>
</div>
