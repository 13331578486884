import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription, throwError, timer, zip} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import {ProfileService} from './profile.service';
import {Popover} from '../popovers/popover/popover.service';
import {
    NilmDevicePopoverComponent
} from '../popovers/nilm-device-popover/nilm-device-popover.component';
import * as moment from 'moment';
import {VisibilityService} from './visibility.service';
import {HouseholdProfile} from '../shared/interfaces/profile-attributes.interfaces';
import {NilmStatusResponse} from '../shared/interfaces/plain-responses/nilm-response.interfaces';


@Injectable({
    providedIn: 'root'
})
export class NilmService extends BaseService {

    private readonly updateInterval = 300000;
    private readonly relevantNilmDevices = [{
        key: 'timeBasedAppliances',
        elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
    }];

    private readonly deviceIdentifierMapping = {
        washingMachine: 'A.11',
        dishWasher: 'A.10',
        dryer: 'A.12',
        oven: 'A.04'
    };

    private joinedNilmProfileSub: Subscription = null;
    private visibilitySub: Subscription = null;
    private nilmData: NilmStatusResponse | null = null;
    private lastNilmStatusUpdateTimestamp: number | null = null;

    private _tempSaveNilmDirectly = true;
    private hasOpenNilmDeviceOverlay = false;

    private _debug = false;

    onNewNilmStatusUpdate$ = new BehaviorSubject<boolean | null>(null);

    onHasAppliancesInRetraining = new BehaviorSubject<boolean | null>(null);


    /**
     * Check whether the passed appliances is complete according to the defined rules
     * @param applianceElement
     */
    static applianceIsComplete(applianceElement: any): boolean {
        return applianceElement.profileComplete ||
            applianceElement.profileComplete === false &&
            applianceElement.profileAdded === true;
    }


    constructor(
        protected http: HttpClient,
        protected auth: ApiService,
        protected user: UserService,
        private application: ApplicationService,
        private profile: ProfileService,
        private userService: UserService,
        private popover: Popover,
        private visibility: VisibilityService
    ) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.joinedNilmProfileSub) {
            this.joinedNilmProfileSub.unsubscribe();
            this.joinedNilmProfileSub = null;
        }
        if (this.visibilitySub) {
            this.visibilitySub.unsubscribe();
            this.visibilitySub = null;
        }
    }


    /**
     * Return current NILM status data
     */
    getNilmStatusData(forceRequest = false): Observable<NilmStatusResponse> {
        if (!forceRequest) {
            if (this.nilmData) {
                return of(this.nilmData);
            }
            return this.requestCurrentNilmStatus();
        } else {
            return this.requestCurrentNilmStatus();
        }
    }


    /**
     * Returns whether the users current profile seems complete
     * @param categories
     */
    isProfileComplete(categories: string[]): boolean {
        const result = [];
        for (const category of categories) {
            result.push(this.nilmCategoryIsComplete(category));
        }
        return result.every(element => element === true);
    }


    /**
     * Returns whether the latest NILM status contains retraining appliances or categories
     */
    hasAppliancesInRetraining(): boolean {
        if ('retrainSchedule' in this.nilmData) {
            return Object.keys(this.nilmData.retrainSchedule).length > 0;
        }
        return false;
    }


    /**
     * Triggers the hasAppliancesInRetraining subject
     */
    triggerHasAppliancesInRetraining(): void {
        this.onHasAppliancesInRetraining.next(
            this.hasAppliancesInRetraining() && this.nilmData.nilmStatus === 3
        );
    }


    /**
     * Starts retraining for a list of appliances by transmitting the appliance keys to the API.
     * @param appliances
     */
    startRetrainingForAppliance(appliances: string[]): Observable<any> {
        const body = {appliances: {}};
        for (const appliance of appliances) {
            body.appliances[appliance] = {
                immediate: false,
                removeOldModels: false
            };
        }
        const url = this.API_BASE_URL + constants.api.routes.nilm.retraining;
        return this.http.post(url, body);
    }


    /**
     * Start NILM status update for the users current profile
     * Runs in background and populates the findings to an exposed BehaviorSubject
     */
    startNilmStatusUpdateForCurrentProfile(): void {
        if (this.joinedNilmProfileSub) {
            return;
        }
        this.joinedNilmProfileSub = timer(0, this.updateInterval).pipe(
            mergeMap(() => this.getCombinedProfileNilmResponse()),
            catchError(error => of(null))
        ).subscribe({
            next: (mergedResponse) => {
                this.handleCombinedProfileStatusResponse(mergedResponse);
            },
            error: (error) => {
                this.onNewNilmStatusUpdate$.next(false);
            }
        });
        this.initializeVisibilityChanges();
    }


    /**
     * Returns if the specified category is complete in the current NILM-Status response
     * @param category - category key
     */
    nilmCategoryIsComplete(category: string): boolean {
        if (!this.nilmData) {
            if (this._debug) {
                console.log('NILM-Service: no data!');
            }
            return true;
        }

        category = category.toLowerCase();
        let profiles = [];
        if (category === 'refrigeration') {
            profiles = [
                this.nilmData.nonTimeBasedAppliances.refrigeration
            ];
        } else if (category === 'entertainment') {
            profiles = [
                this.nilmData.nonTimeBasedAppliances.entertainment
            ];
        } else if (category === 'cooking') {
            profiles = [
                this.nilmData.timeBasedAppliances.oven
            ];
        } else if (category === 'laundry') {
            profiles = [
                this.nilmData.timeBasedAppliances.washingMachine,
                this.nilmData.timeBasedAppliances.dryer,
                this.nilmData.timeBasedAppliances.dishWasher,
            ];
        } else {
            return true;
        }

        if (profiles.every(element =>
            element !== false && element !== undefined && element !== null)) {
            const results = [];
            for (const element of profiles) {
                results.push(NilmService.applianceIsComplete(element));
            }
            return results.every(el => el !== false && el !== undefined && el !== null);
        }

        return false;
    }


    /**
     * Determines whether a cateogry is currently in retraining or contains appliances in retraining
     * @param category
     */
    nilmCategoryIsRetraining(category: string): boolean {
        category = category.toLowerCase();
        if (!('retrainSchedule' in this.nilmData)) {
            return false;
        }

        if (category === 'refrigeration') {
            return 'refrigeration' in this.nilmData.retrainSchedule;
        } else if (category === 'alwayson') {
            return 'alwaysOn' in this.nilmData.retrainSchedule;
        } else if (category === 'entertainment') {
            return 'entertainment' in this.nilmData.retrainSchedule;
        } else if (category === 'lighting') {
            return 'lighting' in this.nilmData.retrainSchedule;
        } else if (category === 'laundry') {
            return 'washingMachine' in this.nilmData.retrainSchedule ||
                'dryer' in this.nilmData.retrainSchedule ||
                'dishWasher' in this.nilmData.retrainSchedule;
        } else if (category === 'cooking') {
            return 'oven' in this.nilmData.retrainSchedule;
        } else if (category === 'spaceheating') {
            return 'heatPump' in this.nilmData.retrainSchedule ||
                'airConditioning' in this.nilmData.retrainSchedule;
        } else if (category === 'waterheating') {
            return 'waterBoiler' in this.nilmData.retrainSchedule ||
                'electricShower' in this.nilmData.retrainSchedule ||
                'flowHeater' in this.nilmData.retrainSchedule;
        } else if (category === 'electricvehicle') {
            return 'electricVehicle' in this.nilmData.retrainSchedule;
        } else {
            if (this._debug) {
                console.log('NILM-Service: unknown category: ', category);
            }
            return false;
        }
    }


    // startApplianceRetraining(appliance: string): Observable<any> {
    //     const url =
    // }


    /**
     * Handle merged responses from NilmStatus and Profile
     * @param mergedResponse
     * @private
     */
    private handleCombinedProfileStatusResponse(
        mergedResponse: { profile: HouseholdProfile, nilm: NilmStatusResponse }
    ): void {
        if (mergedResponse === null) {
            this.onNewNilmStatusUpdate$.next(false);
            return;
        }
        this.nilmData = mergedResponse.nilm;
        this.lastNilmStatusUpdateTimestamp = moment().unix();
        this.onNewNilmStatusUpdate$.next(true);
        this.determineNewDevicesAdded(mergedResponse);
    }


    /**
     * Filters the device model count from a NILM-data response
     * @param data - NILM data response
     * @param deviceMap - device map
     */
    private filterNilmDeviceModelCount(data: any, deviceMap: any): any {
        const result = {};
        for (const category of deviceMap) {
            const responseCategory = data[category.key];
            if (responseCategory === null || responseCategory === undefined) {
                continue;
            }
            for (const device of category.elements) {
                const value = responseCategory[device].models;
                if (value === null || value === undefined) {
                    continue;
                }
                if (!result[category.key]) {
                    result[category.key] = {};
                }
                result[category.key][device] = data[category.key][device];
            }
        }
        return result;
    }


    /**
     * Determines whether there were new device added according to the NILM service
     * @param combinedResponse - combined response of NILM-Status and profile data
     */
    private determineNewDevicesAdded(
        combinedResponse: { profile: HouseholdProfile, nilm: NilmStatusResponse }
    ): void {
        const currentProfileData = combinedResponse.profile;
        const currentNilmData = combinedResponse.nilm;

        // get stored nilm data - focus on the amounts
        const storedNilmData = this.userService.getActiveUserNilmStatus();
        if (!storedNilmData) {
            this.userService.updateActiveUserNilmStatus(combinedResponse.nilm);
            return;
        }

        // filter stored & new data for **only** the amounts
        const storedDeviceAmounts = this.filterNilmDeviceModelCount(
            storedNilmData, this.relevantNilmDevices);
        const newDeviceAmounts = this.filterNilmDeviceModelCount(
            currentNilmData, this.relevantNilmDevices);

        // filter appliance amounts from profile
        const profileAmounts = this.filterNilmDeviceAmountsFromProfile(currentProfileData);

        for (const category of Object.keys(newDeviceAmounts)) {
            // if the old stored object does not contain the category skip ahead
            if (!storedDeviceAmounts.hasOwnProperty(category)) {
                continue;
            }

            for (const appliance of Object.keys(newDeviceAmounts[category])) {
                if (storedDeviceAmounts[category][appliance].models !== 0) {
                    continue;
                }

                const currentApplianceNewData = newDeviceAmounts[category][appliance];
                if (currentApplianceNewData.models !== 0 &&
                    !currentApplianceNewData.profileComplete) {
                    let amount = profileAmounts[appliance];
                    if (!amount) {
                        amount = 0;
                    }
                    if (!this.hasOpenNilmDeviceOverlay) {
                        this.openNilmDeviceOverlayWithConfig({amount, appliance});
                        if (this._tempSaveNilmDirectly) {
                            this.userService.updateActiveUserNilmStatusForAppliance(
                                appliance, newDeviceAmounts[category][appliance]
                            );
                        }
                    }
                }
            }

        }
    }


    /**
     * Open device configuration overlay with a defined config
     * @param config - contains the current appliance amoutn defined in the profile & the appliance
     */
    private openNilmDeviceOverlayWithConfig(config: { amount: number, appliance: string }): void {
        if (this._debug) {
            console.log('Opening NILM Overlay with config:', config);
        }
        this.popover.open({
            content: NilmDevicePopoverComponent,
            hasBackdrop: true,
            data: config
        }).afterClosed$.subscribe((result: any) => {
            this.hasOpenNilmDeviceOverlay = false;
            if (!result.data) {
                return;
            }
            const resultData = result.data;
            this.userService.updateActiveUserNilmStatusForAppliance(
                resultData.appliance, resultData.amount
            );
            const change = {Appliance: {}};
            change.Appliance[resultData.applianceId] = resultData.amount;
            this.profile.setAttributes(change).subscribe(
                (res) => {
                }
            );
        });
        this.hasOpenNilmDeviceOverlay = true;
    }


    /**
     * Filter device amounts form user profile according to the available devices
     * @param profileData
     */
    private filterNilmDeviceAmountsFromProfile(profileData: any) {
        const profileMappedToNilm = {};
        for (const applianceTranslated of Object.keys(this.deviceIdentifierMapping)) {
            const applianceIdentifier = this.deviceIdentifierMapping[applianceTranslated];
            try {
                profileMappedToNilm[applianceTranslated] =
                    profileData.Appliances[applianceIdentifier];
            } catch (e) {
                profileMappedToNilm[applianceTranslated] = null;
            }
        }
        return profileMappedToNilm;
    }


    /**
     * get the combined nilm & household profile response
     */
    private getCombinedProfileNilmResponse():
        Observable<{
            profile: HouseholdProfile,
            nilm: NilmStatusResponse
        }> {
        const $profileRequest = this.profile.getAttributes();
        const $nilmStatusRequest = this.requestCurrentNilmStatus();
        return zip([$profileRequest, $nilmStatusRequest]).pipe(
            map(([profile, nilm]) =>
                ({ profile, nilm })
            )
        );
    }


    /**
     * Initialize change of visibility NILM-Status requests
     */
    private initializeVisibilityChanges(): void {
        if (!this.visibilitySub) {
            return;
        }
        this.visibilitySub = this.visibility.onVisible.pipe(
            mergeMap(() => this.getCombinedProfileNilmResponse())
        ).subscribe({
            next: (mergedResponse) => {
                this.handleCombinedProfileStatusResponse(mergedResponse);
            },
            error: (error) => {
                this.onNewNilmStatusUpdate$.next(false);
            }
        });
    }


    /**
     * Get the NILM status
     */
    private requestCurrentNilmStatus(): Observable<NilmStatusResponse> {
        let url = this.API_BASE_URL + constants.api.routes.nilm.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.nilmStatus}.json`;
        }
        return this.http.get(url).pipe(
            mergeMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            mergeMap((mapped) =>
                mapped ? of(mapped) : throwError({msg: 'Error after mapping response'})
            ),
            catchError((error: any) => this.handleError(error))
        );
    }
}

