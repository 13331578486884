import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {UserService} from './user.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {AuthService} from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private http: HttpClient,
                private userService: UserService,
                private authService: AuthService) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const activeUser = this.userService.getActiveUserName();
        if (activeUser) {
            const token = this.userService.getActiveUserAccessToken();
            if (token) {
                const tokenHeaderValue = `Bearer ${token}`;
                const clonedRequest = req.clone({
                    headers:
                        req.headers.set('Authorization', tokenHeaderValue)
                });
                return next.handle(clonedRequest);
            }
        }
        return next.handle(req);
    }


    private refreshTokenAndRetry(req: HttpRequest<any>, next: HttpHandler) {
        return this.authService.refreshToken().pipe(
            mergeMap(refreshResponse => {
                console.log('Refresh response:', refreshResponse);
                if (refreshResponse) {
                    console.log('Response is not null');
                    console.log('Updating access token');
                    this.userService.updateActiveUserAccessToken(
                        refreshResponse.access_token);
                    console.log('Redirecting to original request');
                    return next.handle(req);
                }
                console.log('Response is null');
                console.log('Redirecting to login');
                return throwError('Refresh token failed');
            })
        );
    }
}
