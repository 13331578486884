import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MockDataService} from '../../../services/mock-data.service';
import {interval, of, Subscription, throwError} from 'rxjs';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {InitializationService} from '../../../services/initialization.service';
import {
    liveDetailERNAZoomLevels,
    liveDetailZoomLevels,
    LiveZoomLevel
} from '../constants/live.constants';
import * as moment from 'moment';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {determineDurationPassed} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {Router} from '@angular/router';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {HeartbeatService} from '../../../services/heartbeat.service';
import {map, mergeMap} from 'rxjs/operators';
import {HappyHourService} from '../../../services/happy-hour.service';
import {
    RemainingTimeIndicatorComponent
} from '../../../components/remaining-time-indicator/remaining-time-indicator.component';
import {PowerValue} from '../live-tile/live-tile.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-live-details',
    templateUrl: './live-details.component.html',
    styleUrls: ['./live-details.component.scss'],
    viewProviders: []
})
export class LiveDetailsComponent extends BasePopover implements OnInit, OnDestroy, AfterViewInit {

    private mockIntervalSub: Subscription = null;
    private mockCurrentConsumptionSub: Subscription = null;

    // private onUserHasHappyHour = new Subject<boolean>();
    private continueLiveUpdate = true;

    private dateFormat = 'DD.MM.YYYY';
    private dateFormatDebug = 'DD.MM.YYYY hh:mm:ss';

    currentConsumption = null;
    currentDataset = [];

    status = {trend: 0, noZone: true, since: null};

    userHasHappyHour = false;
    yesterdayHappyHourStart = null;
    yesterdayHappyHourEnd = null;
    todayHappyHourStart = null;
    todayHappyHourEnd = null;
    currentHappyHourStart = null;
    currentHappyHourEnd = null;

    isVisionUser = false;

    /* Day based data selection */
    today = moment().format('DD.MM.YYYY');
    baseDate = null;
    lastStartDate = null;
    specificDateMode = false;
    specifiedDateDisplay = null;

    isNotRealtime = false;
    energySaverWarningCollapsed = true;
    energySaverWarningVisible = true;
    lastValueTimestamp = null;

    regularSeriesColor = '#47A7D8';
    feedinSeriesColor = '#ffc300';
    happyHourSeriesColor = '#143C8C';

    zoomLevels: Array<LiveZoomLevel> = null;
    valueUnit = 'Watt';
    infoVisible = false;
    disabled = true;
    currentZoomLevelIdx = 1;
    position = 1;

    @ViewChild('happyHourIndicator') hhIndicator: RemainingTimeIndicatorComponent;
    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;
    @ViewChild('dateInput', {static: true}) dateInput: ElementRef;


    constructor(
        protected popoverRef: PopoverRef,
        public userService: UserService,
        private apiService: ApiService,
        private mockDataService: MockDataService,
        private liveData: LiveDataService,
        private homeState: HomestateService,
        private application: ApplicationService,
        private electricity: ElectricityService,
        private router: Router,
        private opticalReader: OpticalReaderService,
        private heartbeat: HeartbeatService,
        private happyHour: HappyHourService,
        private initialization: InitializationService,
        private translate: TranslateService) {
        super(popoverRef);
    }


    ngOnInit() {
        this.baseDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.initializeComponent();
    }

    translateZoomLevels(): void {
        this.zoomLevels = liveDetailZoomLevels.map(level => ({
            ...level,
            name: this.translate.instant(level.name),
            hint: this.translate.instant(level.hint),
            format: level.format.replace('{{timeUnit}}', this.translate.instant('screens.liveDetailZoomLevels.timeUnit'))
        }));
    }

    translateErnaZoomLevels(): void {
        this.zoomLevels = liveDetailERNAZoomLevels.map(level => ({
            ...level,
            name: this.translate.instant(level.name),
            hint: this.translate.instant(level.hint),
            format: level.format.replace('{{timeUnit}}', this.translate.instant('screens.liveDetailZoomLevels.timeUnit'))
        }));
    }


    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.liveChart.updateZoomLevel(
            this.zoomLevels[this.zoomLevels.length - 1].resolution * 1000,
            this.zoomLevels[0].format
        );

        this.initializePowerBasedApiConnection();

        // set user stored zoom level
        const zoomlevel = this.userService.getLiveDetailZoomLevel();
        this.setZoom(zoomlevel, true);
    }


    ngOnDestroy() {
        this.mockIntervalSub = this.unsubscribeAndDelete(this.mockIntervalSub);
        this.mockCurrentConsumptionSub = this.unsubscribeAndDelete(this.mockCurrentConsumptionSub);
        this.heartbeat.destroy();
        super.ngOnDestroy();
    }


    onChartLoaded(): void {

    }


    onDateChange(value) {
        this.baseDate = moment(value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.specifiedDateDisplay = moment(this.baseDate).format(this.dateFormat);
        this.specificDateMode = true;
        this.position = 0;
        this.requestConsumptionForDate();
    }

    onDateChangeNew(value: Date) {
        this.baseDate = moment(value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.specificDateMode = true;
        this.position = 0;
        this.requestConsumptionForDate();
    }


    zoomIn() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx - 1);
    }


    zoomOut() {
        if (this.specificDateMode) {
            this.position = 0;
            this.baseDate = this.lastStartDate;
        }
        this.setZoom(this.currentZoomLevelIdx + 1);
    }


    setZoom(level: number, skipChecks = false) {
        if (!skipChecks) {
            if (this.disabled) {
                return;
            }
            if (level === this.currentZoomLevelIdx) {
                return;
            }
        }

        const zoom = this.zoomLevels.filter((item: any) => item.level === level);
        if (zoom.length < 1) {
            return;
        }
        this.currentZoomLevelIdx = level;
        this.liveChart.updateZoomLevel(zoom[0].resolution * 1000, zoom[0].format);
        this.userService.setLiveDetailZoomLevel(level);
        this.resetChart();
    }


    stepForward() {
        if (this.specificDateMode) {
            this.position--;
            this.requestConsumptionForDate();
            return;
        }

        if ((this.position > 1) && (!this.disabled)) {
            this.position--;
            this.resetChart();
        }

        if (this.position === 0) {
            this.continueLiveUpdate = true;
        }

        this.continueLiveUpdate = true;
    }


    stepBack() {
        if (this.specificDateMode) {
            ++this.position;
            this.requestConsumptionForDate();
            return;
        }

        if (!this.disabled) {
            this.position++;
            this.resetChart();
        }
        this.continueLiveUpdate = false;
    }


    resetPosition() {
        if (!this.disabled) {
            this.position = 1;
            this.specificDateMode = false;
            this.resetChart();
        }
        this.continueLiveUpdate = true;
    }


    resetChart() {
        this.liveChart.reset();
        this.disabled = true;

        if (this.application.isDemoMode()) {
            this.getMockConsumption();
            return;
        }

        if (this.specificDateMode) {
            this.requestConsumptionForDate();
        } else {
            this.getLiveConsumption();
        }
    }


    toggleWarning(event): void {
        const id = event.target.id;
        if (id !== 'hide-button') {
            this.energySaverWarningCollapsed = !this.energySaverWarningCollapsed;
        }
    }


    hideWarning(): void {
        this.energySaverWarningVisible = false;
    }


    routeToSettings(): void {
        this.close();
        this.router.navigate(['einstellungen']);
    }


    currentConsumptionFormatted(): string {
        return Math.abs(this.currentConsumption).toLocaleString('de-DE');
    }


    private initializeComponent(): void {
        this.valueUnit = this.userService.isERNAUser() ? 'Wh' : 'Watt';
        this.specifiedDateDisplay = moment(this.baseDate).format(this.dateFormat);
        this.baseDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
        this.liveChart.setUnit(this.valueUnit);
        if (this.userService.isERNAUser()) {
            this.translateErnaZoomLevels();
        } else {
            this.translateZoomLevels();
        }

        if (this.userService.isEDGUser()) {
            if (this.userService.isEnviamUser()) {
                const s = this.opticalReader.onMeterReaderStatus.subscribe({
                    next: (res) => {
                        this.isNotRealtime = res.mode === 'RT_INACTIVE' && res.battery_status > 0;
                    }
                });
                this.addSub(s);
            }
        }
    }


    /**
     * Initialize ERNA based user api connections
     * using the consumption api instead of live power values
     */
    private initializeConsumptionBasedApiConnection(): void {
        this.getLiveConsumption();

        this.electricity.startLast24hTimerUpdate();
        this.electricity.onConsumption24hUpdate.pipe(
            mergeMap(response => {
                try {
                    const mapped = response.map(element => {
                        return {
                            power: 'measured' in element ? element.measured : 0,
                            timestamp: element.timestamp
                        };
                    });
                    return of(mapped);
                } catch (e) {
                    return of(null);
                }
            })
        ).subscribe((res) => {
            const filtered = res.filter(element => {
                const ts = moment(element.timestamp);
                return ts > moment().subtract(1, 'day');
            });
            if (filtered.length > 0) {
                const copy = filtered.slice();
                const found = copy.reverse().find((el) => el.power > 0);
                if (found) {
                    this.determineCurrentConsumption(found.power);
                }
                this.disabled = false;
            }
        });

        const s = this.electricity.onConsumptionFilteredUpdate.pipe(
            mergeMap(response => {
                try {
                    const mapped = response.map(element => {
                        return {
                            power: 'measured' in element ? element.measured : 0,
                            timestamp: element.timestamp
                        };
                    });
                    return of(mapped);
                } catch (e) {
                    return of(null);
                }
            }),
            map((res) => {
                if (this.specificDateMode) {
                    return null;
                }
                const zoom = this.zoomLevels.filter(
                    (item: any) => item.level === this.currentZoomLevelIdx)[0];
                const from = moment()
                    .subtract(this.position * zoom.offset, 'minutes');
                const to = moment()
                    .subtract((this.position * zoom.offset) - zoom.offset, 'minutes');
                let filtered = res.filter(el => {
                    const ts = moment(el.timestamp);
                    return ts >= from && ts <= to;
                });
                const last = filtered.last();
                try {
                    if (last.power === 0) {
                        filtered = filtered.slice(0, filtered.length - 1);
                    }
                } catch (e) {
                }
                return filtered;
            })
        ).subscribe({
            next: (res: any) => {
                try {
                    res?.forEach((el) => {
                        if (!('power' in el)) {
                            el.power = 0;
                        }
                        return el;
                    });
                    this.updateChartWithData(res);
                    this.liveChart.showLoadingState(false);
                    this.disabled = false;
                } catch (e) {
                    console.log('Error:', e);
                    this.disabled = true;
                    // this.liveChart.showLoadingState();
                }
            }
        });
        this.addSub(s);
        // this.electricity.startFilteredConsumptionUpdate();
    }


    /**
     * Initialize live api connections
     */
    private initializePowerBasedApiConnection(): void {
        if (this.userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
        }

        this.getLiveConsumption();

        // current consumption value
        this.addSub(this.liveData.onLiveConsumptionReceived.pipe(
            mergeMap((values) => !values ? throwError({msg: 'initial'}) : of(values))
        ).subscribe({
            next: (res) => {
                const data = res.results;
                if (data.length === 0) {
                    return;
                }
                // filter by the last 5 minutes
                const start = moment().subtract(5, 'minutes');
                const filtered = data.filter((el) => {
                    const ts = moment(el.timestamp);
                    return ts >= start && ts <= moment();
                });

                // find last value
                const fiveMinCopy = filtered.slice().reverse();
                let latest = fiveMinCopy.find((el) => 'power' in el);
                if (latest) {
                    this.determineCurrentConsumption(latest.power);
                    this.lastValueTimestamp = null;
                } else {
                    const fullHourCopy = data.slice().reverse();
                    latest = fullHourCopy.find((el) => 'power' in el);
                    if (latest) {
                        this.determineCurrentConsumption(latest.power);
                        const tsFormat = 'DD.MM.YYYY HH:MM';
                        this.lastValueTimestamp = moment(latest.timestamp).format(tsFormat);
                    } else {
                        this.currentConsumption = '--';
                    }
                }
            },
            error: (error) => null
        }));
        this.liveData.startCurrentConsumptionUpdate(true);

        this.addSub(this.liveData.onFilteredLiveConsumptionReceived.subscribe({
            next: (res) => {
                if (!this.specificDateMode) {
                    this.handleLiveDataResponse(res);
                }
            },
            error: (error) => {
                console.warn('Error:', error);
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        }));
        this.liveData.startFilteredLiveConsumptionUpdate();

        this.initializeHomeStateInfoUpdate();
    }


    private initializeHomeStateInfoUpdate(): void {
        const homeS = this.homeState.onHomestateInfo.subscribe({
            next: (res) => {
                if (res) {
                    this.handleHomestateStatusResponse(res.status);
                }
            },
            error: (error) => {
                console.log('Error:', error);
            }
        });
        this.addSub(homeS);
        this.homeState.startLiveUpdateForBundledInfo();
    }


    /**
     * Handle live data response from API.
     * @param response
     */
    private handleLiveDataResponse(response: any): void {
        let results: any;
        try {
            results = response.results;
            if (results.length < 1) {
                throw Error();
            }
        } catch (e) {
            this.liveChart.showLoadingState();
            this.liveChart.removeAllSeries();
            this.disabled = false;
            return;
        }

        this.currentDataset = results;

        const center = results[Math.floor(results.length / 2)];
        this.specifiedDateDisplay = moment(center.timestamp).format(this.dateFormat);

        this.updateChartWithData(results);

        this.liveChart.showLoadingState(false);
        this.disabled = false;
    }


    /**
     * Get Live consumption values
     */
    private getLiveConsumption() {
        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * (this.position);
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;

        if (!this.userService.isEDGUser()) {
            this.liveData.setLiveValues(offset, limit, interval, 0);
        } else {
            if (this.userService.isEnviamUser()) {
                this.liveData.setLiveValues(offset, limit, interval, 0);
            } else {
                this.electricity.setCurrentFilter(offset, limit, interval, 0);
            }
        }

        if (this.userHasHappyHour) {
            this.getHappyHourSchedule(offset);
        }
    }


    /**
     * Determine the current consumption value based on the last element of the current data set
     * @param dataset
     */
    private determineCurrentConsumption(value): void {
        const diff = value - Math.floor(value);
        if (diff < 0.5) {
            this.currentConsumption = Math.floor(value);
        } else {
            this.currentConsumption = Math.ceil(value);
        }
        const currentConsumptionFixed = this.currentConsumption.toFixed(0);
        this.currentConsumption = parseInt(currentConsumptionFixed, 10);
    }


    /**
     * Handle Homestate Status response
     * @param data
     */
    private handleHomestateStatusResponse(data): void {
        if (data.current_zone === 4) {
            this.status.noZone = true;
            return;
        }
        this.determineStatus(data);
    }


    /**
     * Determine the current consumption status
     * @param data
     */
    private determineStatus(data) {
        this.status.noZone = false;
        this.status.trend = data.current_zone;
        this.status.since = determineDurationPassed(data.since, this.translate);
    }


    /**
     * Request the consumption power data for a specific date
     */
    private requestConsumptionForDate() {
        const zoom = this.zoomLevels.filter(
            (item: any) => item.level === this.currentZoomLevelIdx
        )[0];
        // position needs to be negated to match the desired movement direction
        this.lastStartDate = moment(this.baseDate).add(-this.position * zoom.offset, 'minutes');
        const to = moment(this.lastStartDate).add(zoom.offset, 'minutes');

        const liveDataSub = this.liveData.getLiveDataAlt(
            this.lastStartDate,
            to.toDate(),
            zoom.interval,
            false
        ).subscribe({
            next: (res) => {
                if (res) {
                    this.handleLiveDataResponse(res);
                }
                liveDataSub.unsubscribe();
            },
            error: (error) => {
                // console.log('Error:', error);
                this.liveChart.removeSeries(0, 2);
                this.liveChart.showLoadingState();
            },
        });

        return;
    }


    checkIsVisionUser(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.initialization.getWithCache().subscribe((data) => {
                if ('product_name' in data) {
                    this.isVisionUser = data.product_name.toLowerCase().includes('vision');
                }
                resolve();
            }, (error) => {
                reject(error);
            });
        });
    }


    /**
     * Request whether the user participates in the happy hour program
     */

    private async determineUserHasHappyHour(): Promise<void> {
        try {
            await this.checkIsVisionUser();

            if (this.isVisionUser) {
                this.userHasHappyHour = false;
                return;
            }

            const s = this.happyHour.userHasHappyHour().subscribe({
                next: (participation => {
                    this.userHasHappyHour = participation;
                    this.getLiveConsumption();
                }),
                complete: () => s.unsubscribe(),
            });
        } catch (error) {
            console.error('Error determining if user is Vision User:', error);
        }
    }


    /**
     * Request the users happyhour schedule
     */
    private getHappyHourSchedule(offset: number): void {
        const date = moment().subtract(offset, 'minutes');

        const s = this.happyHour.requestSchedule(date).subscribe({
            next: (schedule) => {
                this.currentHappyHourStart = schedule.currentStart;
                this.currentHappyHourEnd = schedule.currentEnd;
                this.todayHappyHourStart = schedule.todayStart;
                this.todayHappyHourEnd = schedule.todayEnd;
                this.yesterdayHappyHourStart = schedule.yesterdayStart;
                this.yesterdayHappyHourEnd = schedule.yesterdayEnd;

                if (this.hhIndicator) {
                    this.hhIndicator.setStartTime(
                        this.currentHappyHourStart, this.currentHappyHourEnd
                    );
                }
                s.unsubscribe();
            },
            error: (error) => {
                console.log('Error:', error);
            },
            complete: () => s.unsubscribe()
        });
    }


    /**
     * --- MOCK DATA -------------------------------------------------------------------------------
     */

    private initializeMockData(): void {
        this.getMockConsumption();
        this.getMockHomestateStatus();
        this.mockIntervalSub = interval(10000).subscribe(
            (cycle) => {
                this.getMockConsumption();
                this.getMockHomestateStatus();
            }
        );
        this.mockCurrentConsumptionSub = this.mockDataService.onCurrentConsumptionValue.subscribe(
            (value: { power: number, timestamp: number }) => {
                this.determineCurrentConsumption(value.power);
            }
        );
    }


    private getMockConsumption() {
        const zoom = this.zoomLevels.filter((item: any) => item.level === this.currentZoomLevelIdx);

        const offset = zoom[0].offset * this.position;
        const limit = offset - zoom[0].offset;
        const interval = zoom[0].interval;
        const level = zoom[0].level;

        const s = this.mockDataService.getLiveData(offset, level, limit, interval).subscribe({
            next: (values: any) => {
                try {
                    this.updateChartWithData(values);
                    this.liveChart.showLoadingState(false);
                    this.disabled = false;

                    // determine current consumption value
                    const currentConsumption = values.last().power;
                    const diff = currentConsumption - Math.floor(currentConsumption);
                    if (diff < 0.5) {
                        this.currentConsumption =
                            Math.floor(currentConsumption);
                    } else {
                        this.currentConsumption =
                            Math.ceil(currentConsumption);
                    }
                    const currentConsumptionFixed = this.currentConsumption.toFixed(0);
                    this.currentConsumption = parseInt(currentConsumptionFixed, 10);

                } catch (e) {
                    this.currentConsumption = 0;
                    this.liveChart.showLoadingState();
                    this.disabled = false;
                }
            },
            error: () => {
                this.liveChart.showLoadingState();
                this.disabled = false;
            }
        });
        this.addSub(s);
    }


    private updateChartWithData(dataset: Array<PowerValue>): void {
        const fedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            fedEnergy.push(element);
        }

        const consumedEnergy = [];
        const happyHourEnergy = [];
        for (const el of dataset) {
            const element = {power: null, timestamp: el.timestamp};
            const elementCopy = Object.assign({}, element);
            if (this.userHasHappyHour) {
                const datepointTimestamp = moment(el.timestamp).toDate();
                element.power = el.power >= 0 ? el.power : null;

                if (datepointTimestamp >= this.currentHappyHourStart &&
                    datepointTimestamp <= this.currentHappyHourEnd) {
                    happyHourEnergy.push(element);
                    elementCopy.power = null;
                    consumedEnergy.push(elementCopy);
                } else if (
                    datepointTimestamp >= this.yesterdayHappyHourStart &&
                    datepointTimestamp <= this.yesterdayHappyHourEnd) {

                    happyHourEnergy.push(element);
                    elementCopy.power = null;
                    consumedEnergy.push(elementCopy);
                } else if (datepointTimestamp >= this.todayHappyHourStart &&
                    datepointTimestamp <= this.todayHappyHourEnd) {
                    happyHourEnergy.push(element);
                    elementCopy.power = null;
                    consumedEnergy.push(elementCopy);
                } else {
                    consumedEnergy.push(element);
                    elementCopy.power = null;
                    happyHourEnergy.push(elementCopy);
                }
            } else {
                element.power = el.power >= 0 ? el.power : null;
                consumedEnergy.push(element);
            }
        }
        this.currentDataset = dataset;
        this.liveChart.removeAllSeries();
        this.liveChart.showLoadingState(false);
        this.liveChart.addNewSeries(
            consumedEnergy,
            'power',
            0,
            {zindex: 0, isTileChart: true, color: this.regularSeriesColor}
        );
        this.liveChart.addNewSeries(
            fedEnergy,
            'power',
            0,
            {zindex: 1, isTileChart: true, color: this.feedinSeriesColor}
        );
        if (this.userHasHappyHour) {
            if (happyHourEnergy.length > 0) {
                this.liveChart.addNewSeries(
                    happyHourEnergy,
                    'power',
                    0,
                    {zindex: 2, isTileChart: true, color: this.happyHourSeriesColor}
                );
            }
        }
    }


    /**
     * Get values for a mocked homestate status
     */
    private getMockHomestateStatus(): void {
        this.mockDataService.getHomeStateStatus().subscribe((data) => {
            this.determineStatus(data.data);
        });
    }

}
