import { DetailBannerData } from '../../interfaces/detail-banner.interfaces';
import { TranslateService } from '@ngx-translate/core';

export function getAppliancesProfileUpdateBanner(translate: TranslateService): DetailBannerData {
    return {
        paragraphs: [
            {
                type: 'text',
                text: translate.instant('screens.dashboard.appliances.appliancesDetailTexts.bannerUpdate.text'),
                buttonText: '',
                buttonId: ''
            },
            {
                type: 'button',
                text: '',
                buttonText: translate.instant('screens.dashboard.appliances.appliancesDetailTexts.bannerUpdate.buttonLabel'),
                buttonId: 'update-profile'
            }
        ],
        sideButton: {
            visible: true,
            isClose: true
        }
    };
}

export function getAppliancesRetrainingBanner(translate: TranslateService): DetailBannerData {
    return {
        paragraphs: [
            {
                type: 'text',
                text: translate.instant('screens.dashboard.appliances.appliancesDetailTexts.bannerRetraining.text'),
                buttonText: '',
                buttonId: ''
            }
        ],
        sideButton: {
            visible: true,
            isClose: false
        }
    };
}

