<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1 class="font-medium">
            {{ showFeatures ? ('popovers.optInPopover.titleWithFeatures' | translate) : ('popovers.optInPopover.titleWithoutFeatures' | translate) }}
        </h1>
        <ng-container *ngIf="showFeatures; else alternate">
            <p>
                {{ 'popovers.optInPopover.featuresDescription' | translate }}
            </p>
            <div class="feature-list">
                <div *ngFor="let feature of features" class="feature-list-item">
                    <div class="feature-icon"
                         [style.background-image]="createImageUrl(feature)"></div>
                    <div class="feature-description">
                        <div><strong class="m-b-m">{{feature.title}}</strong></div>
                        <div> {{feature.text}} </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #alternate>
            <p class="font-regular">
                {{ 'popovers.optInPopover.alternateDescriptionPart1' | translate }}
            </p>
            <p class="font-regular">
                {{ 'popovers.optInPopover.alternateDescriptionPart2' | translate }}
            </p>
        </ng-template>
        <div class="row">
            <button class="iona-button orange m-r-x"
                    (click)="close(true)">{{ 'popovers.optInPopover.allowOnlineMode' | translate }}
            </button>
            <button class="iona-button outline" (click)="close()">{{ 'common.logout' | translate }}</button>
        </div>
    </div>
</div>
