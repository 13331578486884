import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {MeterStatuses} from '../shared/enums/meter-statuses.enum';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {MeterService} from './meter.service';


@Injectable({
    providedIn: 'root'
})
export class OpticalReaderService extends BaseService {

    onMeterReaderStatus = new Subject<MeterReaderStatus>();

    private meterConnectedStates = [
        MeterStatuses.CONNECTED_WITH_METER
    ];

    private updateRate = 5000;
    private timerSub: Subscription = null;


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private meterService: MeterService) {
        super(http, auth, user);
    }


    destroy() {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            this.timerSub = null;
        }
    }


    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getOpticalReaderStatus()),
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe((res) => {
            if (res) {
                this.onMeterReaderStatus.next(res);
            }
        });
    }


    getOpticalReaderStatus(raw = false): Observable<MeterReaderStatus | any> {
        const url = this.API_BASE_URL + constants.api.routes.meterreader.status;
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            mergeMap((data) => {
                const meterId = data.electricity.smartreader?.meter_id_read;

                const isAllZeros = /^[0]+$/.test(meterId);

                if (!isAllZeros && data.electricity && data.electricity.smartreader?.meter_id_read) {
                    this.meterService.validateAndCacheMeterSerialNumber(data.electricity.smartreader?.meter_id_read);
                } else {
                    data.electricity.smartreader.meter_id_read = this.meterService.getMeterSerialNumberFromSession();
                }


                if (raw) {
                    return of(data);
                }
                let status = null;
                try {
                    status = data.electricity.current_status;
                } catch (e) {
                    // console.log('status not available');
                }

                if (this.meterConnectedStates.findIndex(el => el === status) >= 0) {
                    localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '1');
                } else {
                    localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '0');
                }

                let update_progress = null;
                if (data.electricity.current_status === 'UPDATE_INSTALLING') {
                    try {
                        update_progress = data.electricity.smartreader.firmware_update_progress;
                    } catch (e) {
                        // console.log('update progress not available');
                    }
                }

                const firmware_status =
                    this.getAttribute('firmware_status', data.electricity.smartreader);
                const meter_unlocked =
                    this.getAttribute('meter_unlocked', data.electricity.smartreader);
                const pin_entry_mode =
                    this.getAttribute('pin_entry_mode', data.electricity.smartreader);
                const key_retries =
                    this.getAttribute('key_retries', data.electricity.smartreader);
                const pincode =
                    this.getAttribute('pincode', data.electricity.smartreader);


                return of({
                    meter_id: data.electricity.smartreader.meter_id_provisioned,
                    battery_status: data.electricity.smartreader.battery_status,
                    connection_quality: data.electricity.smartreader.signal_strength,
                    mode: data.electricity.smartreader.mode,
                    status,
                    update_progress,
                    firmware_status,
                    meter_unlocked,
                    pin_entry_mode,
                    key_retries,
                    pincode
                });
            }),
            catchError((error) => {
                // console.log('Error on MeterReader Status Request', error);
                return of(null);
            })
        );
    }


    getBatteryStatus(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.meterreader.status;
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res)),
            mergeMap(data => {
                try {
                    return of(data.electricity.smartreader.battery_status);
                } catch (error) {
                    return throwError(error);
                }
            })
        );
    }


    startCommission(opticalReaderMac: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.meterreader.commission;
        return this.http.put(url, {mac_address: opticalReaderMac}).pipe(
            map(res => this.mapDefault(res)),
            catchError((error) => this.handleError(error))
        );
    }


    private getAttribute(attributeKey: string, object: any): any {
        try {
            return object[attributeKey];
        } catch (e) {
            return null;
        }
    }
}


export interface MeterReaderStatus {
    meter_id: string;
    battery_status: number;
    connection_quality: number;
    mode: string;
    pin_entry_mode: string;
    status?: string;
    update_progress?: number;
    firmware_status?: string;
    meter_unlocked?: number;
    key_retries?: number;
    pincode?: any;
}
