import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../services/user.service';
import {LocalOptInService} from '../../services/local-opt-in.service';
import {ImprintService} from '../../services/imprint.service';
import {imprintUrls} from '../../shared/constants/imprint.constants';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    viewProviders: []
})

export class ImprintComponent implements OnInit {

    @ViewChild('imprintContainer', {static: true})
    private imprintContainer: ElementRef<HTMLElement>;

    constructor(private title: Title,
                private user: UserService,
                private optInService: LocalOptInService,
                private imprintService: ImprintService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.translate.get('ionaSpecific.imprint.pageTitle').subscribe((translatedTitle: string) => {
            this.title.setTitle(translatedTitle);
        });
        this.optInService.checkStatus();

        this.loadImprintFile();
    }

    private loadImprintFile(): void {
        this.imprintService.getImprint().subscribe(
            (fileContents) => {
                this.imprintContainer.nativeElement.innerHTML = fileContents;
            }
        );
    }


    protected readonly imprintUrls = imprintUrls;
}
