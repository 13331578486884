<div class="overlay-minimal">
    <div class="overlay-container-cdk">
        <h1>{{ 'screens.profile.emailChange' | translate }}</h1>
        <div>{{ 'screens.profile.emailChangeDescription' | translate }}</div>
        <form [formGroup]="form" (ngSubmit)="submitChange()">
            <input formControlName="email1" type="email" class="iona-input m-b-l"
                   [placeholder]="'screens.profile.newEmailLabel' | translate">
            <input formControlName="email2" type="email" class="iona-input m-b-l"
                   [placeholder]="'screens.profile.repeatEmailLabel' | translate">
            <button type="submit" class="iona-button orange"
                    [disabled]="submitDisabled">
                {{'screens.profile.changeButton' | translate}}
            </button>
        </form>
    </div>
    <div class="close-overlay-control center-contents">
        <button class="iona-icon-only-button close" (click)="close(false)"></button>
    </div>
</div>
