<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">{{ 'screens.dashboard.live.title' | translate }}</h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Live"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>

                <section>
                    <div class="center-contents current-live-value font-bold"
                         [ngClass]="{muted: isNotRealtime}">
                        <i [class.feedin-white]="currentConsumption < 0"
                           [class.consumption-white]="currentConsumption >= 0">
                        </i>
                        {{currentConsumptionFormatted()}} {{ 'screens.dashboard.live.unit' | translate }}
                    </div>

                    <ng-container *ngIf="!userService.isERNAUser()">

                        <ng-container *ngIf="!userHasHappyHour; else happyHourTemplate">

                            <ng-container *ngIf="!status.noZone; else noZoneTemplate">
                                <div class="consumption-zones">
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                            {{ 'common.zone.low' | translate }}
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 0"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 0 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                            {{ 'common.zone.medium' | translate }}
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 1"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 1 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="zone-title"
                                             [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                            {{ 'common.zone.high' | translate }}
                                        </div>
                                        <div class="zone-bar" [class.active]="status.trend === 2"></div>
                                        <div class="zone-duration"
                                             [style.visibility]="status.trend === 2 ? 'visible' : 'hidden'">
                                            {{ status.since}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #noZoneTemplate>
                                <div class="center-contents zone-unavailable">
                                    {{ 'screens.dashboard.live.noZone' | translate }}
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-template #happyHourTemplate>
                            <div class="happy-hour-indicator-row">
                                <app-remaining-time-indicator
                                    #happyHourIndicator
                                    [size]="36"
                                    [overallTime]="60*60"
                                    [refreshrate]="10"
                                    [indicatorWidth]="6"
                                    [inverted]="true">
                                </app-remaining-time-indicator>
                            </div>
                        </ng-template>

                    </ng-container>
                </section>

            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">{{ 'screens.dashboard.live.infoHeading' | translate }}</h2>
                    <p>
                        <ng-container *ngIf="userService.isEDGUser()">
                            {{ 'screens.dashboard.live.infoNonEDGUserIona' | translate }}
                        </ng-container>
                        <ng-container *ngIf="!userService.isEDGUser()">
                            {{ 'screens.dashboard.live.infoNonEDGUserIona' | translate }}
                        </ng-container>
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <section class="energy-saver-warning" *ngIf="isNotRealtime && energySaverWarningVisible">
                <div class="heading" (click)="toggleWarning($event)">
                    <div class="collapse">
                        <i [class]="energySaverWarningCollapsed ? 'collapsed' : 'expand'"></i>
                    </div>
                    <div class="title">{{ 'screens.dashboard.live.energySaverWarning.title' | translate }}</div>
                    <button id="hide-button" class="iona-icon-only-button close" (click)="hideWarning()"></button>
                </div>
                <ng-container *ngIf="!energySaverWarningCollapsed">
                    <div class="content-wrapper">
                        <div class="content">
                            <div></div>
                            <div>
                                <p>
                                    {{ 'screens.dashboard.live.energySaverWarning.message' | translate }}
                                    <ng-container *ngIf="lastValueTimestamp">
                                        {{ 'screens.dashboard.live.energySaverWarning.lastValue' | translate: {lastValueTimestamp: lastValueTimestamp} }}
                                    </ng-container>
                                </p>

                                <button class="iona-button orange" (click)="routeToSettings()">{{ 'screens.dashboard.live.energySaverWarning.buttonLabel' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </section>

            <section class="detail-content">

                <div class="live-diagram-head">
                    <ng-container *ngFor="let zoom of zoomLevels">
                        <div *ngIf="zoom.level === currentZoomLevelIdx"
                             class="center-contents font-medium current-zoom">
                            {{zoom.name}}
                            <span>&nbsp;</span>
                            <small class="font-regular" *ngIf="zoom.hint">
                                ({{ zoom.hint }})
                            </small>
                        </div>
                    </ng-container>

                    <div class="live-chart-legend">
                        <div *ngIf="userHasHappyHour">
                            <span class="dot happy-hour"></span> {{'screens.dashboard.householdComparison.detailLabels.happyHour' | translate}}
                        </div>
                        <div>
                            <span class="dot consumption"></span> {{ 'screens.dashboard.comparison.consumption' | translate }}
                        </div>
                        <div>
                            <span class="dot feedin"></span>{{ 'screens.dashboard.comparison.feedIn' | translate }}
                        </div>
                    </div>

                    <div class="live-diagram-zoom-controls">
                        <button class="iona-icon-only-button timeframe-minus"
                                (click)="zoomOut()"
                                [disabled]="disabled || currentZoomLevelIdx === zoomLevels.length"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">
                        </button>
                        <div class="center-contents zoom-level">
                            <div class="dot" title="{{ zoom.name }}"
                                 [class.active]="zoom.level === currentZoomLevelIdx"
                                 *ngFor="let zoom of zoomLevels" (click)="setZoom(zoom.level)">
                            </div>
                        </div>

                        <button class="iona-icon-only-button timeframe-plus"
                                (click)="zoomIn()" [disabled]="disabled || currentZoomLevelIdx === 1"
                                angulartics2On="click" angularticsCategory="Detailansichten"
                                angularticsAction="detail_time_interval_change" angularticsLabel="Live">
                        </button>
                    </div>
                </div>


                <app-live-chart #liveChart
                                (chartLoaded)="onChartLoaded()"
                                isDetail="true">
                </app-live-chart>


                <div class="diagram-controls">
                    <div class="left">
                        <app-date-picker-button
                            [alternativeClass]="'date-picker-live'"
                            [placeholder]="'common.date' | translate"
                            (dateChanged)="onDateChangeNew($event)"
                            [hasIcon]="false"
                            [dateDisplayDisabled]="true"
                        ></app-date-picker-button>
                        <div class="m-h-s"></div>
                        <button class="iona-icon-only-button prev"
                                [disabled]="disabled"
                                (click)="stepBack()">
                        </button>
                    </div>
                    <div class="center center-contents font-regular">
                        <strong> {{baseDate ? specifiedDateDisplay : ''}} </strong>
                    </div>
                    <div class="right">
                        <button class="iona-icon-only-button next"
                                [disabled]="disabled || (position === 1 && !specificDateMode)"
                                (click)="stepForward()">
                        </button>
                        <div class="m-h-s"></div>
                        <button class="iona-button small outline"
                                [disabled]="disabled || (position === 1 && !specificDateMode)"
                                (click)="resetPosition()">
                            {{ 'common.now' | translate }}
                        </button>
                    </div>
                </div>

            </section>

        </div>
    </div>
</div>




