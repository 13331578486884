import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject, Subscription, throwError, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {constants} from '../shared/constants/constants';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';
import {MeterStatuses} from '../shared/enums/meter-statuses.enum';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';
import {AccountRewriteService} from './account-rewrite.service';
import {MeterValues} from '../shared/interfaces/meter.interfaces';
import {
    MeterInfoData,
    MeterInfoResponse
} from '../shared/interfaces/plain-responses/meter-info-response.interface';


@Injectable({
    providedIn: 'root'
})
export class MeterService extends BaseService {
    onMeterStatus = new Subject<any>();
    onPinEntrySuccess = new BehaviorSubject<any>(null);

    private meterConnectedStates = [
        MeterStatuses.CONNECTED_WITH_METER
    ];

    private updateRate = 10000;

    private timerSub: Subscription = null;
    private pinEntryTimerSub: Subscription = null;


    constructor(
        protected http: HttpClient,
        protected auth: ApiService,
        protected user: UserService,
        private application: ApplicationService,
        private accountRewrite: AccountRewriteService
    ) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            this.timerSub = null;
        }
    }


    /**
     * Start meter status live update
     */
    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getStatus())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onMeterStatus.next(res);
                }
            }
        );
    }


    /**
     * Start pin entry until successful
     * @param pin
     */
    startContinuousPinEntry(pin: string): void {
        if (this.pinEntryTimerSub) {
            return;
        }
        this.pinEntryTimerSub = timer(0, 5000).pipe(
            mergeMap((cycle) => {
                return this.putOpticalReaderPin(pin).pipe(
                    map((res) => true),
                    catchError((e) => {
                        if (e.status === 401) {
                            this.onPinEntrySuccess.next(true);
                            this.pinEntryTimerSub.unsubscribe();
                            this.pinEntryTimerSub = null;
                        }
                        return of(false);
                    })
                );
            })
        ).subscribe(
            (res) => {
                if (res) {
                    this.pinEntryTimerSub.unsubscribe();
                }
            }
        );
    }


    /**
     * Requests the meter status
     * @param handle_error
     */
    getStatus(handle_error = true): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.meter.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.meterStatus}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            map((res: any) => {
                if ('current_status' in res) {
                    if (this.meterConnectedStates.findIndex(el => el === res.current_status) >= 0) {
                        localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '1');
                    } else {
                        localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, '0');
                    }
                    const connected = res.current_status === 'CONNECTED_WITH_METER' ? 1 : 0;
                    localStorage.setItem(StorageAttributes.IS_METER_CONNECTED, connected.toString());
                }
                return res;
            }),
            catchError((error: any) => {
                if (handle_error) {
                    return this.handleError(error);
                }
                return of(error);
            })
        );
    }


    /**
     * Returns the current meter value
     */
    getCurrentMeterValue(): Observable<any> {
        return this.requestMeterInfo().pipe(
            mergeMap((res: any) => {
                if (!('Electricity' in res)) {
                    return throwError(null);
                }
                if (!('CSD' in res.Electricity)) {
                    return throwError(null);
                }
                return of({
                    offtake: res.Electricity.CSD,
                    feedin: res.Electricity.CSR
                });
            }),
            catchError((error: any) => {
                return of(error);
            })
        );
    }


    /**
     * Returns the current meter values
     */
    getCurrentMeterValueNew(): Observable<MeterValues> {
        return this.requestMeterInfo().pipe(
            mergeMap((response) => {
                try {
                    return of({
                        offtake: response.Electricity.CSD,
                        feedin: response.Electricity.CSR,
                    } as MeterValues);
                } catch (error) {
                    return throwError(() => new Error(error));
                }
            }),
            catchError(error => throwError(() => new Error(error)))
        );
    }


    /**
     * Request Meter Serial Number
     */
    getMeterSerialNumber(): Observable<string> {
        return this.requestMeterInfo().pipe(
            mergeMap((response) => {
                try {
                    return of(response.Serialnumber);
                } catch (e) {
                    return throwError(e);
                }
            })
        );
    }


    /**
     * Sends a PUT request setting the current meter pin
     * @param pin
     */
    putOpticalReaderPin(pin: string): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.meter.connect;
        const payload = {smart_reader: {pincode: pin.toString()}};
        return this.http.put(url, payload).pipe(
            map((res) => {
                return this.mapDefault(res);
            }),
        );
    }


    /**
     * Send API meter/info request
     * @private
     */
    private requestMeterInfo(): Observable<MeterInfoData> {
        let url = this.API_BASE_URL + constants.api.routes.meter.info;
        if (this.accountRewrite.accountRewriteEnabled()) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.meter.info;
        }
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.meterInfo}.json`;
        }
        return this.http.get<MeterInfoResponse>(url).pipe(
            map((response: MeterInfoResponse) => this.mapDefault(response)),
            map((data: MeterInfoData) => {

                const isAllZeros = /^[0]+$/.test(data?.Serialnumber);

                if (!isAllZeros && data && data?.Serialnumber) {
                    this.validateAndCacheMeterSerialNumber(data?.Serialnumber);
                } else {
                    data.Serialnumber = this.getMeterSerialNumberFromSession();
                }

                return data;
            }),
            catchError((error: any) => of(error))
        );
    }

    validateAndCacheMeterSerialNumber(serialNumber: string) {
        sessionStorage.setItem(StorageAttributes.METER_SERIAL_NUMBER, serialNumber);
    }

    getMeterSerialNumberFromSession() {
        return sessionStorage.getItem(StorageAttributes.METER_SERIAL_NUMBER) || null;
    }
}
