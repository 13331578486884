import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private selectedLanguage = 'de';
    private readonly STORAGE_KEY = 'selected_language';

    constructor(private translate: TranslateService) {
        this.selectedLanguage = localStorage.getItem(this.STORAGE_KEY) || 'de';
        this.initTranslate();
    }

    initTranslate(): void {
        this.translate.setDefaultLang('de');
        this.translate.use(this.selectedLanguage);
    }

    getLanguage(): string {
        return this.selectedLanguage;
    }

    setLanguage(language: string): void {
        this.selectedLanguage = language;
        localStorage.setItem(this.STORAGE_KEY, language);
        this.translate.use(language);
    }
}
